import { Droppable } from "react-beautiful-dnd";
import { React, useContext } from "react";
import { Box, Container } from "@mui/material";
import FormElement from "./FormElement";
import { FormCtx } from "./FormWrapper/BuilderWrapper";

export default function FormDisplay() {
  const context = useContext(FormCtx);
  const form =
    context.state.data.inspectionTypeTemplate.configuration.forms.find(
      (form) => form.id === context.state.formId
    );
  const fields = form?.fields ?? [];
  return (
    <Droppable droppableId="formDisplayDroppable">
      {(provided, snapshot) => (
        <Box position="relative" flex="1" sx={{ overflowY: "scroll" }}>
          <Container
            maxWidth="lg"
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{
              position: "absolute",
              paddingTop: "0.5rem",
              minHeight: "100%",
            }}
          >
            {fields.map(function (element, i) {
              return (
                <FormElement
                  fieldData={element}
                  index={i}
                  key={element.id}
                  thereIsMotion={snapshot.isDraggingOver}
                />
              );
            })}
            {provided.placeholder}
          </Container>
        </Box>
      )}
    </Droppable>
  );
}
