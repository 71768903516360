// material
import { Box, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import TremorCards from './tremorCards2';

export default function DashboardApp() {
    return (
        <Page title="Dashboard | FINBACK670">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">Dashboard</Typography>
                </Box>
                <TremorCards />
            </Container>
        </Page>
    );
}
