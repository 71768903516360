import { IFormJson } from "../..";
const pako = require("pako");

export const decode = (superEncoded: string) => {
  let un64 = atob(superEncoded);
  const gzipedDataArray = Uint8Array.from(un64, (c) => c.charCodeAt(0));
  const unzippedArray = pako.ungzip(gzipedDataArray);
  const unzippedString = new TextDecoder().decode(unzippedArray);
  const formJson: IFormJson = JSON.parse(unzippedString);

  return formJson;
};
