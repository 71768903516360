import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";

const getHelperText = (invalid, error, description) => {
  if(error && !!error.mesage)
    return error.message
  else if (error)
    return `Required Field`
  else if (description)
    return description
  else
    return ""
}
export const MUIRHFTextField = ({
  name,
  description,
  label,
  defaultValue,
  rules,
  isPassword,
  ...rest
}) => {
  const { control } = useFormContext();
  const password =
    isPassword || name.includes("password") || name.includes("Password");
  const [passwordVisible, setPasswordVisible] = useState(!password);
  return (
    <>
      <Controller
        name={name}
        rules={rules}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error, invalid } }) => {
          return (
            <TextField
              {...field}
              margin="dense"
              value={field.value || ""}
              label={label}
              error={invalid}
              type={passwordVisible ? "text" : "password"}
              InputProps={{
                endAdornment: password && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setPasswordVisible(!passwordVisible)}
                      onMouseDown={(e) => e.preventDefault}
                      edge="end"
                    >
                      {passwordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={getHelperText(invalid, error, description)}
              {...rest}
            />
          );
        }}
      />
    </>
  );
};

export default MUIRHFTextField;
