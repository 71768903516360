// material
import { useState, useEffect, useCallback } from 'react';
import {
    DataGridPremium,
    GridToolbar
} from "@mui/x-data-grid-premium";
import { Container, Grid } from '@mui/material';
// components
import Confirmation from "../../../components/modals/confirmation";
import TableActions from '../../../components/tables/tableActions';
import Page from '../../../components/Page';
// ----------------------------------------------------------------------
import { useLazyQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import AddProjectContact from './addProjectContact';

const DELETE_PROJECT_CONTACTS = gql`
    mutation deleteProjectContacts($ids: [ID]!) {
        deleteProjectContacts(ids: $ids) {
            id
        }
    }
`
const GET_PROJECT_CONTACTS = gql`
query projectContacts($projectId: ID) {
  projectContacts(projectId: $projectId) {
    id
    projectContactType {
        id
        name
    }
    firstName
    lastName
    email
    phone
    street1
    street2
    zip
    city
    state
    project {
        id
        name
    }
  }
}
`

const UPDATE_PROJECT_CONTACT = gql`
    mutation updateProjectContact($projectContact: InputProjectPersonParams) {
        updateProjectContact(projectContact: $projectContact) {
            id
            projectContactType {
                id
                name
            }
            firstName
            lastName
            email
            phone
            street1
            street2
            zip
            city
            state
            project {
                id
                name
            }
        }
    }
`
export default function ProjectPeople({ project }) {
    const [rows, setRows] = useState([]);
    const [addOpen, setAddOpen] = useState(false);
    const closeAdd = () => {
        setAddOpen(false);
    };
    const openAdd = () => {
        setAddOpen(true);
    };
    const [selectionModel, setSelectionModel] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [selectionRecord, setSelectionRecord] = useState([]);
    const [confirmation, setConfirmation] = useState({ open: false });
    const closeConfirmation = () => {
        setConfirmation({ open: false });
    };
    const [getProjectContacts] = useLazyQuery(GET_PROJECT_CONTACTS, {
        variables: { projectId: project.id },
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setRows(response.projectContacts)
        }
    });
    useEffect(() => {
        getProjectContacts({ variables: { projectId: project.id } })
    }, [project, getProjectContacts]);

    const [updateProjectContact] = useMutation(UPDATE_PROJECT_CONTACT)
    const [deleteProjectContacts] = useMutation(DELETE_PROJECT_CONTACTS, {
        onCompleted: () => {
            refetchCallback()
        }
    })

    const refetchCallback = useCallback(
        () => {
            getProjectContacts({ variables: { projectId: project.id } })
        }, [project, getProjectContacts]
    )
    const processRowUpdate = useCallback(
        async (newRow) => {
            const projectContact = {
                id: newRow.id,
                firstName: newRow.firstName,
                lastName: newRow.lastName,
                email: newRow.email,
                phone: newRow.phone,
                street1: newRow.street1,
                street2: newRow.street2,
                zip: newRow.zip,
                city: newRow.city,
                state: newRow.state
            }
            updateProjectContact({ variables: { projectContact } });
            refetchCallback()
        },
        [updateProjectContact, refetchCallback]
    );
    const handleProcessRowUpdateError = useCallback((error) => { }, []);

    const onDeleteProjectContacts = (selectionModel) => {
        let ids = selectionModel.map((model) => model.id)
        deleteProjectContacts({ variables: { ids } })
    }

    return (
        <Page title="Projects | FINBACK670">
            <Confirmation
                confirmation={confirmation}
                handleClose={closeConfirmation}
            />
            <AddProjectContact
                project={project}
                open={addOpen}
                handleClose={closeAdd}
                refetch={refetchCallback}
            />
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TableActions
                            addRecord={openAdd}
                            deleteRecords={onDeleteProjectContacts}
                            selectionModel={selectionRecord}
                            setConfirmation={setConfirmation}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ height: 600, width: "100%" }}>
                            <DataGridPremium
                                rows={rows}
                                loading={!project}
                                columns={[
                                    {
                                        editable: false, valueGetter: (params) => params.row?.projectContactType?.name,
                                        field: 'projectContact', headerName: 'Project Contact'
                                    },
                                    { editable: true, field: 'firstName', headerName: 'First Name' },
                                    { editable: true, field: 'lastName', headerName: 'Last Name' },
                                    { editable: true, field: 'email', headerName: 'Email' },
                                    { editable: true, field: 'phone', headerName: 'Phone' },
                                    { editable: true, field: 'street1', headerName: 'Street 1' },
                                    { editable: true, field: 'street2', headerName: 'Street 2' },
                                    { editable: true, field: 'zip', headerName: 'Zip' },
                                    { editable: true, field: 'city', headerName: 'City' },
                                    { editable: true, field: 'state', headerName: 'State' }
                                ]}
                                pageSize={5}
                                pageSizeOptions={[10, 25, 50]}
                                processRowUpdate={processRowUpdate}
                                onProcessRowUpdateError={handleProcessRowUpdateError}
                                slots={{ toolbar: GridToolbar }}
                                checkboxSelection
                                onRowSelectionModelChange={(ids) => {
                                    const selectedIDs = new Set(ids);
                                    const selectedRowData = rows.filter((row) =>
                                        selectedIDs.has(row.id)
                                    );
                                    setSelectionRecord(selectedRowData);
                                    setSelectionModel(ids);
                                }}
                                rowSelectionModel={selectionModel}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
