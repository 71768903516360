import * as React from "react";
// material
import { Box, Container, Typography, Tabs, Tab } from "@mui/material";
// components
import Page from "../../components/Page";
import GQLTable from "../../utils/gql-mui-rhf/Table";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function Credential() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Dashboard | FINBACK670">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Credential Management</Typography>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Person" {...a11yProps(0)} />
            <Tab label="Firm" {...a11yProps(1)} />
            <Tab label="Asset" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <GQLTable
            queryString={"credentialTypes"}
            queryVariables={{ active: true, entity: "PERSON" }}
            addMutationVariables={{ entity: "PERSON" }}
            addMutationString={"createCredentialType"}
            updateMutationString={"updateCredentialType"}
            deleteMutationString={"deleteCredentialType"}
            updateModal={true}
            columnOptions={{ name: { width: 250 }, module: { width: 200 } }}
            columnOrder={["jurisdiction", "rest", "category"]}
            restrictedColumns={{
              id: true,
              active: true,
              entity: true,
              interval: true,
            }}
            onRowClick={(rowData) => {
              navigate(`/app/admin/credential/${rowData.id}`);
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <GQLTable
            queryString={"credentialTypes"}
            queryVariables={{ active: true, entity: "FIRM" }}
            addMutationVariables={{ entity: "FIRM" }}
            addMutationString={"createCredentialType"}
            updateMutationString={"updateCredentialType"}
            updateModal={true}
            deleteMutationString={"deleteCredentialType"}
            columnOptions={{ name: { width: 250 }, module: { width: 200 } }}
            columnOrder={["name", "category", "jurisdiction"]}
            restrictedColumns={{
              id: true,
              active: true,
              entity: true,
              interval: true,
            }}
            onRowClick={(rowData) => {
              navigate(`/app/admin/credential/${rowData.id}`);
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <GQLTable
            queryString={"credentialTypes"}
            queryVariables={{ active: true, entity: "ASSET" }}
            addMutationVariables={{ entity: "ASSET" }}
            addMutationString={"createCredentialType"}
            updateMutationString={"updateCredentialType"}
            updateModal={true}
            deleteMutationString={"deleteCredentialType"}
            columnOptions={{ name: { width: 250 }, module: { width: 200 } }}
            columnOrder={["name", "category", "jurisdiction"]}
            restrictedColumns={{
              id: true,
              active: true,
              entity: true,
              interval: true,
            }}
            onRowClick={(rowData) => {
              navigate(`/app/admin/credential/${rowData.id}`);
            }}
          />
        </TabPanel>
      </Container>
    </Page>
  );
}
