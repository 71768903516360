import { Action, Form, PageState } from "../../TypeDefinitions";
import { v4 as uuidv4 } from "uuid";

export function createSubform<
  E extends Extract<
    Action,
    {
      type: "createSubform";
    }
  >
>(action: E, draft: PageState): void {
  const { parentFormId, newFormType } = action.payload;
  const newSubformBase = {
    fields: [],
    formConfigurationId: draft.data.inspectionTypeTemplate.configuration.id,
    subformIds: [],
    id: uuidv4(),
    validations: [],
    name: "",
    isEmbedded: false,
  };

  const createNewSubform = (): Form => {
    switch (newFormType) {
      case "ComponentInspection":
        return {
          ...newSubformBase,
          type: newFormType,
          references: [],
        };
      case "RoomInspection":
        return {
          ...newSubformBase,
          type: newFormType,
          references: [],
        };
      case "InteriorInspection":
      case "ExteriorInspection":
      case "SubForm":
      case "Questionnaire":
        return { ...newSubformBase, type: newFormType };
      default:
        const _exhaustiveCheck: never = newFormType;
        return _exhaustiveCheck;
    }
  };

  const newSubform = createNewSubform();

  draft.data.inspectionTypeTemplate.configuration.forms.push(newSubform);

  draft.data.inspectionTypeTemplate.configuration.forms.every((form) => {
    if (form.id === parentFormId) {
      if (!form.subformIds) form.subformIds = [];
      form.subformIds.push(newSubform.id);
      return false;
    } else {
      return true;
    }
  });

  draft.navigate(`./${newSubform.id}`);
}
