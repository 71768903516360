import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { GQLForm } from '../../utils/gql-mui-rhf/FormProvider';

export let navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
  }
  
  export let views = {
    MONTH: 'month',
    WEEK: 'week',
    WORK_WEEK: 'work_week',
    DAY: 'day',
    AGENDA: 'agenda',
  }
class Toolbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            event: null,
            openEvent: false,
            series: null,
            openSeries: false,
        }
    }
    render() {
    let {
      localizer: { messages },
      label,
    } = this.props

    return (
      <div className="rbc-toolbar">
          {this.addEvent()}
        <span className="rbc-btn-group">
        <button
            type="button"
            onClick={this.openEvent}
          >
            {"Create New Event"}
          </button>
          <button
            type="button"
            onClick={this.openSeries}
          >
            {"Create New Series"}
          </button>
          </span>
          <span className="rbc-btn-group">
          <button
            type="button"
            onClick={this.navigate.bind(null, navigate.PREVIOUS)}
          >
            {messages.previous}
          </button>
          <button
            type="button"
            onClick={this.navigate.bind(null, navigate.TODAY)}
          >
            {messages.today}
          </button>
          <button
            type="button"
            onClick={this.navigate.bind(null, navigate.NEXT)}
          >
            {messages.next}
          </button>
        </span>

        <span className="rbc-toolbar-label">{label}</span>

        <span className="rbc-btn-group">{this.viewNamesGroup(messages)}</span>
      </div>
    )
  }

// TODO: Change reference to authentication object personId
addEvent = () => {
    return (
        <Dialog open={this.state.openEvent} onClose={this.closeEvent}>
            <DialogContent>
            <GQLForm mutationString={"createEvent"} onCompleted={()=>{
                this.closeEvent()
                }}
                skipFields={{id: true, locationId: true, seriesId: true, createdBy: true}}
                inputOrder={{event: {order: 1, inputOrder: {title: {order: 1}, start: {order: 2}, end: {order: 3}}}}}
                defaultValues={{createdBy: "b2b69393-cdd2-4ad2-b9ef-54c1833fb06b", allDay: false}}
                />
            </DialogContent>
        </Dialog>
    )
}

  openEvent = () => {
    this.setState((state, props)=>{return {openEvent: true}})
  }
  closeEvent = () => {
    this.setState((state, props)=>{return {openEvent: false}})
  }

  addSeries = () => {
    return (
        <Dialog open={this.state.openSeries} onClose={this.closeSeries}>
            <DialogContent>
            <GQLForm mutationString={"createSeries"} onCompleted={()=>{
                this.closeSeries()
                }}
                skipFields={{id: true, locationId: true, seriesId: true, createdBy: true}}
                inputOrder={{event: {order: 1, inputOrder: {title: {order: 1}, start: {order: 2}, end: {order: 3}}}}}
                defaultValues={{createdBy: "b2b69393-cdd2-4ad2-b9ef-54c1833fb06b", allDay: false}}
                />
            </DialogContent>
        </Dialog>
    )
}

  openSeries = () => {
    this.setState((state, props)=>{return {openSeries: true}})
  }
  closeSeries = () => {
    this.setState((state, props)=>{return {openSeries: false}})
  }

  navigate = (action) => {
    this.props.onNavigate(action)
  }

  view = (view) => {
    this.props.onView(view)
  }

  viewNamesGroup(messages) {
    let viewNames = this.props.views
    const view = this.props.view

    if (viewNames.length > 1) {
      return viewNames.map((name) => (
        <button
          type="button"
          key={name}
          className={clsx({ 'rbc-active': view === name })}
          onClick={this.view.bind(null, name)}
        >
          {messages[name]}
        </button>
      ))
    }
  }
}

Toolbar.propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
}

export default Toolbar