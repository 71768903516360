import TextField from "../../utils/mui-rhf/TextField";
import DatePicker from "../../utils/mui-rhf/DatePicker";
import Address from "../../utils/mui-rhf/Address";
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from "react-hook-form";
import { Grid, Button, Dialog, DialogContent, DialogActions, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const CREATE_CLIENT = gql`
mutation createClient($client: InputClientParams) {
    createClient(client: $client) {
      address {
        id
      }
      id
    }
  }
`


export default function Forms({ open, handleClose, project, projectId = null, projectOwner, refetch }) {
    const { enqueueSnackbar } = useSnackbar();
    const snackbar = (message, variant) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(message, { variant });
    };
    const methods = useForm({ ...project });
    const { handleSubmit } = methods;
    const [createClient] = useMutation(CREATE_CLIENT, {
        onCompleted: () => {
            snackbar('Project Created', 'success');
            refetch();
            handleClose();
        }
    })


    const onSubmit = (values) => {
        createClient({
            variables: {
                client: {
                    projectId: projectId,
                    ...values
                }
            }
        })
    }
    return (
        <Dialog
            open={open}
            onClose={() => {
                methods.reset()
                handleClose()
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <div style={{ width: "100%" }}>
                            <Grid container spacing={2} padding={1}>
                                <Grid item xs={12} >
                                    <TextField fullWidth rules={{ required: "Required Fields" }} name={"contractNumber"} label={"Contract Number"} />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField fullWidth rules={{ required: "Required Fields" }} name={"solicitationNumber"} label={"Solicitation Number"} />
                                </Grid>
                                <Grid item xs={12} >
                                    <DatePicker fullWidth rules={{ required: "Required Fields" }} name={"dateIssued"} label={"Date Issued"} />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField fullWidth rules={{ required: "Required Fields" }} name={"issuedBy"} label={"Issued By"} />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField fullWidth rules={{ required: "Required Fields" }} name={"requisition"} label={"Requisition"} />
                                </Grid>
                                <Grid item xs={12}><Typography>Issued By: </Typography></Grid>
                                <Grid item xs={12} >
                                    <TextField fullWidth rules={{ required: "Required Fields" }} name={"name"} label={"Name"} />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField fullWidth rules={{ required: "Required Fields" }} name={"pointOfContact"} label={"Point of Contact"} />
                                </Grid>
                                <Grid item spacing={2} xs={12} >
                                    <Address
                                        methods={methods}
                                        group={'address.'}
                                        initialConfig={{ type: 'create' }}
                                        rules={
                                            {
                                                street1: { required: "Required Field" },
                                                city: { required: "Required Field" },
                                                state: { required: "Required Field" },
                                                zip: { required: "Required Field" }
                                            }
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField fullWidth rules={{ required: "Required Fields" }} name={"email"} label={"Email"} />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField fullWidth rules={{ required: "Required Fields" }} name={"telephone"} label={"Telephone"} />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField fullWidth rules={{ required: "Required Fields" }} name={"fax"} label={"Fax"} />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField fullWidth rules={{ required: "Required Fields" }} name={"code"} label={"Code"} />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField fullWidth rules={{ required: "Required Fields" }} name={"contractingOfficerName"} label={"Contracting Officer Name"} />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField fullWidth rules={{ required: "Required Fields" }} name={"contractingOfficerEmail"} label={"Contracting Officer Email"} />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField fullWidth rules={{ required: "Required Fields" }} name={"promptPaymentDiscount"} label={"Prompt Payment Discount"} />
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" type="submit">
                            {"Submit"}
                        </Button>
                        <Button onClick={() => {
                            methods.reset()
                            handleClose()
                        }}>Cancel</Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
}