import React, { FC, PropsWithChildren } from "react";
import {
  Configuration,
  Expression,
} from "src/pages/Inspections/TypeDefinitions";

interface DisplayConfigValidations {
  config: Configuration;
}

interface ExpressionWithField extends Expression {
  fieldName: string;
  fieldNumber: number;
  valueField: string | null;
}
export default function DisplayValidations({
  config,
}: DisplayConfigValidations) {
  const formValidations = config.forms.map((form) => {
    return {
      formName: form.name,
      validations: form.validations.map((validation) => {
        let fieldIndex = form.fields.findIndex(
          (x) => x.id === validation.field
        );
        let field;
        if (fieldIndex !== -1) field = form.fields[fieldIndex];
        let fieldName;
        if (field) {
          fieldName = `${field.label}${
            field.description ? ` - ${field.description}` : ""
          }`;
        }
        let newExpressions: ExpressionWithField[] = [];
        validation.expressions.forEach((expression) => {
          if (expression.fieldToCompare === null) return;
          let valueField = null;
          let fieldIndex = form.fields.findIndex(
            (field) => field.id === expression.fieldToCompare
          );
          let field = null;
          if (fieldIndex !== -1) {
            field = form.fields[fieldIndex];
          }
          if (!field) return;
          if (
            field.inputType === "OptionSelect" ||
            field.inputType === "DropDown"
          ) {
            let foundPossibleValue = field.possibleValues.find(
              (pos) => pos.id === expression.value
            );
            if (!foundPossibleValue) {
              valueField = null;
            } else {
              valueField = foundPossibleValue.label;
            }
          }
          let newExpression = {
            ...expression,
            fieldNumber: fieldIndex + 1,
            fieldName: field.label,
            valueField: valueField,
          };
          newExpressions.push(newExpression);
        });
        return {
          ...validation,
          expressions: newExpressions,
          validationFieldName: fieldName,
          validationFieldNumber: fieldIndex + 1,
        };
      }),
    };
  });
  return (
    <>
      {formValidations.map((fval) => {
        if (fval.validations.length === 0) return <></>;
        return (
          <>
            <h1>{fval.formName}</h1>
            <Ul>
              {fval.validations.map((validation) => (
                <li>
                  {validation.validationFieldNumber +
                    ". " +
                    validation.validationFieldName}
                  <Ul>
                    <li>
                      {`${validation.action} the above field${
                        validation.message && validation.action === "Error"
                          ? ` with the message: "${validation.message}"`
                          : ""
                      } when:`}
                      <Ul>
                        {validation.expressions.map((expression) => {
                          let operatorString = "";
                          switch (expression.operator) {
                            case "<":
                              operatorString = "IS LESS THAN";
                              break;
                            case ">":
                              operatorString = "IS GREATER THAN";
                              break;
                            case "==":
                              operatorString = "IS EQUAL TO";
                              break;
                          }
                          return (
                            <li>
                              {typeof expression.linkedBy === "string" ? (
                                <>
                                  {expression.linkedBy}
                                  <br />
                                </>
                              ) : null}
                              {expression.fieldNumber +
                                ". " +
                                expression.fieldName}
                              <br />
                              {operatorString !== "" ? operatorString : ""}
                              <br />
                              {expression.valueField !== null
                                ? `"${expression.valueField}"`
                                : `"${expression.value.toString()}"`}
                            </li>
                          );
                        })}
                      </Ul>
                    </li>
                  </Ul>
                </li>
              ))}
            </Ul>
          </>
        );
      })}
    </>
  );
}

const Ul: FC<PropsWithChildren<any>> = ({ children }) => {
  return <ul style={{ marginLeft: "15px" }}>{children}</ul>;
};
