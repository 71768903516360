import { Grid } from "@mui/material";
import { useContext } from "react";
import { GQLForm } from "src/utils/gql-mui-rhf/FormProvider";
import { AuthContext } from "src/utils/contexts/auth";
import { PersonContext } from "src/utils/contexts/person";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';

const GET_PERSON = gql`
query Person($person: InputPersonParams) {
  person(person: $person) {
    id
    avatar
    email
    firmId
    firstName
    lastName
    mailingAddressId
    phone1
    phone2
    preferences
    userId
  }}
`;

// ----------------------------------------------------------------------

export default function LoginForm(params) {
  let navigate = useNavigate();
  let auth = useContext(AuthContext);
  let { setPerson } = useContext(PersonContext);
  let link = params.from ? params.from : "/app";
  const [getPerson] = useLazyQuery(GET_PERSON, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      setPerson(response.person);
      navigate(link);
    }
  });
  return (
    <Grid container spacing={3} width={1000}>
      <Grid item xs={12} sm={6} md={3} width={500}>
        <GQLForm
          mutationString="signin"
          onCompleted={(response) => {
            if (response.signin) {
              auth.signin(response.signin);
              getPerson({ variables: { person:{ userId: response.signin.user.id} } })
              navigate(link);
            }
          }}
        />
      </Grid>
    </Grid>
  );
}
