import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Page from "src/components/Page";
// import AutocompleteChips from "./AutocompleteChips";
import CreateInputButtons from "./CreateInputButtons";
import DeleteDialog from "./DeleteDialog";
import DnDContext from "./DnDContext";
import Editor from "./Editor";
import FormDisplay from "./FormDisplay";
import BuilderWrapper, { FormCtx } from "./FormWrapper/BuilderWrapper";
import SubForms from "./SubForms";

export default function FormBuilder() {
  return (
    <BuilderWrapper>
      <UnwrappedFormBuilder />
    </BuilderWrapper>
  );
}

const formTypes = [
  {
    label: "Room Inspection",
    value: "RoomInspection",
  },
  {
    label: "Component Inspection",
    value: "ComponentInspection",
  },
  {
    label: "Interior Inspection",
    value: "InteriorInspection",
  },
  {
    label: "Exterior Inspection",
    value: "ExteriorInspection",
  },
  {
    label: "Subform",
    value: "SubForm",
  },
  {
    label: "Questionnaire",
    value: "Questionnaire",
  },
];

function UnwrappedFormBuilder() {
  const { state, dispatch } = useContext(FormCtx);
  const { formId } = useParams();

  useEffect(() => {
    if (state.formId !== formId) {
      dispatch({
        type: "x_Set_Form_Do_Not_Call",
        payload: {
          formId: formId,
        },
      });
    }
  }, [dispatch, formId, state.formId]);

  const form = state.data.inspectionTypeTemplate.configuration.forms.find(
    (form) => form.id === formId
  );

  return (
    <>
      {state.formId && (
        <Page
          title="Dashboard | FINBACK670"
          id="pagee"
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Breadcrumbs>
            <Link to="../../.." relative="path">
              Inspection Types List
            </Link>
            <Link to="../.." relative="path">
              Inspection Type
            </Link>
            <Link to=".." relative="path">
              Inspection Type Template
            </Link>
            <Link to="." relative="path">
              Form Builder
            </Link>
          </Breadcrumbs>
          <Box height="1rem"></Box>
          <DnDContext>
            <Box
              sx={{
                display: "flex",
                flex: "1 1 100%",
                flexDirection: "row",
                maxHeight: "100%",
              }}
            >
              <Stack direction="column" height="100%" spacing={2}>
                <TextField
                  label="Form Name"
                  value={form.name}
                  error={form.name === ""}
                  sx={{ width: 400 }}
                  size="small"
                  helperText={form.name === "" ? "Form name required." : ""}
                  onChange={(e) =>
                    dispatch({
                      type: "updateFormName",
                      payload: {
                        formName: e.target.value,
                      },
                    })
                  }
                />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                >
                  <FormGroup>
                    <FormControlLabel
                      checked={form.isEmbedded}
                      onChange={(e) =>
                        dispatch({
                          type: "updateSubformEmbedded",
                          payload: {
                            subformId: form.id,
                            isEmbeddedValue: e.target.checked,
                          },
                        })
                      }
                      control={<Checkbox />}
                      label="Embedded?"
                    />
                  </FormGroup>
                  <TextField
                    select
                    label="Form Type"
                    fullWidth
                    size="small"
                    value={form.type}
                    onChange={(e) => {
                      dispatch({
                        type: "updateFormType",
                        payload: {
                          formType: e.target.value,
                        },
                      });
                    }}
                  >
                    {formTypes.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <Paper
                  elevation={3}
                  sx={{
                    border: "3px solid #e6e6e6",
                    flex: 1,
                  }}
                >
                  <Box
                    sx={{
                      width: 370,
                      margin: 1,
                      marginTop: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {form.name &&
                      (state.editingFieldId ? (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "100%",
                              justifyContent: "center",
                              position: "relative",
                            }}
                          >
                            <IconButton
                              sx={{ position: "absolute", left: 0 }}
                              onClick={() => dispatch({ type: "closeEditor" })}
                            >
                              <ArrowBack />
                            </IconButton>
                            <Typography>Edit Form Input</Typography>
                          </Box>
                          <Editor />
                        </>
                      ) : (
                        <Stack
                          direction="column"
                          spacing={3}
                          alignItems="center"
                        >
                          <CreateInputButtons />
                          {/* <AutocompleteChips /> */}
                          <SubForms />
                        </Stack>
                      ))}
                  </Box>
                </Paper>
              </Stack>
              <FormDisplay />
              <DeleteDialog />
            </Box>
          </DnDContext>
        </Page>
      )}
    </>
  );
}
