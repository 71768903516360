import React, { useState } from 'react';
import TextField from "../../../../utils/mui-rhf/TextField";
import Select from "../../../../utils/mui-rhf/Select";
import DateTimePicker from "../../../../utils/mui-rhf/DateTimePicker";
import { FormProvider, useForm } from "react-hook-form";
import { Grid, Button } from '@mui/material';
import _ from 'lodash';
import { GQLMutationAction } from "../../../../utils/gql-mui-rhf/SingleMutation";

function createInspectionAssignment(refetch, snackbar) {
    return GQLMutationAction({
        mutationString: "createInspectionAssignment",
        onCompleted: (response) => {
            snackbar('Assignment Created', 'success');
            refetch();
        },
    });
}


export default function Forms({ snackbar, assignmentLocation, inspection, inspectors, refetch }) {
    const methods = useForm({ ...assignmentLocation });
    const { handleSubmit, setValue } = methods;
    const [currentAssignmentLocation, setAssignmentLocation] = useState({})
    const [currentInspection, setInspection] = useState({})
    const handleCreateInspectionAssignment = createInspectionAssignment(refetch, snackbar);
    const onSubmit = (values) => {
        handleCreateInspectionAssignment({
            firmPersonId: values.personId,
            personId: values.personId,
            inspectionId: values.id,
            start: values.start,
            end: values.end,
            status: "SCHEDULED",
        })
        methods.reset({})
    }

    if (assignmentLocation !== currentAssignmentLocation) {
        _.forOwn(assignmentLocation, (value, key) => {
            setValue(key, value)
        })
        setAssignmentLocation(assignmentLocation)
    }
    if (inspection !== currentInspection) {
        setValue("inspectionId", inspection.id)
        setInspection(inspection)
    }
    const options = inspectors.map((inspector) => {
        return {
            label: `${inspector.firstName} ${inspector.lastName}`,
            value: inspector.id,
            ...inspector
        }
    })

    return (
        <div style={{ width: "100%" }}>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} padding={1}>
                        <Grid item spacing={2} xs={12} >
                            <TextField disabled={true} key={1} fullWidth label={"Street 1"} name={"street1"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} >
                            <TextField disabled={true} key={1} fullWidth label={"Street 2"} name={"street2"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} >
                            <TextField disabled={true} key={1} fullWidth label={"Street 3"} name={"street3"} />
                        </Grid>
                        <Grid item spacing={2} xs={4} >
                            <TextField disabled={true} key={2} fullWidth label={"City"} name={"city"} />
                        </Grid>
                        <Grid item spacing={2} xs={4} >
                            <TextField disabled={true} key={3} fullWidth label={"State"} name={"state"} />
                        </Grid>
                        <Grid item spacing={2} xs={4} >
                            <TextField disabled={true} key={3} fullWidth label={"Zip"} name={"zip"} />
                        </Grid>
                        <Grid item spacing={2} xs={12}>
                            <Select options={options} fullWidth label={"Inspector"} name={"personId"} rules={{required: true}} />
                        </Grid>
                        <Grid item spacing={2} xs={12}>
                            <DateTimePicker fullWidth label={"Start"} name={"start"} rules={{
                                required: true,
                                validate: {
                                    afterNow: (v) => v > new Date() || "Start date must be in the future",
                                    beforeEnd: (v, fV) => (fV.end && v < fV.end) || "Start date must come before end date"
                                }
                            }} />
                        </Grid>
                        <Grid item spacing={2} xs={12} style={{ marginBottom: 32 }}>
                            <DateTimePicker fullWidth label={"End"} name={"end"} rules={{
                                required: true,
                                validate: {
                                    afterStart: (v, fV) => v > fV.start || "End date must come after start date",
                                    notOverThree: (v, fV) => (v - fV.start) / (1000*60*60) <= 3 || "Assignments cannot exceed 3 hours"
                                }
                            }} />
                        </Grid>
                    </Grid>
                    <Button variant="contained" type="submit">
                        submit
                    </Button>
                </form>
            </FormProvider>
        </div>

    );
}

