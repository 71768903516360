import { useState } from "react"
import {
    Button,
    Grid
} from '@mui/material';
import OfferorProfile from './offerorProfile';
// material
import CreateOfferor from '../createOfferor';
// ----------------------------------------------------------------------

export default function Offeror({ project }) {
    let projectId = project.id
    const [addOfferor, setAddOfferor] = useState(false);
    const closeAddProject = () => setAddOfferor(false)
    const openAddProject = () => setAddOfferor(true)
    // eslint-disable-next-line no-unused-vars
    const [shouldRefetch, setShouldRefetch] = useState(false);
    const refetch = () => setShouldRefetch(true)
    if (project.offeror) {
        return <OfferorProfile offeror={project.offeror} />
    } else {
        return (
            <>
                <CreateOfferor
                    open={addOfferor}
                    handleClose={closeAddProject}
                    projectId={projectId}
                    refetch={refetch}
                />
                <Grid container>
                    <Grid>
                        <Button onClick={openAddProject}>
                            Add Offeror
                        </Button>
                    </Grid>
                </Grid>
            </>
        )
    }
}
