import { Action, PageState } from "../../TypeDefinitions";

export function updatePossibleValue<
  E extends Extract<
    Action,
    {
      type: "updatePossibleValue";
    }
  >
>(action: E, draft: PageState) {
  const { label, possibleValueId } = action.payload;

  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );

  if (formIndex === -1) return;

  let fieldCopy = draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.find((field) => field.id === draft.editingFieldId);
  if (!fieldCopy) return;

  switch (fieldCopy.inputType) {
    case "Checklist":
    case "OptionSelect":
    case "DropDown":
      fieldCopy.possibleValues.every((possibleValue) => {
        if (possibleValue.id === possibleValueId) {
          possibleValue.label = label;
          possibleValue.name = label;
          possibleValue.value = label;
          return false;
        }
        return true;
      });

      break;
    case "Text":
    case "Date":
    case "DateTime":
    case "Email":
    case "LongText":
    case "Number":
    case "Phone":
    case "PhotoCollection":
    case "Signature":
    case "Time":
    case "YesNo":
      break;
    default: {
      const _exhaustiveCheck: never = fieldCopy;
      return _exhaustiveCheck;
    }
  }

  draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.forEach((field) => {
    if (fieldCopy !== undefined && field.id === draft.editingFieldId) {
      field = fieldCopy;
    }
  });
}
