import { Grid, Button } from "@mui/material";
import { Icon } from "@iconify/react";
import plusSquareOutline from "@iconify/icons-eva/plus-square-outline";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import editOutline from "@iconify/icons-eva/edit-outline";

function actionText(action, length) {
    if (length === 1) {
      return `${action} Record`;
    }
    return `${action} ${length} Records`;
  }

function additionalActions(customActions, selectionModel) {
    return customActions.map((action) => {
        return selectionModel.length > 0 && (
            <Grid item>
                <Button
                    onClick={() => {
                        action.callback(selectionModel);
                    }}
                    variant="outlined"
                    startIcon={<Icon icon={action.icon} />}
                >
                    {actionText(action.text, selectionModel.length)}
                </Button>
            </Grid>
        )
    })
}

function renderTableActions({
    selectionModel,
    setConfirmation,
    addRecord,
    deleteRecords,
    editRecord,
    customActions = []
}) {
    if (!addRecord && !deleteRecords && !editRecord && customActions.length === 0) return null;
    return (
        <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={4}
        >
            <Grid item>
                <h4>Actions: </h4>
            </Grid>
            {deleteRecords && selectionModel.length > 0 && (
                <Grid item>
                    <Button
                        onClick={() => {
                            setConfirmation({
                                open: true,
                                action: () => {
                                    deleteRecords(selectionModel);
                                },
                                actionText: actionText("Delete", selectionModel.length),
                            });
                        }}
                        variant="outlined"
                        startIcon={<Icon icon={trash2Outline} />}
                    >
                        {actionText("Delete", selectionModel.length)}
                    </Button>
                </Grid>
            )}
            {addRecord && (
                <Grid item>
                    <Button
                        onClick={addRecord}
                        variant="outlined"
                        startIcon={<Icon icon={plusSquareOutline} />}
                    >
                        Add New
                    </Button>
                </Grid>
            )}
            {editRecord && selectionModel.length === 1 && (
                <Grid item>
                    <Button
                        onClick={editRecord}
                        variant="outlined"
                        startIcon={<Icon icon={editOutline} />}
                    >
                        Edit
                    </Button>
                </Grid>
            )}
            {additionalActions(customActions, selectionModel, selectionModel)}
        </Grid>
    );
}

export default renderTableActions