import {
  InputType,
  Field,
  FormElement,
  PageState,
  Action,
} from "../../TypeDefinitions";
import { v4 as uuidv4 } from "uuid";

export function createField<
  E extends Extract<
    Action,
    {
      type: "createField";
    }
  >
>(action: E, draft: PageState): void {
  const { displayIndex, inputType } = action.payload;

  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );

  let newField: Field;

  if (inputType === "paste" && draft.fieldToCopy) {
    let fieldCopy = { ...draft.fieldToCopy };
    fieldCopy.id = uuidv4();
    newField = fieldCopy;
  } else if (inputType !== "paste") {
    newField = generateNewField(
      inputType,
      draft.data.inspectionTypeTemplate.configuration.forms[formIndex],
      displayIndex
    );
  } else return;

  draft.editingFieldId = newField.id;

  if (displayIndex === undefined) {
    draft.data.inspectionTypeTemplate.configuration.forms[
      formIndex
    ].fields.push(newField);
    return;
  }

  draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.splice(displayIndex, 0, newField);

  draft.data.inspectionTypeTemplate.configuration.forms[formIndex].fields.map(
    (field, i) => (field.displayIndex = i)
  );
}

function generateNewField(
  inputType: InputType,
  form: FormElement,
  displayIndex?: number
): Field {
  let newFieldValues = {
    id: uuidv4(),
    formId: form.id,
    isVisible: true,
    isRequired: false,
    label: "",
    name: "",
    description: "",
    displayIndex: displayIndex || form.fields.length,
  };

  switch (inputType) {
    case "Checklist": {
      return {
        ...newFieldValues,
        inputType: inputType,
        possibleValues: [],
      };
    }
    case "OptionSelect": {
      return {
        ...newFieldValues,
        inputType: inputType,
        possibleValues: [],
      };
    }
    case "DropDown": {
      return {
        ...newFieldValues,
        inputType: inputType,
        possibleValues: [],
      };
    }
    case "Text": {
      return {
        ...newFieldValues,
        inputType: inputType,
        placeholder: "",
      };
    }
    case "LongText": {
      return {
        ...newFieldValues,
        inputType: inputType,
        placeholder: "",
      };
    }
    case "Date": {
      return {
        ...newFieldValues,
        minDate: null,
        maxDate: null,
        inputType: inputType,
      };
    }
    case "DateTime": {
      return {
        ...newFieldValues,
        minDate: null,
        maxDate: null,
        inputType: inputType,
      };
    }
    case "Time": {
      return {
        ...newFieldValues,
        inputType: inputType,
      };
    }
    case "Number": {
      return {
        ...newFieldValues,
        inputType: inputType,
      };
    }
    case "YesNo": {
      return {
        ...newFieldValues,
        inputType: inputType,
      };
    }
    case "Email": {
      return {
        ...newFieldValues,
        inputType: inputType,
      };
    }
    case "Phone": {
      return {
        ...newFieldValues,
        inputType: inputType,
      };
    }
    case "PhotoCollection": {
      return {
        ...newFieldValues,
        inputType: inputType,
      };
    }
    case "Signature": {
      return {
        ...newFieldValues,
        inputType: inputType,
      };
    }
    default: {
      const _exhaustiveCheck: never = inputType;
      return _exhaustiveCheck;
    }
  }
}
