import { gql } from "@apollo/client";
import { dec2bin } from "src/utils/decimalToBinary";

export const getPersonQueryComponents = (personIds: string[]) => {
  let variables: any = {};
  const queryParams = personIds
    .map((id, i) => `$${dec2bin(i, true)}: InputPersonParams`)
    .join(", ");
  const aliasedQueries = () => {
    let queryArray: string[] = [];
    personIds.forEach((id, i) => {
      const binaryChar = dec2bin(i, true);
      variables[binaryChar] = { id: id };
      queryArray.push(`${binaryChar}: person(person: $${binaryChar}) {
          id
      avatar
      email
      firmId
      firstName
      lastName
      mailingAddressId
      phone1
      phone2
      preferences
      userId
      isDeleted
        }
        `);
    });
    return queryArray.join();
  };

  const queryString = `
      query Person(${queryParams}) {
        ${aliasedQueries()}
      }
    `;
  return [gql(queryString), variables];
};
