import {
  CopyAll,
  Delete,
  FilterAlt,
  Search,
  Sort,
  SortByAlpha,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormCtx } from "../FormWrapper/BuilderWrapper";
import { Reference } from "../Reference";

const formTypes = [
  "ComponentInspection",
  "RoomInspection",
  "SubForm",
  "ExteriorInspection",
  "InteriorInspection",
];

export default function SelectFormPage() {
  const { state, dispatch } = useContext(FormCtx);
  const [formType, setFormType] = useState(formTypes[0]);
  const [formTypeFilter, setFormTypeFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [alphaSort, setAlphaSort] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deletePrompt, setDeletePrompt] = useState({
    open: false,
    id: null,
    formName: "",
  });
  const [copyPrompt, setCopyPrompt] = useState({
    open: false,
    id: null,
    formName: "",
  });

  const navigate = useNavigate();

  const forms = state.data.inspectionTypeTemplate.configuration.forms || [];

  const filteredForms = forms
    .filter(
      (form) =>
        (!search || form.name.toLowerCase().includes(search.toLowerCase())) &&
        (formTypeFilter.length === 0 || formTypeFilter.includes(form.type))
    )
    .sort((form1, form2) => {
      if (alphaSort) {
        if (form1.name.toLowerCase() < form2.name.toLowerCase()) {
          return -1;
        }
        if (form1.name.toLowerCase() > form2.name.toLowerCase()) {
          return 1;
        }
        return 0;
      } else {
        return -1;
      }
    });

  return (
    <>
      <Breadcrumbs>
        <Link to="../..">Inspection Types List</Link>
        <Link to=".." relative="path">
          Inspection Type
        </Link>
        <Link to=".">Inspection Type Template</Link>
      </Breadcrumbs>

      <Box height="0.5rem"></Box>
      <Stack
        direction="row"
        alignItems="start"
        justifyContent="space-between"
        minWidth="100%"
      >
        <List sx={{ maxWidth: "30rem", width: "50%" }}>
          <Stack
            sx={{ marginBottom: "1rem" }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1">
              Please select or create a form.
            </Typography>
            <Link to="./validations">View Validations</Link>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "100px",
                },
              }}
              fullWidth
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              label="Search . . ."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <ButtonGroup>
              <Filter
                formTypeFilter={formTypeFilter}
                setFormTypeFilter={setFormTypeFilter}
              />
              <Button
                variant="outlined"
                sx={{ borderRadius: "100px" }}
                endIcon={alphaSort ? <SortByAlpha /> : <Sort />}
                onClick={(e) => setAlphaSort(!alphaSort)}
              >
                Sort
              </Button>
            </ButtonGroup>
          </Stack>

          <Button
            variant="outlined"
            sx={{
              width: "100%",
              marginTop: "1rem",
            }}
            onClick={(e) => setDialogOpen(true)}
          >
            Create Form
          </Button>
          {filteredForms.map((form, i) => {
            return (
              <ListItem
                secondaryAction={
                  <Stack direction="row">
                    <IconButton
                      onClick={(e) =>
                        setCopyPrompt({
                          id: form.id,
                          formName: "",
                          open: true,
                        })
                      }
                    >
                      <CopyAll />
                    </IconButton>
                    <IconButton
                      edge="end"
                      onClick={(e) =>
                        setDeletePrompt({
                          id: form.id,
                          open: true,
                          formName: form.name
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, function (str) {
                              return str.toUpperCase();
                            }),
                        })
                      }
                    >
                      <Delete />
                    </IconButton>
                  </Stack>
                }
                key={form.id}
              >
                <ListItemButton onClick={(e) => navigate(form.id)}>
                  <ListItemText
                    primary={form.name}
                    secondary={`${form.type
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}   ·   ${form.fields.length} fields   ·   ${
                      form.subformIds?.length || 0
                    } subforms`}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <Stack direction="row" spacing={1}>
          <Button variant="outlined" LinkComponent={Link} to="./excelMapping">
            Excel Mapper
          </Button>
          <Reference
            referenceMaterialIds={
              state.data.inspectionTypeTemplate.configuration
                ?.referenceMaterialIds ?? []
            }
            config={{ selectable: false }}
          />
        </Stack>
      </Stack>
      <Dialog open={dialogOpen} onClose={(e) => setDialogOpen(false)}>
        <DialogTitle>Create which type of form?</DialogTitle>
        <DialogContent>
          <TextField
            select
            value={formType}
            fullWidth
            onChange={(e) => setFormType(e.target.value)}
          >
            {formTypes.map((formType) => (
              <MenuItem value={formType} key={formType}>
                {formType
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, function (str) {
                    return str.toUpperCase();
                  })}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={(e) =>
              dispatch({
                type: "createForm",
                payload: {
                  type: formType,
                },
              })
            }
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deletePrompt.open}
        onClose={(e) => setDeletePrompt({ ...deletePrompt, open: false })}
      >
        <DialogTitle>Confirm Form Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete {deletePrompt?.formName}?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => setDeletePrompt({ ...deletePrompt, open: false })}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              dispatch({
                type: "deleteForm",
                payload: {
                  formId: deletePrompt.id,
                },
              });
              setDeletePrompt({ ...deletePrompt, open: false });
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={copyPrompt.open}
        onClose={(e) => setCopyPrompt({ ...copyPrompt, open: false })}
      >
        <DialogTitle>Please enter a name for the new form copy.</DialogTitle>
        <DialogContent>
          <TextField
            value={copyPrompt.formName}
            sx={{ marginTop: "0.5rem" }}
            onChange={(e) =>
              setCopyPrompt({ ...copyPrompt, formName: e.target.value })
            }
            label="Form Copy Name"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => setCopyPrompt({ ...copyPrompt, open: false })}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              dispatch({
                type: "duplicateForm",
                payload: {
                  formId: copyPrompt.id,
                  newFormName: copyPrompt.formName,
                },
              });
              setCopyPrompt({ ...copyPrompt, open: false });
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const Filter = ({ setFormTypeFilter, formTypeFilter }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant={
          formTypeFilter && formTypeFilter.length > 0 ? "contained" : "outlined"
        }
        sx={{ borderRadius: "100px" }}
        endIcon={<FilterAlt />}
        onClick={handleClick}
      >
        Filter
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {formTypes.map((formType) => (
          <MenuItem
            dense
            onClick={(e) => {
              if (formTypeFilter.includes(formType)) {
                setFormTypeFilter(
                  formTypeFilter.filter((form) => form !== formType)
                );
              } else {
                setFormTypeFilter([...formTypeFilter, formType]);
              }
            }}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={formTypeFilter.includes(formType)}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            {formType
              .replace(/([A-Z]+)([A-Z][a-z])/g, " $1 $2")
              // Look for lower-case letters followed by upper-case letters
              .replace(/([a-z\d])([A-Z])/g, "$1 $2")
              // Look for lower-case letters followed by numbers
              .replace(/([a-zA-Z])(\d)/g, "$1 $2")
              .replace(/^./, function (str) {
                return str.toUpperCase();
              })
              // Remove any white space left around the word
              .trim()}
          </MenuItem>
        ))}
        {formTypeFilter && formTypeFilter.length > 0 && (
          <ListItem dense>
            <Button
              onClick={(e) => setFormTypeFilter([])}
              variant="text"
              size="small"
              sx={{ marginX: "auto" }}
            >
              Clear Filters
            </Button>
          </ListItem>
        )}
      </Menu>
    </>
  );
};
