import { useState } from 'react';
import { useSnackbar } from 'notistack';
//gql-mui-rhf
import createQuery from '../../utils/gql-mui-rhf/QueryAction';
import { GQLMutationAction } from '../../utils/gql-mui-rhf/SingleMutation';
import GQLTable from '../../utils/gql-mui-rhf/Table';
import { GQLForm } from '../../utils/gql-mui-rhf/FormProvider';

// material
import { Grid, Typography, IconButton, Dialog, DialogContent, Box, CircularProgress } from '@mui/material';
import { Icon } from '@iconify/react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import leftArrow from '@iconify/icons-eva/arrow-circle-left-fill';
import Confirmation from '../../components/modals/confirmation';
import { Link, useParams } from "react-router-dom";


// ----------------------------------------------------------------------

const breadcrumbs = [
    <Link
        to="/app/admin/credential/"
    >
        Credentials List
    </Link>,
    <Typography key="3" color="text.primary">
        Credential View
    </Typography>,
];

function generateMutation(refetch, snackbar, shouldRefetch) {
    return GQLMutationAction({
        mutationString: "createCredentialTypeValue",
        onCompleted: (response) => {
            refetch();
            shouldRefetch();
            snackbar('Added Field', 'success');
        }
    })
}
function updateCredentialType(refetch, snackbar, shouldRefetch) {
    return GQLMutationAction({
        mutationString: "updateCredentialType",
        onCompleted: (response) => {
            refetch();
            shouldRefetch();
            snackbar('Updated Credential', 'success');
        }
    })
}
const renderDefaultArray = (array, addCredentialTypeValue, credentialType, existingValues) => {
    const activeValues = existingValues.filter(item => item.active)
    const filteredArray = array.filter(item => !activeValues.some(item2 => item2.name === item.name))
    return filteredArray.map((defaultField, idx) => (
        <Grid item container alignItems="center" key={idx}>
            <IconButton
                size="large"
                color={'primary'}
                onClick={() => {
                    addCredentialTypeValue({ name: defaultField.name, type: defaultField.type, credentialTypeId: credentialType.id })
                }}
            >
                <Icon icon={leftArrow} width={30} height={30} />
            </IconButton>
            <div>{defaultField.name}</div>
        </Grid>
    ))
}
const renderDefaults = (credentialTypeValues, credentialType, personDefaults, firmDefaults, addCredentialTypeValue) => {
    if (credentialType.entity === "PERSON") {
        return renderDefaultArray(personDefaults, addCredentialTypeValue, credentialType, credentialTypeValues)
    }
    if (credentialType.entity === "FIRM") {
        return renderDefaultArray(firmDefaults, addCredentialTypeValue, credentialType, credentialTypeValues)
    }
}
function addCustomField(open, handleClose, addMutationString, additionalVariables, shouldRefetch, snackbar, restrictedColumns) {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <GQLForm mutationString={addMutationString}
                    additionalVariables={additionalVariables}
                    inputOrder={{ name: { order: 1 }, type: { order: 2 } }}
                    skipFields={{ id: true, credentialTypeId: true, active: true, label: true, required: true, tier1: true, tier2: true, inputMask: true, helperText: true, placeholder: true }} onCompleted={() => {
                        shouldRefetch();
                        handleClose()
                        snackbar('Added Custom Field', 'success')
                    }} />
            </DialogContent>
        </Dialog>
    )
}

function immutableMove(handleUpdateCredentialType, arr, rowTransaction) {
    const from = rowTransaction.oldIndex
    const to = rowTransaction.targetIndex
    const newOrder = arr.reduce((prev, current, idx, self) => {
        if (from === to) {
            prev.push(current);
        }
        if (idx === from) {
            return prev;
        }
        if (from < to) {
            prev.push(current);
        }
        if (idx === to) {
            prev.push(self[from]);
        }
        if (from > to) {
            prev.push(current);
        }
        return prev;
    }, []);
    const credentialTypeId = newOrder[0].credentialTypeId;
    const idOrder = newOrder.map((e) => e.id);
    handleUpdateCredentialType({ id: credentialTypeId, valueOrder: idOrder })
}

export default function CredentialTypeView() {
    const params = useParams()
    const credentialTypeId = params.id
    const [refetchTable, setRefetchTable] = useState(false);
    const [customFieldModal, setCustomFieldModal] = useState(false);
    const shouldRefetchTable = () => setRefetchTable(true)
    const openCustomFieldModal = () => setCustomFieldModal(true)
    const closeCustomFieldModal = () => setCustomFieldModal(false)
    const { enqueueSnackbar } = useSnackbar();
    const snackbar = (message, variant) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(message, { variant });
    };
    const [confirmation, setConfirmation] = useState({ open: false });
    const closeConfirmation = () => { setConfirmation({ open: false }) };
    const { loading, error, data, refetch } = createQuery('credentialTypeValues', { active: true, credentialTypeId: credentialTypeId })
    const cType = createQuery('credentialType', { id: credentialTypeId })
    const personDefaults = [{ name: "First Name", type: "TEXT" }, { name: "Last Name", type: "TEXT" }]
    const firmDefaults = [{ name: "Name", type: "TEXT" }, { name: "DBA", type: "TEXT" }]
    const addCredentialTypeValue = generateMutation(refetch, snackbar, shouldRefetchTable);
    const handleUpdateCredentialType = updateCredentialType(refetch, snackbar, shouldRefetchTable);
    if (loading || cType.loading)
        return <CircularProgress />
    if (error || cType.error) {
        return <div>{`${error || cType.error}`}</div>
    }
    if (data && cType.data) {
        return (
            <Grid container>
                <Confirmation confirmation={confirmation} handleClose={closeConfirmation} />
                {addCustomField(customFieldModal, closeCustomFieldModal, "createCredentialTypeValue", { credentialTypeId }, shouldRefetchTable, snackbar)}
                <Grid item xs={8} style={{ padding: '16pt' }}>
                    <Box sx={{ pb: 5 }}>

                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                        >
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Box>
                    <Grid container direction="row" alignItems={"center"}>
                        <Typography variant="h6">{`Credential Attributes for ${cType.data.credentialType.name}`}</Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent={"flexStart"} alignItems={"flexStart"}>
                    <Grid item xs={9}>
                        <GQLTable
                            queryString={'credentialTypeValues'}
                            queryVariables={{ "active": true, "credentialTypeId": credentialTypeId }}
                            rowOrder={cType.data.credentialType.valueOrder}
                            handleRowOrderChange={immutableMove.bind(null, handleUpdateCredentialType)}
                            deleteMutationString={'deleteCredentialTypeValue'}
                            updateMutationString={'updateCredentialTypeValue'}
                            columnOptions={{
                                "label": {
                                    editable: true,
                                    width: 150
                                },
                                "name": {
                                    editable: true
                                },
                                "inputMask": {
                                    headerName: "INPUT MASK",
                                    editable: true,
                                    width: 150
                                },
                                "helperText": {
                                    headerName: "HELPER TEXT",
                                    editable: true,
                                    width: 150
                                },
                                "placeholder": {
                                    editable: true,
                                    width: 150
                                },
                                "tier1": {
                                    headerName: "TIER 1",
                                    type: 'boolean',
                                    editable: true
                                },
                                "tier2": {
                                    headerName: "TIER 2",
                                    type: 'boolean',
                                    editable: true
                                },
                                "required": {
                                    type: 'boolean',
                                    editable: true
                                },
                                "type": {
                                    editable: false
                                }

                            }}
                            columnOrder={["name", "type", "label", "required", "tier1", "tier2", "inputMask", "helperText", "placeholder", "credentialTypeId"]}
                            restrictedColumns={{ valueOrder: true, id: true, credentialType: true, credentialTypeId: true, active: true }}
                            shouldRefetch={refetchTable}
                            setShouldRefetch={setRefetchTable}
                        />
                    </Grid>
                        <Grid item container direction="column" justifyContent="flex-start" alignItems="flex-start" xs={3} style={{ padding: '16pt' }} >
                            <Typography variant="h6">Not Selected</Typography>
                            <Typography variant="h7">Field</Typography>
                            {renderDefaults(data.credentialTypeValues, cType.data.credentialType, personDefaults, firmDefaults, addCredentialTypeValue)}
                            <Grid item container alignItems="center">
                                <IconButton
                                    size="large"
                                    color={'primary'}
                                    onClick={openCustomFieldModal}
                                >
                                    <Icon icon={leftArrow} width={30} height={30} />
                                </IconButton>
                                <div>{"[Custom Field]"}</div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                );
    }
}
