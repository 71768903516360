import { TreeItem } from "@mui/lab";
import { IFormJson, InspectionDisplayProps } from "..";
import FieldDisplayForForm from "./FieldDisplayForForm";
import getForm from "./utilities/getForm";

export default function InspectionTreeRecurse({
  config,
  parentNodeId,
  formJson,
  parentChain,
  callDrilledUpdate,
}: InspectionDisplayProps & {
  parentChain?: string[];
  parentNodeId?: string;
  callDrilledUpdate: (parentChain: string[], newForm: IFormJson) => void;
}) {
  const form = getForm(config, formJson.formId);
  if (form) {
    const newNodeId = formJson.id;
    const currentParentChain = parentChain || [];
    const newParentChain: string[] = [...currentParentChain, formJson.id];
    function updateNewFormJson(newForm: IFormJson) {
      callDrilledUpdate(newParentChain, newForm);
    }
    return (
      <TreeItem label={form.name} nodeId={newNodeId}>
        <FieldDisplayForForm
          form={form}
          formJson={formJson}
          updateFormJson={updateNewFormJson}
        />
        {formJson.subFormInstances &&
          formJson.subFormInstances.map((subform: IFormJson) => {
            return (
              <InspectionTreeRecurse
                key={subform.id}
                callDrilledUpdate={callDrilledUpdate}
                formJson={subform}
                config={config}
                parentNodeId={newNodeId}
                parentChain={newParentChain}
              />
            );
          })}
      </TreeItem>
    );
  } else {
    return <p>Error generating report view.</p>;
  }
}
