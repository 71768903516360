import { gql, useMutation, useQuery } from "@apollo/client";
import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

const templatesQuery = gql`
  query InspectionType($inspectionType: InputInspectionTypeParams) {
    inspectionType(inspectionType: $inspectionType) {
      description
      id
      inspectionTypeTemplates {
        id
        name
        description
      }
      name
    }
  }
`;

const DELETE_INSPECTION_TYPE_TEMPLATE = gql`
  mutation DeleteInspectionTypeTemplate($deleteInspectionTypeTemplateId: ID!) {
    deleteInspectionTypeTemplate(id: $deleteInspectionTypeTemplateId) {
      id
    }
  }
`;

const columns = (handleDeleteClick) => [
  {
    field: "Edit",
    width: 50,
    renderCell: (params) => (
      <Link to={params.id}>
        <Edit color="primary" />
      </Link>
    ),
  },
  {
    field: "name",
    headerName: "Template Name",
    width: 250,
  },
  {
    field: "start",
    headerName: "Start Date",
    width: 100,
    valueGetter: (params) => moment(params.start).format("MM/DD/YYYY"),
  },
  {
    field: "end",
    headerName: "End Date",
    width: 100,
    valueGetter: (params) => moment(params.start).format("MM/DD/YYYY"),
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
  },
  {
    headerName: "Delete",
    width: 70,
    renderCell: (params) => (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={(e) =>
            handleDeleteClick({ id: params.id, open: true, name: params.name })
          }
        >
          <Delete />
        </IconButton>
      </Box>
    ),
  },
];

export default function TemplatesView() {
  const { inspectionTypeId } = useParams();
  const { loading, data, error, refetch } = useQuery(templatesQuery, {
    fetchPolicy: "network-only",
    variables: {
      inspectionType: {
        id: inspectionTypeId,
      },
    },
  });

  const [inspectionTypeTemplateDelete] = useMutation(
    DELETE_INSPECTION_TYPE_TEMPLATE
  );

  const [deletePrompt, setDeletePrompt] = useState({
    open: false,
    id: null,
    formName: "",
  });

  return (
    <>
      {loading && <CircularProgress />}
      {data && (
        <>
          <DataGrid
            sx={{ marginTop: "1rem" }}
            autoHeight
            rows={data.inspectionType.inspectionTypeTemplates}
            columns={columns(setDeletePrompt)}
          />

          <Dialog
            open={deletePrompt.open}
            onClose={(e) => setDeletePrompt({ ...deletePrompt, open: false })}
          >
            <DialogTitle>Confirm Template Delete</DialogTitle>
            <DialogContent>
              Are you sure you want to delete {deletePrompt?.templateName}?
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) =>
                  setDeletePrompt({ ...deletePrompt, open: false })
                }
              >
                Cancel
              </Button>
              <Button
                onClick={async (e) => {
                  await inspectionTypeTemplateDelete({
                    variables: {
                      deleteInspectionTypeTemplateId: deletePrompt.id,
                    },
                  });
                  setDeletePrompt({ ...deletePrompt, open: false });
                  refetch();
                }}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {error && <h1>{error.message}</h1>}
    </>
  );
}
