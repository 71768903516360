import SchemaContext from "./SchemaContext";
import { useContext } from "react";
import { useLazyQuery } from '@apollo/client';
import { getQueryInputs, getQueryReturn, generateQuery } from './QueryHelpers';

const GQLUseLazyQuery = (queryString, onCompleted = () => {}, onError = () => {}) => {
  const schema = useContext(SchemaContext);
  const query = schema.queries[queryString];
  if(!query) throw Error('Undefined Query')
  const queryInputs = getQueryInputs(query, schema);
  const queryReturn = getQueryReturn(query, schema);
  const queryDocument = generateQuery(queryInputs, queryReturn, queryString)
  return useLazyQuery(queryDocument, {
    fetchPolicy: 'network-only',
    onCompleted,
    onError
  });
};

export default GQLUseLazyQuery;
