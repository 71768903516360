import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
  TextField,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  RadioGroup,
  Radio,
  Button,
  Stack,
  MenuItem,
} from "@mui/material";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { Gesture } from "@mui/icons-material";
import {
  TextField as TextFieldType,
  Field,
  ChecklistField,
  LongTextField,
  PossibleValue,
  OptionSelectField,
  DropDownField,
  YesNoField,
  PhoneField,
  EmailField,
  NumberField,
  DateField,
  DateTimeField,
  PhotoCollectionField,
  TimeField,
  SignatureField,
} from "./TypeDefinitions";
import {
  IFormField,
  IFormJson,
  IFormValue,
} from "../Review/pages/InspectionAssignment/components/InspectionDisplay";
import { v4 as uuidv4 } from "uuid";

interface FormStateHandler {
  completedData: IFormField;
  updateData: (data: IFormField) => void;
  formJson: IFormJson;
  firmId: string;
  personId: string;
}
interface ChildFieldProps {
  fieldData: Field;
  formState?: FormStateHandler;
}

export default function ChildField({ fieldData, formState }: ChildFieldProps) {
  switch (fieldData.inputType) {
    case "Text":
      return <FormShortText fieldData={fieldData} formState={formState} />;
    case "LongText":
      return <FormLongText fieldData={fieldData} formState={formState} />;
    case "Phone":
      return <FormPhone fieldData={fieldData} formState={formState} />;
    case "Checklist":
      return <FormChecklist fieldData={fieldData} formState={formState} />;
    case "OptionSelect":
      return <FormRadio fieldData={fieldData} formState={formState} />;
    case "DropDown":
      return <FormDropDown fieldData={fieldData} formState={formState} />;
    case "YesNo":
      return <YesNo fieldData={fieldData} formState={formState} />;
    case "Email":
      return <FormEmail fieldData={fieldData} formState={formState} />;
    case "Number":
      return <FormNumber fieldData={fieldData} formState={formState} />;
    case "Date":
      return <FormDate fieldData={fieldData} formState={formState} />;
    case "DateTime":
      return <FormDateTime fieldData={fieldData} formState={formState} />;
    case "Time":
      return <FormTime fieldData={fieldData} />;
    case "PhotoCollection":
      return <FormPhoto fieldData={fieldData} />;
    case "Signature":
      return <FormSignature fieldData={fieldData} />;
    default:
      return null;
  }
}

// DONE!
const FormShortText: FC<{
  fieldData: TextFieldType;
  formState?: FormStateHandler;
}> = ({ fieldData, formState }) => {
  const { label, description, placeholder } = fieldData;

  const updateField = (newValue: string) => {
    if (!formState) return null;

    const { completedData, updateData, formJson, firmId, personId } = formState;
    let newFieldData = updatePrepopData(
      completedData,
      formJson,
      firmId,
      personId,
      { textValue: newValue }
    );
    updateData(newFieldData);
  };

  return (
    <TextField
      label={label}
      fullWidth
      helperText={description}
      placeholder={placeholder}
      onChange={(e) => updateField(e.target.value)}
      value={
        formState?.completedData.formInput.formValue?.textValue || undefined
      }
    />
  );
};

const FormSignature: FC<{ fieldData: SignatureField }> = ({ fieldData }) => {
  const { label, description } = fieldData;
  return (
    <Stack direction="column">
      <FormLabel>{label}</FormLabel>
      <FormHelperText>{description}</FormHelperText>
      <Button
        endIcon={<Gesture />}
        variant="contained"
        sx={{ marginTop: "1rem" }}
      >
        Capture Signature
      </Button>
    </Stack>
  );
};

const FormChecklist: FC<{
  fieldData: ChecklistField;
  formState?: FormStateHandler;
}> = ({ fieldData, formState }) => {
  const { possibleValues, description, label } = fieldData;
  function convertPossibleValuesToObj(
    possibleValues: PossibleValue[],
    selectedValues: string[] | null
  ) {
    let obj: any = {};
    possibleValues?.forEach(function (value) {
      obj[value.id] = selectedValues && selectedValues.includes(value.label);
    });
    return obj;
  }

  const [checkedValues, setCheckedValues] = useState(
    convertPossibleValuesToObj(
      possibleValues,
      formState?.completedData.formInput.formValue?.textValues || null
    )
  );

  // useEffect(() => {
  //   if (
  //     formState &&
  //     formState.completedData.formInput.formValue &&
  //     formState.completedData.formInput.formValue.textValues
  //   ) {
  //     let checkedValuesCopy = { ...checkedValues };
  //     possibleValues.forEach((pv) => {
  //       const existsInTextValuesField =
  //         formState.completedData.formInput.formValue!.textValues?.some(
  //           (textValue) => pv.label === textValue
  //         ) || false;
  //       checkedValuesCopy[pv.id] = existsInTextValuesField;
  //     });
  //     setCheckedValues(checkedValuesCopy);
  //   }
  // }, [possibleValues, formState]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedValues({
      ...checkedValues,
      [event.target.name]: !checkedValues[event.target.name],
    });
    if (formState) {
      let newCheckedValuesObj = {
        ...checkedValues,
        [event.target.name]: !checkedValues[event.target.name],
      };
      let valuesArray: string[] = [];
      for (const [key, value] of Object.entries(newCheckedValuesObj)) {
        if (value === true) {
          const textValue = possibleValues.find((pv) => pv.id === key)?.label;
          if (!textValue) return;
          valuesArray.push(textValue);
        }
      }
      let newField = updatePrepopData(
        formState.completedData,
        formState.formJson,
        formState.firmId,
        formState.personId,
        { textValues: valuesArray }
      );

      formState.updateData(newField);
    }
  };

  return (
    <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {possibleValues?.map(function (value) {
          return (
            <FormControlLabel
              key={value.id}
              control={
                <Checkbox
                  name={value.id}
                  checked={checkedValues[value.id]}
                  onChange={handleChange}
                />
              }
              label={value.label}
            />
          );
        })}
      </FormGroup>
      {description && <FormHelperText>{description}</FormHelperText>}
    </FormControl>
  );
};

const FormRadio: FC<{
  fieldData: OptionSelectField;
  formState?: FormStateHandler;
  isYesNo?: boolean;
}> = ({ fieldData, formState, isYesNo }) => {
  const { label, description, possibleValues } = fieldData;
  const [selected, setSelected] = useState<string | null>(null);

  useEffect(() => {
    const prefilledValue = () => {
      if (!formState) return null;
      const { completedData } = formState;
      if (!completedData.formInput.formValue) return null;
      let formValue = completedData.formInput.formValue;
      let selectedPossibleValue = fieldData.possibleValues.find((pv) =>
        isYesNo
          ? (pv.label === "Yes") === formValue.booleanValue
          : pv.label === formValue.textValue
      );
      if (!selectedPossibleValue) return null;
      return selectedPossibleValue.id;
    };
    if (formState && formState.completedData.formInput.formValue) {
      let resolvedValue = prefilledValue();
      if (resolvedValue) setSelected(resolvedValue);
    }
  }, [formState, fieldData.possibleValues, isYesNo]);

  const updateField = (newValue: string) => {
    let possibleValueSelected = fieldData.possibleValues.find(
      (pv) => pv.id === newValue
    );

    if (!formState || !possibleValueSelected) return null;

    const { completedData, updateData, formJson, firmId, personId } = formState;
    let newFieldData = updatePrepopData(
      completedData,
      formJson,
      firmId,
      personId,
      {
        ...(!isYesNo
          ? { textValue: possibleValueSelected.label }
          : {
              booleanValue: possibleValueSelected.label === "No" ? false : true,
            }),
      }
    );
    updateData(newFieldData);
  };

  return (
    <FormControl sx={{ marginLeft: "0.6rem" }}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        name={`${label} group`}
        value={selected}
        onChange={
          formState
            ? (e) => updateField(e.target.value)
            : (e) => setSelected(e.target.value)
        }
      >
        {possibleValues.map((value) => (
          <FormControlLabel
            key={value.id}
            value={value.id}
            control={<Radio />}
            label={value.label}
          />
        ))}
      </RadioGroup>
      {description && <FormHelperText>{description}</FormHelperText>}
    </FormControl>
  );
};

const FormDropDown: FC<{
  fieldData: DropDownField;
  formState?: FormStateHandler;
}> = ({ fieldData, formState }) => {
  const [selected, setSelected] = useState<string>("");

  useEffect(() => {
    const prefilledValue = () => {
      if (!formState) return null;
      const { completedData } = formState;
      if (!completedData.formInput.formValue) return null;
      let formValue = completedData.formInput.formValue;
      let selectedPossibleValue = fieldData.possibleValues.find(
        (pv) => pv.label === formValue.textValue
      );
      if (!selectedPossibleValue) return null;
      return selectedPossibleValue.id;
    };
    if (formState && formState.completedData.formInput.formValue) {
      let resolvedValue = prefilledValue();
      if (resolvedValue) setSelected(resolvedValue);
    }
  }, [formState, fieldData.possibleValues]);

  const updateField = (newValue: string) => {
    let possibleValueSelected = fieldData.possibleValues.find(
      (pv) => pv.id === newValue
    );

    if (!formState || !possibleValueSelected) return null;

    const { completedData, updateData, formJson, firmId, personId } = formState;
    let newFieldData = updatePrepopData(
      completedData,
      formJson,
      firmId,
      personId,
      { textValue: possibleValueSelected.label }
    );
    updateData(newFieldData);
  };
  return (
    <TextField
      label={fieldData.label}
      fullWidth
      select
      helperText={fieldData.description}
      value={selected}
      onChange={
        formState
          ? (e) => updateField(e.target.value)
          : (e) => setSelected(e.target.value)
      }
    >
      {fieldData.possibleValues.map((possibleValue) => (
        <MenuItem key={possibleValue.id} value={possibleValue.id}>
          {possibleValue.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

const YesNo: FC<{ fieldData: YesNoField; formState?: FormStateHandler }> = ({
  fieldData,
  formState,
}) => {
  let newFieldData: OptionSelectField = {
    ...fieldData,
    inputType: "OptionSelect",
    possibleValues: [],
  };
  newFieldData.possibleValues = [
    {
      id: "1",
      label: "Yes",
      value: "1",
      description: "",
      displayIndex: 0,
      formFieldId: fieldData.id,
      name: "yes",
    },
    {
      id: "2",
      label: "No",
      value: "2",
      description: "",
      displayIndex: 1,
      formFieldId: fieldData.id,
      name: "no",
    },
  ];

  return (
    <FormRadio fieldData={newFieldData} isYesNo={true} formState={formState} />
  );
};

const FormLongText: FC<{
  fieldData: LongTextField;
  formState?: FormStateHandler;
}> = ({ fieldData, formState }) => {
  const { label, description, placeholder } = fieldData;
  const updateField = (newValue: string) => {
    if (!formState) return null;

    const { completedData, updateData, formJson, firmId, personId } = formState;
    let newFieldData = updatePrepopData(
      completedData,
      formJson,
      firmId,
      personId,
      { textValue: newValue }
    );
    updateData(newFieldData);
  };

  return (
    <TextField
      label={label}
      fullWidth
      helperText={description}
      placeholder={placeholder}
      onChange={(e) => updateField(e.target.value)}
      multiline
      value={formState?.completedData.formInput.formValue?.textValue || null}
    />
  );
};

const FormPhone: FC<{
  fieldData: PhoneField;
  formState?: FormStateHandler;
}> = ({ fieldData, formState }) => {
  const { label, description } = fieldData;
  const [state, setState] = useState<string | null>(null);

  useEffect(() => {
    if (
      formState &&
      formState.completedData.formInput.formValue &&
      formState.completedData.formInput.formValue.textValue
    ) {
      setState(formState.completedData.formInput.formValue.textValue);
    }
  }, [formState]);

  const updateField = (newValue: string) => {
    if (!formState) {
      setState(newValue);
      return;
    }

    const { completedData, updateData, formJson, firmId, personId } = formState;
    let newFieldData = updatePrepopData(
      completedData,
      formJson,
      firmId,
      personId,
      { textValue: newValue }
    );
    updateData(newFieldData);
  };

  return (
    <ReactInputMask
      mask="999 - 999 - 9999"
      value={state || ""}
      onChange={(e) => updateField(e.target.value)}
      maskChar={null}
      disabled={false}
      placeholder="### - ### - ####"
    >
      <TextField fullWidth label={label} helperText={description} />
    </ReactInputMask>
  );
};

const FormEmail: FC<{
  fieldData: EmailField;
  formState?: FormStateHandler;
}> = ({ fieldData, formState }) => {
  const { label, description } = fieldData;

  const [state, setState] = useState<string | null>(null);

  useEffect(() => {
    if (
      formState &&
      formState.completedData.formInput.formValue &&
      formState.completedData.formInput.formValue.textValue
    ) {
      setState(formState.completedData.formInput.formValue.textValue);
    }
  }, [formState]);

  const updateField = (newValue: string) => {
    if (!formState) {
      setState(newValue);
      return;
    }

    const { completedData, updateData, formJson, firmId, personId } = formState;
    let newFieldData = updatePrepopData(
      completedData,
      formJson,
      firmId,
      personId,
      { textValue: newValue }
    );
    updateData(newFieldData);
  };
  return (
    <TextField
      label={label}
      fullWidth
      onChange={(e) => updateField(e.target.value)}
      value={state}
      helperText={description}
      placeholder="example@email.com"
    />
  );
};

const FormNumber: FC<{
  fieldData: NumberField;
  formState?: FormStateHandler;
}> = ({ fieldData, formState }) => {
  const { label, description } = fieldData;
  const [value, setValue] = useState("");

  useEffect(() => {
    if (formState) {
      if (formState.completedData.formInput.formValue) {
        let integerValue: number | null =
          formState.completedData.formInput.formValue.integerValue;
        integerValue && setValue(integerValue.toString());
      }
    }
  }, [formState]);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (formState) {
      const { completedData, updateData, formJson, firmId, personId } =
        formState;
      const newData = updatePrepopData(
        completedData,
        formJson,
        firmId,
        personId,
        {
          integerValue: Number(onlyNums),
        }
      );
      updateData(newData);
    } else {
      setValue(onlyNums);
    }
  }

  return (
    <TextField
      fullWidth
      label={label}
      value={value}
      onChange={handleChange}
      helperText={
        <>
          {description && (
            <>
              {description}
              <br />
            </>
          )}
          <span style={{ fontWeight: "bold" }}>
            Only numbers in this field.
          </span>
        </>
      }
    />
  );
};

const FormDate: FC<{ fieldData: DateField; formState?: FormStateHandler }> = ({
  fieldData,
  formState,
}) => {
  const { label, description } = fieldData;
  const [value, setValue] = useState<string | null>("");

  const noErrorOnEmpty = value === "" && {
    error: false,
  };

  useEffect(() => {
    if (formState) {
      let prepopDateString =
        formState.completedData.formInput.formValue?.beginDateValue;
      if (!prepopDateString) return;
      let date = new Date(prepopDateString).toString();
      setValue(date);
    }
  }, [formState]);

  function handleChange(date: string) {
    if (formState) {
      const { completedData, formJson, firmId, personId, updateData } =
        formState;
      const dateObj = new Date(date);
      let dateString = dateObj.toISOString();
      let newFieldData = updatePrepopData(
        completedData,
        formJson,
        firmId,
        personId,
        {
          beginDateValue: dateString,
        }
      );
      updateData(newFieldData);
    }
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={label}
        inputFormat="MM/dd/yyyy"
        value={value}
        onChange={(e) => {
          e && handleChange(e);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            {...noErrorOnEmpty}
            helperText={
              params.error ? (
                <>
                  <>Please enter a valid date.</>
                  <br />
                  <span style={{ color: "grey" }}>{description}</span>
                </>
              ) : (
                description
              )
            }
            placeholder="mm/dd/yyyy"
          />
        )}
      />
    </LocalizationProvider>
  );
};

const FormDateTime: FC<{
  fieldData: DateTimeField;
  formState?: FormStateHandler;
}> = ({ fieldData, formState }) => {
  const { label, description } = fieldData;
  const [value, setValue] = useState<string | null>("");

  const noErrorOnEmpty = value === "" && {
    error: false,
  };

  useEffect(() => {
    if (formState) {
      let prepopDateString =
        formState.completedData.formInput.formValue?.beginDateValue;
      if (!prepopDateString) return;
      let date = new Date(prepopDateString).toString();
      setValue(date);
    }
  }, [formState]);

  function handleChange(date: string) {
    if (formState) {
      const { completedData, formJson, firmId, personId, updateData } =
        formState;
      const dateObj = new Date(date);
      let dateString = dateObj.toISOString();
      let newFieldData = updatePrepopData(
        completedData,
        formJson,
        firmId,
        personId,
        {
          beginDateValue: dateString,
        }
      );
      updateData(newFieldData);
    } else {
      setValue(date);
    }
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        label={label}
        value={value}
        onChange={(e) => e && handleChange(e)}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            helperText={
              params.error ? (
                <>
                  <>Please enter a valid date and time.</>
                  <br />
                  <span style={{ color: "grey" }}>{description}</span>
                </>
              ) : (
                description
              )
            }
            {...noErrorOnEmpty}
          />
        )}
      />
    </LocalizationProvider>
  );
};

const FormPhoto: FC<{ fieldData: PhotoCollectionField }> = ({ fieldData }) => {
  return <Button>Capture Photo</Button>;
};

const FormTime: FC<{ fieldData: TimeField }> = ({ fieldData }) => {
  const { label, description } = fieldData;
  const [value, setValue] = useState<string | null>("");

  const noErrorOnEmpty = value === "" && {
    error: false,
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        label={label}
        value={value}
        onChange={(e) => setValue(e)}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            helperText={
              params.error ? (
                <>
                  <>Please enter a valid time.</>
                  <br />
                  <span style={{ color: "grey" }}>{description}</span>
                </>
              ) : (
                description
              )
            }
            {...noErrorOnEmpty}
          />
        )}
      />
    </LocalizationProvider>
  );
};

function updatePrepopData(
  formField: IFormField,
  form: IFormJson,
  firmId: string,
  personId: string,
  updateKeyValues: Partial<IFormValue>
) {
  let formFieldClone: IFormField = JSON.parse(JSON.stringify(formField));
  if (!formField.formInput.formValue) {
    formFieldClone.formInput.formValue = generateFormValue(
      form,
      formField,
      firmId,
      personId
    );
  }
  Object.keys(updateKeyValues).map(
    (key) =>
      ((formFieldClone.formInput.formValue as any)[key] = (
        updateKeyValues as any
      )[key])
  );

  return formFieldClone;
}

function generateFormValue(
  form: IFormJson,
  field: IFormField,
  firmId: string,
  personId: string
): IFormValue {
  return {
    beginDateValue: null,
    booleanValue: null,
    createdAt: null,
    createdBy: personId,
    doubleValue: null,
    endDateValue: null,
    entityId: "646ac12c-0f3b-11ed-9668-2eed6cdb025f",
    firmId: firmId,
    formConfigurationId: form.formConfigurationId,
    formFieldId: field.formFieldId,
    formFieldInstanceId: field.formInput.formFieldInstanceId,
    formId: form.formId,
    formInstanceId: field.formInput.formFieldInstanceId,
    id: uuidv4(),
    inspectionAssignmentId: form.inspectionAssignmentId,
    inspectionId: form.inspectionId,
    inspectionTemplateId: form.inspectionTypeTemplateId,
    integerValue: null,
    isDeleted: false,
    isDirty: false,
    jsonValue: null,
    name: "",
    parentId: null,
    photoIds: null,
    referenceType: "HomeInspectionAnswer",
    textValue: null,
    textValues: null,
    valueType: null,
  };
}
