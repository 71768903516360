import { Cancel, OpenInNew } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const documents = [
  { name: "Report", link: "https://www.hud.gov/sites/documents/LBPH09.PDF" },
  {
    name: "Checklist",
    link: "https://www.totalhomeinspection.com/totalhomeinspectionchecklist.pdf",
  },
];

type DocState =
  | {
      state: "closed";
    }
  | {
      state: "open";
      doc: {
        name: string;
        link: string;
      };
    };

export default function Documentation() {
  const [doc, setDoc] = useState<DocState>({ state: "closed" });
  return (
    <>
      <Stack
        flex={1}
        direction="row-reverse"
        alignItems={doc.state === "open" ? "stretch" : "start"}
      >
        {doc.state === "closed" && (
          <Card sx={{ width: 400 }}>
            <CardHeader title="Documents" />
            <CardContent>
              <List>
                {documents.map((doc) => (
                  <ListItem
                    key={doc.link}
                    secondaryAction={
                      <IconButton href={doc.link} target="_blank">
                        <OpenInNew />
                      </IconButton>
                    }
                  >
                    <ListItemButton
                      onClick={() =>
                        setDoc({
                          state: "open",
                          doc: { name: doc.name, link: doc.link },
                        })
                      }
                    >
                      <ListItemText primary={doc.name} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        )}
        {doc.state === "open" && (
          <Paper elevation={5} sx={{ flex: 1, backgroundColor: "#ECECEC" }}>
            <Stack flex={1} direction="column" height="100%">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                paddingLeft="1rem"
                sx={{
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
              >
                <Typography>{doc.doc.name}</Typography>
                <Stack direction="row" justifyContent="center">
                  <IconButton href={doc.doc.link} target="_blank">
                    <OpenInNew />
                  </IconButton>
                  <IconButton onClick={() => setDoc({ state: "closed" })}>
                    <Cancel />
                  </IconButton>
                </Stack>
              </Stack>
              <iframe
                src={doc.doc.link}
                title={doc.doc.name}
                height="100%"
                width="100%"
                aria-label={doc.doc.name}
              />
            </Stack>
          </Paper>
        )}
      </Stack>
    </>
  );
}
