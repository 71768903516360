import { MenuItem, Stack, Tab, Tabs, TextField } from "@mui/material";
import React, { FC, ReactNode, useContext, useState } from "react";
import { Checkbox, FormControlLabel, Box } from "@mui/material";
import PossibleValues from "./PossibleValues";
import LogicEditor from "./LogicEditor";
import { FormCtx } from "./FormWrapper/BuilderWrapper";
import { Error } from "@mui/icons-material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateField, DateTimeField } from "./TypeDefinitions";
import { exhaustiveGuard } from "src/utils/exhaustiveGuard";

export default function Editor() {
  const [tab, setTab] = useState(0);
  const { state, dispatch } = useContext(FormCtx);
  const form = state.data.inspectionTypeTemplate.configuration.forms.find(
    (form) => form.id === state.formId
  );

  if (!form) return <h1>err1</h1>;

  const fieldParams = form.fields.find(
    (field) => field.id === state.editingFieldId
  );
  if (!fieldParams) return <h1>err2</h1>;
  const { label, isRequired, description } = fieldParams;

  const handleTabChange = (e: any, newTabValue: any) => setTab(newTabValue);

  const validationErrors = state.errors.some(
    (error) => error.fieldId === fieldParams.id && error.validationId
  );

  const fieldParameterErrors = state.errors.some(
    (err) => err.fieldId === fieldParams.id && !err.validationId
  );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        marginTop: "0.5rem",
      }}
    >
      <Box sx={{ marginBottom: "1.5rem" }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="toggle logic editor"
          variant="fullWidth"
        >
          <Tab
            label="Edit Fields"
            value={0}
            icon={fieldParameterErrors ? <Error htmlColor="red" /> : undefined}
            iconPosition="end"
          />
          <Tab
            label="Logic"
            value={1}
            icon={validationErrors ? <Error htmlColor="red" /> : undefined}
            iconPosition="end"
          />
        </Tabs>
      </Box>
      {tab === 1 ? (
        <LogicEditor />
      ) : (
        <>
          <MarginWrap>
            <TextField
              label="Field Label"
              fullWidth
              value={label ?? ""}
              placeholder="This field represents . . ."
              onChange={(e) =>
                dispatch({
                  type: "updateField",
                  payload: {
                    fieldId: fieldParams.id,
                    newValues: {
                      inputType: fieldParams.inputType,
                      label: e.target.value,
                    },
                  },
                })
              }
              error={!label}
              helperText={
                <>
                  <span style={{ color: "red" }}>*</span>A field label is
                  required.
                </>
              }
            />
          </MarginWrap>
          <MarginWrap>
            <FormControlLabel
              control={
                <Checkbox
                  name="requiredFieldCheck"
                  checked={isRequired ?? false}
                  onChange={(e) =>
                    dispatch({
                      type: "updateField",
                      payload: {
                        fieldId: fieldParams.id,
                        newValues: {
                          inputType: fieldParams.inputType,
                          isRequired: !isRequired,
                        },
                      },
                    })
                  }
                />
              }
              label="This is a required field."
            />
          </MarginWrap>
          <MarginWrap>
            <TextField
              label="Description"
              fullWidth
              value={description ?? ""}
              multiline
              onChange={(e) =>
                dispatch({
                  type: "updateField",
                  payload: {
                    fieldId: fieldParams.id,
                    newValues: {
                      inputType: fieldParams.inputType,
                      description: e.target.value,
                    },
                  },
                })
              }
              helperText={<>Provide additional information about this field.</>}
            />
          </MarginWrap>
          {(fieldParams.inputType === "Date" ||
            fieldParams.inputType === "DateTime") && (
            <>
              <MinMaxDateSelect fieldParams={fieldParams} config="min" />
              <MinMaxDateSelect fieldParams={fieldParams} config="max" />
            </>
          )}
          {(fieldParams.inputType === "Text" ||
            fieldParams.inputType === "LongText") && (
            <MarginWrap>
              <TextField
                value={fieldParams.placeholder ?? ""}
                label="Placeholder"
                fullWidth
                onChange={(e) =>
                  dispatch({
                    type: "updateField",
                    payload: {
                      fieldId: fieldParams.id,
                      newValues: {
                        inputType: fieldParams.inputType,
                        placeholder: e.target.value,
                      },
                    },
                  })
                }
                helperText={<>Provide some example text for the user.</>}
              />
            </MarginWrap>
          )}
          <PossibleValues />
        </>
      )}
    </Box>
  );
}

const MarginWrap: FC<{ children: ReactNode }> = ({ children }) => {
  return <Box sx={{ marginBottom: "2rem" }}>{children}</Box>;
};

const MinMaxDateSelect: FC<{
  fieldParams: DateTimeField | DateField;
  config: "min" | "max";
}> = ({ fieldParams, config }) => {
  const { dispatch } = useContext(FormCtx);

  let fieldKey: "maxDate" | "minDate";
  let label: string;
  switch (config) {
    case "max": {
      label = "Maximum Date";
      fieldKey = "maxDate";
      break;
    }
    case "min": {
      label = "Minimum Date";
      fieldKey = "minDate";
      break;
    }
  }

  let dateType: ValueConstant;

  let value: string | null;
  // =
  //   fieldParams[fieldKey] === "today" ? null : fieldParams[fieldKey];

  switch (fieldParams[fieldKey]) {
    case "yesterday": {
      dateType = "yesterday";
      value = null;
      break;
    }
    case "today": {
      dateType = "today";
      value = null;
      break;
    }
    case "tomorrow": {
      dateType = "tomorrow";
      value = null;
      break;
    }
    case "": {
      dateType = "date";
      value = "";
      break;
    }
    case null: {
      dateType = "none";
      value = null;
      break;
    }
    case undefined: {
      dateType = "none";
      value = null;
      break;
    }
    default:
      dateType = "date";
      let x = fieldParams[fieldKey];
      if (x === null) {
        value = "";
      } else if (!isNaN(Date.parse(x))) {
        value = x;
      } else {
        value = "";
      }
      break;
  }

  type ValueConstant = "yesterday" | "today" | "tomorrow" | "date" | "none";

  interface Option {
    value: ValueConstant;
    label: string;
  }
  const options: Option[] = [
    { value: "yesterday", label: "Date Before Inspection" },
    { value: "today", label: "Date of Inspection" },
    { value: "tomorrow", label: "Date After Inspection" },
    {
      value: "date",
      label: "Specify Date",
    },
    {
      value: "none",
      label: "None",
    },
  ];

  function fieldUpdate(newValue: string | null) {
    dispatch({
      type: "updateField",
      payload: {
        fieldId: fieldParams.id,
        newValues: {
          inputType: fieldParams.inputType,
          [fieldKey]: newValue,
        },
      },
    });
  }

  function handleChange(newValue: ValueConstant) {
    switch (newValue) {
      case "date": {
        fieldUpdate("");
        break;
      }
      case "yesterday": {
        fieldUpdate("yesterday");
        break;
      }
      case "today": {
        fieldUpdate("today");
        break;
      }
      case "tomorrow": {
        fieldUpdate("tomorrow");
        break;
      }
      case "none": {
        fieldUpdate(null);
        break;
      }
      default:
        exhaustiveGuard(newValue);
    }
  }
  return (
    <>
      <MarginWrap>
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="space-between"
          spacing={2}
        >
          <TextField
            select
            label={label}
            sx={{ width: "50%" }}
            value={dateType}
            onChange={(e) => handleChange(e.target.value as ValueConstant)}
          >
            {options.map((option) => (
              <MenuItem value={option.value} key={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              inputFormat="MM/dd/yyyy"
              value={value}
              disabled={dateType !== "date"}
              onChange={(e) => {
                const dateObj = e === null ? null : new Date(e);
                let dateStringOrNull = dateObj?.toISOString() || null;
                dispatch({
                  type: "updateField",
                  payload: {
                    fieldId: fieldParams.id,
                    newValues: {
                      inputType: fieldParams.inputType,
                      ...(config === "min" && { minDate: dateStringOrNull }),
                      ...(config === "max" && { maxDate: dateStringOrNull }),
                    },
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "50%" }}
                  error={value === ""}
                  helperText={
                    (params.error || value === "") &&
                    !params.disabled && (
                      <>
                        <>Please enter a valid date.</>
                      </>
                    )
                  }
                  placeholder="mm/dd/yyyy"
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
      </MarginWrap>
    </>
  );
};
