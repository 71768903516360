import * as React from 'react';
import Map from 'react-map-gl';
import { MapProvider } from 'react-map-gl';

export default function map() {
    return (
        <MapProvider>
            <Map
                initialViewState={{
                    longitude: -100.4,
                    latitude: 37.34,
                    zoom: 3
                }}
                style={{ width: 600, height: 400 }}
                mapStyle="mapbox://styles/mapbox/streets-v9"
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN}
            />
        </MapProvider>
    );
}