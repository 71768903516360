import { jsonToGraphQLQuery, VariableType } from "json-to-graphql-query";
import { gql } from "@apollo/client";

function checkFields(inputObject, inputObjects) {
  return inputObject.inputFields.map((inputField, index) => {
    if (inputField.type.kind === "INPUT_OBJECT") {
      return {
        ...inputField,
        fields: checkFields(inputObjects[inputField.type.name], inputObjects),
      };
    } else if (
      inputField.ofType &&
      inputField.type.kind === "NON_NULL" &&
      inputField.ofType.kind === "INPUT_OBJECT"
    ) {
      return {
        ...inputField,
        fields: checkFields(
          inputObjects[inputField.type.ofType.name],
          inputObjects
        ),
      };
    } else if (
      inputField.type.kind === "SCALAR" ||
      inputField.type.kind === "NON_NULL"
    )
      return inputField;
    else {
      console.error("Unhandled Input Field Type: ", inputField.type.kind);
      return null;
    }
  });
}
export function getQueryInputs(query, schema) {
  let inputObjects = schema.inputObjects;
  let queryInput;
  let queryInputs = query.args.map((arg) => {
    queryInput = {
      name: arg.name,
      description: arg.description,
      type: arg.type,
    };
    if (arg.type.kind === "INPUT_OBJECT") {
      queryInput.fields = checkFields(
        inputObjects[arg.type.name],
        inputObjects
      );
    } else if (arg.type.ofType && arg.type.ofType.kind === "INPUT_OBJECT") {
      queryInput.fields = checkFields(
        inputObjects[arg.type.ofType.name],
        inputObjects
      );
    }
    return queryInput;
  });
  return queryInputs;
}

function processReturnFields(
  fields,
  schema,
  parentObjectArray,
  capturedFields = {}
) {
  let returnObject = {};
  let returnName;
  let returnType;
  let object;
  fields.forEach((field) => {
    returnName = field.type.name;
    returnType = field.type.kind;
    switch (returnType) {
      case "OBJECT":
        if (parentObjectArray.includes(field.name)) return;
        parentObjectArray = [...parentObjectArray, field.name];
        object = schema.objects[returnName];
        capturedFields = { ...capturedFields, [field.name]: true };
        returnObject = {
          ...returnObject,
          [field.name]: processReturnFields(
            object.fields,
            schema,
            parentObjectArray,
            capturedFields
          ),
        };
        break;
      case "SCALAR":
        returnObject = { ...returnObject, [field.name]: true };
        break;
      case "NON_NULL":
        returnObject = { ...returnObject, [field.name]: true };
        break;
      case "ENUM":
        returnObject = { ...returnObject, [field.name]: true };
        break;
      case "LIST":
        if (parentObjectArray.includes(field.name)) return;
        parentObjectArray = [...parentObjectArray, field.name];
        if (
          field.type.ofType &&
          field.type.ofType.kind === "OBJECT" &&
          !capturedFields[field.name]
        ) {
          capturedFields = { ...capturedFields, [field.name]: true };
          returnObject = {
            ...returnObject,
            [field.name]: processReturnFields(
              schema.objects[field.type.ofType.name].fields,
              schema,
              parentObjectArray,
              capturedFields
            ),
          };
        } else if (capturedFields[field.name])
          returnObject = { ...returnObject, [field.name]: false };
        else returnObject = { ...returnObject, [field.name]: true };
        break;
      default:
        console.log("Error Unaccounted Return Type", returnType);
    }
  });
  return returnObject;
}

function queryType(query) {
  if (query.kind === "NON_NULL" || query.kind === "LIST") {
    return queryType(query.ofType);
  } else {
    return query;
  }
}

export function getQueryReturn(query, schema) {
  const type = queryType(query.type);
  const parentObject = [query.name];
  let object;
  let returnObject = {};
  switch (type.kind) {
    case "OBJECT":
      object = schema.objects[type.name];
      returnObject = {
        ...returnObject,
        ...processReturnFields(object.fields, schema, parentObject, 0),
      };
      break;
    case "SCALAR":
      returnObject = { ...returnObject, [query.name]: true };
      break;
    case "NON_NULL":
      returnObject = { ...returnObject, [query.ofType.name]: true };
      break;
    case "LIST":
      returnObject = { ...returnObject, [query.ofType.name]: true };
      break;
    default:
      console.log("Error Unaccounted Return Type", type.kind);
  }
  return returnObject;
}

function generateVariable(input) {
  let kind = input.type.kind;
  let nullable = !(kind === "NON_NULL");
  let variable;
  if (nullable && kind === "SCALAR")
    variable = { [input.name]: `${input.type.name}` };
  else if (
    input.type.ofType &&
    !nullable &&
    input.type.ofType.kind === "SCALAR"
  )
    variable = { [input.name]: `${input.type.ofType.name}!` };
  else if (nullable && kind === "ENUM")
    variable = { [input.name]: `${input.type.name}` };
  else if (nullable && kind === "LIST") {
    variable = { [input.name]: `[${input.type.ofType.name}]` };
  }
  else console.error("Unhandled Query Variable Generation: ", kind);
  return variable;
}
function generateVariables(inputs) {
  let variables = {};
  inputs.forEach((input) => {
    if (input.fields) {
      variables = { ...variables, ...generateVariables(input.fields) };
    } else {
      variables = { ...variables, ...generateVariable(input) };
    }
  });
  return variables;
}
// {
//   user: {
//     email: new VariableType("email"),
//     password: new VariableType("password"),
//   },
// }
function generateArg(name) {
  return { [name]: new VariableType(`${name}`) };
}
function generateArgs(inputs) {
  let args = {};
  inputs.forEach((input) => {
    if (input.fields) {
      args = { ...args, [input.name]: { ...generateArgs(input.fields) } };
    } else args = { ...args, ...generateArg(input.name) };
  });
  return { ...args };
}
export function generateQuery(queryInputs, queryReturn, queryString) {
  const variables =
    queryInputs.length > 0 ? generateVariables(queryInputs) : false;
  const args = queryInputs.length > 0 ? generateArgs(queryInputs) : false;
  const queryObject = {
    query: {
      __variables: variables,
      [queryString]: {
        __args: args,
        ...queryReturn,
      },
    },
  };
  const query = gql`
    ${jsonToGraphQLQuery(queryObject, { pretty: true })}
  `;
  return query;
}
