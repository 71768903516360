// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------

export default function Reporting() {
    return (
        <Page title="Reporting | FINBACK670">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">Reporting</Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
