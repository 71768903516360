import SchemaContext from "./SchemaContext";
import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { getMutationInputs, getMutationReturn, generateMutation } from './MutationHelpers';

export const GQLMutationAction = ({ mutationString, onCompleted }) => {

  const schema = useContext(SchemaContext);
  const mutation = schema.mutations[mutationString];
  if (!mutation) {
    throw Error(`Undefined Mutation: ${mutationString}`)
  }
  const mutationInputs = getMutationInputs(mutation, schema);
  const mutationReturn = getMutationReturn(mutation, schema);
  const mutationDocument = generateMutation(mutationInputs, mutationReturn, mutationString)
  const [mutate, { error }] = useMutation(mutationDocument, { onCompleted });
  if (error)
  console.error("Error in Mutation Action for", mutationString, "\nError is: ", error)
  return (record) => {
    mutate({ variables: record })
      .then((res) => {
        console.log(res)
      })
      .catch((e) => {
        console.log(e)
      });
  }
};
