import GQLTable from '../../../utils/gql-mui-rhf/Table';

// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
// ----------------------------------------------------------------------

export default function FirmPeople({firmId}) {
    return (
        <Page title="Firms | FINBACK670">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">Firm People</Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <GQLTable
                            queryString={'firmPersons'}
                            queryVariables={{ firmId, "active": true }}
                            addMutationVariables={{ firmId }}
                            addMutationString={'createFirmPerson'}
                            deleteMutationString={'deleteFirmPerson'}
                            updateMutationString={'updateFirmPerson'}
                            updateModal={true}
                            restrictedColumns={{}}
                            // onRowClick={(rowData) => {
                            //     navigate(`/app/admin/role/${rowData.id}`)
                            // }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
