import { TextField } from "@mui/material";
import React, { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      // isNumericString
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const MUIRHFTextField = ({
  name,
  description,
  label,
  defaultValue,
  rules,
  isPassword,
  ...rest
}) => {
  const { control } = useFormContext();
  const [numericFormat, setNumericformat] = useState(0);

  return (
    <>
      <Controller
        name={name}
        rules={rules}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error, invalid } }) => {
          return (
            <TextField
              {...field}
              margin="dense"
              value={numericFormat}
              onChange={(event) => {
                field.onChange(parseInt(event.target.value))
                setNumericformat(event.target.value);
              }}
              label={label}
              error={invalid}
              type={"number"}
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              helperText={error?.message || description || ""}
              {...rest}
            />
          );
        }}
      />
    </>
  );
};

export default MUIRHFTextField;
