import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';

// material
import { CircularProgress, Grid } from "@mui/material";
// components
import { useForm, FormProvider } from 'react-hook-form';
import createQuery from "../../../utils/gql-mui-rhf/QueryAction";
import { Select } from "../../../utils/mui-rhf";
import { GQLMutationAction } from '../../../utils/gql-mui-rhf/SingleMutation';

function generateMutation(snackbar, handleClose) {
    return GQLMutationAction({
        mutationString: "createRolesRequest",
        onCompleted: (response) => {
            snackbar("success", "Roles Request Created")
            handleClose()
        }
    })
}
// ----------------------------------------------------------------------


export default function AddRole({ open, selectionRecord, handleClose, project }) {
    const { enqueueSnackbar } = useSnackbar();
    const snackbar = (message, variant) => {
        enqueueSnackbar(message, { variant });
    };
    const methods = useForm();
    const createRolesRequest = generateMutation(snackbar, handleClose);
    const handleSubmit = methods.handleSubmit;
    const { loading, error, data } = createQuery("roleTypes", {
        active: true,
        entity: "PERSON",
    });

    const onSubmit = data => {
        selectionRecord.map((model) => createRolesRequest({
            roleTypeId: data.roleTypeId,
            entityId: model.personId,
            firstName: model.firstName,
            lastName: model.lastName,
            email: model.email,
            entity: "PERSON",
            projectId: project.id,
            active: true
        }))
    }

    if (loading)
        return <CircularProgress />
    if (error) {
        return <div>{`${error}`}</div>;
    }
    if (data) {
        const options = data.roleTypes
            .map((roleType) => ({
                value: roleType.id,
                name: roleType.name,
                label: roleType.name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >  <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <DialogTitle id="alert-dialog-title">
                            {`Select a Role`}
                        </DialogTitle>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Select style={{ marginBottom: '16px' }} rules={{ required: "select option" }} fullWidth label={"Role"} name={"roleTypeId"} options={options} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button type={"submit"} autoFocus>
                                Submit
                            </Button>
                        </DialogActions>
                    </form>
                </FormProvider>
            </Dialog>
        );
    }
}