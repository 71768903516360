import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useContext } from "react";
import { FormCtx } from "./FormWrapper/BuilderWrapper";
import { Expression, Validation } from "./TypeDefinitions";

const Expressions: FC<{ validation: Validation }> = ({ validation }) => {
  return (
    <>
      {validation.expressions.length > 0 ? (
        <>
          <Typography
            color="GrayText"
            sx={{ marginBottom: "-0.5rem", marginTop: "0.5rem" }}
          >
            Conditions
          </Typography>
          {validation.expressions.map((expression, i) => {
            return (
              <ExpressionComponent
                expression={expression}
                key={expression.id}
              />
            );
          })}
        </>
      ) : (
        <Typography textAlign="center" color="red">
          Please add a condition.
        </Typography>
      )}
    </>
  );
};

export default Expressions;

const ExpressionComponent: FC<{ expression: Expression }> = ({
  expression,
}) => {
  // const { fields, getExpression, updateExpression, deleteExpression } =
  //   useContext(BuilderContext);

  const { state, dispatch } = useContext(FormCtx);

  const form = state.data.inspectionTypeTemplate.configuration.forms.find(
    (form) => form.id === state.formId
  );

  const fields = form!.fields.filter((field) =>
    ["Number", "YesNo", "OptionSelect"].includes(field.inputType)
  );

  let field = fields.find((field) => field.id === expression.fieldToCompare);

  const isFirst = expression.order === 0;

  return (
    // <Card sx={{ marginBottom: "0.5rem", boxShadow: 3 }}>
    //   <CardContent>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        borderTop: "dashed 1px #cccccc",
        paddingTop: "1rem",
        marginTop: isFirst ? "1rem" : 0,
      }}
    >
      {!isFirst && (
        <TextField
          select
          sx={{ marginBottom: "0.5rem", minWidth: "4rem" }}
          value={expression.linkedBy}
          onChange={(e) => {
            if (e.target.value !== "AND" && e.target.value !== "OR") return;
            dispatch({
              type: "updateExpression",
              payload: {
                expressionId: expression.id,
                validationId: expression.formValidationId,
                paramToUpdate: {
                  linkedBy: e.target.value,
                },
              },
            });
          }}
        >
          <MenuItem value="AND">AND</MenuItem>
          <MenuItem value="OR">OR</MenuItem>
        </TextField>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <TextField
          select
          fullWidth
          label="Field"
          size="small"
          value={field?.id}
          error={!field?.id}
          onChange={
            (event) =>
              dispatch({
                type: "updateFieldToCompare",
                payload: {
                  validationId: expression.formValidationId,
                  expressionId: expression.id,
                  fieldToCompareId: event.target.value,
                },
              })
            // handleUpdate({
            //   fieldToCompare: event.target.value,
            //   linkedBy: expression.linkedBy,
            //   order: index,
            // })
          }
        >
          {fields &&
            fields.length > 0 &&
            fields.map((field, i) => (
              <MenuItem key={field.id} value={field.id}>
                {field.label}
              </MenuItem>
            ))}
        </TextField>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "3rem",
          }}
        >
          <Typography>is</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          marginTop: "0.5rem",
        }}
      >
        <ExpressionEditingOptions expression={expression} />
      </Box>
      <Button
        sx={{ marginY: "0.5rem" }}
        onClick={(e) =>
          dispatch({
            type: "deleteExpression",
            payload: {
              validationId: expression.formValidationId,
              expressionId: expression.id,
            },
          })
        }
      >
        Delete Condition
      </Button>
    </Box>
  );
};

const ExpressionEditingOptions: FC<{ expression: Expression }> = ({
  expression,
}) => {
  const { dispatch, state } = useContext(FormCtx);
  const formIndex =
    state.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === state.formId
    );
  const field = state.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.find((field) => field.id === expression.fieldToCompare);
  if (!field) return <></>;
  return (
    <>
      {field.inputType === "YesNo" && (
        <TextField
          select
          fullWidth
          label=""
          size="small"
          error={expression.value === null}
          value={
            expression.value === null
              ? null
              : expression.value === true
              ? "Yes"
              : "No"
          }
          onChange={(e) =>
            dispatch({
              type: "updateExpression",
              payload: {
                validationId: expression.formValidationId,
                expressionId: expression.id,
                paramToUpdate: {
                  operator: "==",
                  value: e.target.value === "Yes" ? true : false,
                },
              },
            })
          }
        >
          <MenuItem key={1} value={"Yes"}>
            Yes
          </MenuItem>
          <MenuItem key={2} value={"No"}>
            No
          </MenuItem>
        </TextField>
      )}
      {field.inputType === "Number" && (
        <Stack direction="row" spacing={1} width={"100%"}>
          <TextField
            select
            fullWidth
            label=""
            size="small"
            value={expression.operator}
            onChange={(e) => {
              let value = e.target.value;
              if (value === "<" || value === ">" || value === "==") {
                dispatch({
                  type: "updateExpression",
                  payload: {
                    validationId: expression.formValidationId,
                    expressionId: expression.id,
                    paramToUpdate: {
                      operator: value,
                    },
                  },
                });
              }
            }}
          >
            <MenuItem key={1} value={">"}>
              Greater Than
            </MenuItem>
            <MenuItem key={2} value={"<"}>
              Less Than
            </MenuItem>
            <MenuItem key={3} value={"=="}>
              Equal To
            </MenuItem>
          </TextField>
          <TextField
            size="small"
            helperText="Only numbers in this field."
            value={expression.value}
            onChange={(e) => {
              const onlyNums = Number(e.target.value.replace(/[^0-9]/g, ""));
              dispatch({
                type: "updateExpression",
                payload: {
                  validationId: expression.formValidationId,
                  expressionId: expression.id,
                  paramToUpdate: {
                    value: onlyNums,
                  },
                },
              });
            }}
          />
        </Stack>
      )}
      {field.inputType === "OptionSelect" && (
        <TextField
          select
          fullWidth
          size="small"
          value={expression.value}
          onChange={(e) =>
            dispatch({
              type: "updateExpression",
              payload: {
                validationId: expression.formValidationId,
                expressionId: expression.id,
                paramToUpdate: {
                  operator: "==",
                  value: e.target.value,
                },
              },
            })
          }
        >
          {field.possibleValues.map((value) => (
            <MenuItem key={value.id} value={value.id}>
              {value.name}
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
};
