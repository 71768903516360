import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { OVCheckbox } from "../../../utils/mui-rhf";
import createLazyQuery from "../../../utils/gql-mui-rhf/LazyQuery"
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const CREATE_PROJECT_ROLES = gql`
    mutation createProjectRoles($projectRoles: [InputProjectRoleParams]) {
        createProjectRoles(projectRoles: $projectRoles) {
                roleId
                project {
                    id
                    name
                }
        }
    }
`

export default function AddRoleToProject({ project, open, handleClose, projectRoles, refetchProjectRoles }) {
    const [roles, setRoles] = useState([]);
    const onGetRoleTypes = (data) => {
        let options = []
        data.roleTypes
            .forEach((roleType) => {
                if (!projectRoles.some((role) => { return roleType.id === role.roleId })) {
                    options.push({
                        value: roleType.id,
                        formValue: {
                            roleId: roleType.id,
                            projectId: project.id
                        },
                        name: roleType.name,
                        label: roleType.name,
                    })
                }
            })
        options.sort((a, b) => a.label.localeCompare(b.label));
        setRoles(options)
    }
    const onError = () => { debugger }
    const [getRoleTypes] = createLazyQuery("roleTypes", onGetRoleTypes, onError);

    useEffect(() => {
        if (project.id) {
            getRoleTypes({
                variables: {
                    active: true,
                    entity: "PERSON",
                }
            })
        }
    },
        [project, projectRoles, getRoleTypes])

    const methods = useForm({ defaultValues: { projectRoles: [] } });
    const { handleSubmit, } = methods;
    const [mutate] = useMutation(CREATE_PROJECT_ROLES);
    const onSubmit = (data) => {
        const variables = { ...data };
        mutate({ variables })
            .then((res) => {
                refetchProjectRoles()
                handleClose()
            })
            .catch((e) => {
                console.log(e);
            });
    };
    return <Dialog
        open={open}
        onClose={() => {
            methods.reset()
            handleClose()
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {`Select Roles to Add to Project`}
        </DialogTitle>
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Grid item xs={12} style={{ marginBottom: 32 }}>
                        <OVCheckbox
                            rules={{ required: "select one or many" }}
                            description={"Roles"}
                            fieldName={"projectRoles"}
                            options={roles}
                            valueField={"roleId"}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" type="submit">
                        {"Submit"}
                    </Button>
                    <Button onClick={() => {
                        methods.reset()
                        handleClose()
                    }}>Cancel</Button>
                </DialogActions>
            </form>
        </FormProvider>
    </Dialog>
}
