import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Edit } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Feature } from "./mapboxResponse";

const defaultFields = {
  street1: "",
  street2: "",
  street3: "",
  city: "",
  zip: "",
  state: "",
  unit: "",
  mapboxId: "123",
};

const CREATE_ADDRESS = gql`
  mutation Mutation($address: InputAddressParams) {
    createAddress(address: $address) {
      id
      city
      state
      street1
      street2
      street3
      zip
      unit
      buildingType
      neighborhood
    }
  }
`;

const UPDATE_ADDRESS = gql`
  mutation Mutation($address: InputAddressParams) {
    updateAddress(address: $address) {
      id
      city
      state
      street1
      street2
      street3
      zip
      unit
      buildingType
      neighborhood
    }
  }
`;

const GET_ADDRESS = gql`
  query Query($address: InputAddressParams) {
    address(address: $address) {
      id
      city
      mapboxId
      state
      street1
      street2
      street3
      zip
      unit
      buildingType
      neighborhood
    }
  }
`;

/** INFO:
 *Change between Update and Create type using the *config* prop. If empty, defaults to 'Create'.
 *
 */
const AddressSelect: FC<{
  initialConfig?:
    | {
        type: "create";
      }
    | {
        type: "update";
        addressId: string;
        state:
          | {
              type: "unloaded";
            }
          | {
              type: "loaded";
              address: typeof defaultFields;
            };
      };
}> = ({ initialConfig }) => {
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<null | Feature>(null);
  const [fields, setFields] = useState(defaultFields);
  const [config, setConfig] = useState(initialConfig);
  const [createMutation] = useMutation(CREATE_ADDRESS, {
    fetchPolicy: "network-only",
    onCompleted: <
      T extends typeof defaultFields & { id: string },
      A extends { createAddress: T }
    >(
      data: A
    ) => {
      setConfig({
        type: "update",
        addressId: data.createAddress.id,
        state: { type: "loaded", address: { ...data.createAddress } },
      });
      setOpen(false);
    },
  });

  const [updateMutation] = useMutation(UPDATE_ADDRESS, {
    fetchPolicy: "network-only",
    onCompleted: <
      T extends typeof defaultFields & { id: string },
      A extends { updateAddress: T }
    >(
      data: A
    ) => {
      setConfig({
        type: "update",
        addressId: data.updateAddress.id,
        state: { type: "loaded", address: { ...data.updateAddress } },
      });
      setOpen(false);
    },
  });

  const [getAddress] = useLazyQuery(GET_ADDRESS, {
    fetchPolicy: "network-only",
    onCompleted: <
      T extends typeof defaultFields & { id: string },
      A extends { address: T }
    >(
      data: A
    ) => {
      setConfig({
        type: "update",
        addressId: data.address.id,
        state: { type: "loaded", address: { ...data.address } },
      });
      setOpen(false);
    },
  });

  useEffect(() => {
    if (!config) {
      setConfig({ type: "create" });
      return;
    }
    if (config.type === "update") {
      if (config.state.type === "loaded") {
        setFields({ ...config.state.address });
      }
      if (config.state.type === "unloaded") {
        getAddress({ variables: { address: { id: config.addressId } } });
      }
    }
  }, [config, getAddress]);

  useEffect(() => {
    async function getSearch() {
      if (!query || query.split(" ").length < 2 || query.split(" ")[1] === "") {
        setOptions([]);
        setLoading(false);
        return;
      }
      let res = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          query
        )}.json?types=address&access_token=${
          process.env.REACT_APP_MAPBOX_TOKEN
        }&proximity=ip`
      ).then((response) => response.json());
      setOptions(res.features);
      setLoading(false);
    }

    if (query) {
      setLoading(true);
      setOptions([]);
    }
    let timer = setTimeout(() => {
      getSearch();
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [query]);

  useEffect(() => {
    if (open === false && config?.type !== "update") {
      setTimeout(() => {
        setFields(defaultFields);
        setQuery("");
      }, 200);
    }
  }, [open, config]);

  function handleSelect<T extends Feature | null>(value: T) {
    setSelected(value);
    value && setFields(mapBoxToState(value));
  }
  function handleClear() {
    setSelected(null);
  }

  function handleSubmit() {
    if (!config) return;
    if (config.type === "create") {
      console.log("fields");
      console.log({ address: { ...fields } });
      createMutation({ variables: { address: { ...fields } } });
    }
    if (config.type === "update") {
      updateMutation({ variables: { address: { ...fields } } });
    }
  }

  if (!config) return <></>;

  return (
    <>
      {config.type === "create" && (
        <Button onClick={(e) => setOpen(true)} variant="contained">
          Input Address
        </Button>
      )}
      {config.type === "update" && config.state.type === "loaded" && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="start"
        >
          <Stack direction="column" alignItems="stretch">
            {(config.state.address.street1 || config.state.address.unit) && (
              <Typography>
                {config.state.address.street1}
                {config.state.address.unit && " " + config.state.address.unit}
              </Typography>
            )}
            {config.state.address.street2 && (
              <Typography>{config.state.address.street2}</Typography>
            )}
            {config.state.address.street3 && (
              <Typography>{config.state.address.street3}</Typography>
            )}
            {(config.state.address.city || config.state.address.state) && (
              <Typography>
                {config.state.address.city + ", "}
                {config.state.address.state}
              </Typography>
            )}
          </Stack>
          <IconButton onClick={(e) => setOpen(true)}>
            <Edit />
          </IconButton>
        </Stack>
      )}
      <Dialog open={open} onClose={(e) => setOpen(false)}>
        <DialogTitle>
          {(config.type === "create" && "Input Address") ||
            (config.type === "update" && "Update Address")}
        </DialogTitle>
        <DialogContent>
          <Stack
            direction="column"
            width="30rem"
            spacing={2}
            paddingTop="0.5rem"
          >
            <Autocomplete
              fullWidth
              id="address-search"
              onInputChange={(e, value) => {
                setSelected(null);
                setQuery(value);
              }}
              includeInputInList
              autoHighlight
              value={selected}
              open={Boolean(query) && !Boolean(selected)}
              filterOptions={(x) => x}
              onChange={(e, value, reason) => {
                if (reason === "clear") handleClear();
                if (reason !== "clear") handleSelect(value);
              }}
              options={options}
              noOptionsText={
                query.split(" ").length < 2 || query.split(" ")[1] === ""
                  ? "Be more specific."
                  : "No options."
              }
              loading={loading}
              isOptionEqualToValue={(option, value) =>
                option.place_name === value.place_name
              }
              getOptionLabel={(option: any) => option.place_name}
              renderInput={(params) => {
                const inputProps = params.inputProps;
                inputProps.autoComplete = "chrome-off";
                return (
                  <TextField
                    {...params}
                    inputProps={inputProps}
                    label="Search Address"
                  />
                );
              }}
            />
            <Stack direction="row" spacing={2}>
              <TextInput
                textFieldProps={{ sx: { flex: "1" } }}
                label="Address Line 1"
                fieldName="street1"
                fields={fields}
                setFields={setFields}
              />
              <TextInput
                textFieldProps={{ sx: { flex: ".33" } }}
                label="Unit / Suite"
                fieldName="unit"
                fields={fields}
                setFields={setFields}
              />
            </Stack>
            <TextInput
              label="Address Line 2"
              fieldName="street2"
              fields={fields}
              setFields={setFields}
            />
            <TextInput
              label="Address Line 3"
              fieldName="street3"
              fields={fields}
              setFields={setFields}
            />
            <TextInput
              label="City"
              fieldName="city"
              fields={fields}
              setFields={setFields}
            />

            <Stack direction="row" spacing={2}>
              <TextInput
                label="Zip"
                fieldName="zip"
                fields={fields}
                setFields={setFields}
              />
              <TextInput
                label="State"
                fieldName="state"
                fields={fields}
                setFields={setFields}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={(e) => handleSubmit()}>
            {config.type === "create" && "Create"}
            {config.type === "update" && "Update"} Address
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddressSelect;

function mapBoxToState(response: Feature): typeof defaultFields {
  let fields: typeof defaultFields = {
    mapboxId: "123",
    street1: "",
    street2: "",
    street3: "",
    city: "",
    zip: "",
    state: "",
    unit: "",
  };

  fields.street1 = response.address + " " + response.text;

  response.context.forEach((data) => {
    if (data.id.startsWith("postcode")) {
      fields.zip = data.text;
    }
    if (data.id.startsWith("place")) {
      fields.city = data.text;
    }
    if (data.id.startsWith("place")) {
      fields.city = data.text;
    }
    if (data.id.startsWith("region")) {
      fields.state = data.text;
    }
  });
  return fields;
}

const TextInput: FC<{
  label: string;
  fieldName: keyof typeof defaultFields;
  textFieldProps?: TextFieldProps;
  fields: typeof defaultFields;
  setFields: Dispatch<SetStateAction<typeof defaultFields>>;
}> = (props) => {
  const { label, fieldName, textFieldProps, fields, setFields } = props;
  return (
    <TextField
      size="small"
      fullWidth
      label={label}
      value={fields[fieldName]}
      onChange={(e) => setFields({ ...fields, [fieldName]: e.target.value })}
      {...textFieldProps}
    />
  );
};
