import GQLTable from '../../utils/gql-mui-rhf/Table';
import { useNavigate } from "react-router-dom";

// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
// ----------------------------------------------------------------------

export default function Projects({firmUnitId, projectId}) {
    const navigate = useNavigate()
    
    return (
        <Page title="Projects | FINBACK670">
            <Container maxWidth="xl">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box sx={{ pb: 5 }}>
                            <Typography variant="h4">Projects</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <GQLTable
                            queryString={'projects'}
                            queryVariables={{ "projectOwner": firmUnitId, "projectId": projectId }}
                            addMutationString={'createProject'}
                            deleteMutationString={'deleteProject'}
                            updateMutationString={'updateProject'}
                            updateModal={true}
                        onRowClick={(rowData) => {
                            navigate(`/app/admin/project/${rowData.id}`)
                        }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
