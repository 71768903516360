import { useState, useCallback } from 'react';
// material
import { Grid, Container } from '@mui/material';
// components
import Page from '../../../components/Page';
import TableActions from '../../../components/tables/tableActions';
import Confirmation from "../../../components/modals/confirmation";

import {
  DataGridPremium
} from "@mui/x-data-grid-premium";
// ----------------------------------------------------------------------
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const DELETE_PROJECT_FIRM_UNITS = gql`
    mutation deleteProjectFirmUnits($ids: [ID]!) {
        deleteProjectFirmUnits(ids: $ids) {
            firmUnitId
        }
    }
`
const GET_ROLES_REQUESTS = gql `
query rolesRequests($active: Boolean, $entity: RoleEntityType, $projectId: ID, $status: RoleRequestStatus) {
  rolesRequests(active: $active, entity: $entity, projectId: $projectId, status: $status) {
    id
    firstName
    lastName
    email
    draftRoles {
      roleType {
        name
      }
    }
    entityId
    name
  }
}
`

const GET_FIRM_UNITS = gql`
query FirmUnits($ids: [ID]) {
  firmUnitsByIds(ids: $ids) {
    id
    firm {
      id
      name
    }
    firmUnitId
    dba
    domain
    duns
    fax
    fein
    legalAbbreviation
    legalName
    name
    owner
    phone1
    phone2
    website
  }}
`;
const GET_PROJECT_FIRM_UNITS = gql`
query ProjectFirmUnits($projectId: ID) {
  projectFirmUnits(projectId: $projectId) {
    id
    firmUnitId
    project { 
      id
      name
    }
  }
}
`

export default function FirmManagement({ project }) {
  const [rows, setRows] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [firmUnits, setFirmUnits] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [rolesRequests, setRolesRequests] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [projectFirmUnits, setProjectFirmUnits] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectionRecord, setSelectionRecord] = useState([]);
  const [confirmation, setConfirmation] = useState({ open: false });
  const closeConfirmation = () => {
    setConfirmation({ open: false });
  };
  const [getFirmUnits] = useLazyQuery(GET_FIRM_UNITS, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      setFirmUnits(response.firmUnitsByIds)
    }
  });
  const [getRolesRequests] = useLazyQuery(GET_ROLES_REQUESTS, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      setRolesRequests(response.rolesRequests)
      debugger
      setRows(response.rolesRequests)
    }
  });
  const { loading, error, refetch } = useQuery(GET_PROJECT_FIRM_UNITS, {
    variables: { projectId: project.id },
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      setProjectFirmUnits(response.projectFirmUnits)
      getFirmUnits({ variables: { ids: [project.projectOwner, ...response.projectFirmUnits.map((projectFirmUnit) => projectFirmUnit.firmUnitId)] } })
      getRolesRequests({variables: { "projectId": project.id, "status": "APPROVED", "active": true, "entity": "FIRM" }})
    }
  });
  const refetchCallback = useCallback(
    () => {
      refetch()
    }, [refetch]
  )
  const [deleteProjectFirmUnits] = useMutation(DELETE_PROJECT_FIRM_UNITS, {
    onCompleted: () => {
      refetchCallback()
    }
  })
  
  if (!project) { return null }
  if (loading) {
    return null
  }
  if (error) {
    debugger
  }

  const onDeletProjectFirmUnits = (selectionModel) => {
    debugger
    let ids = selectionModel.map((model) => model.id)
    deleteProjectFirmUnits({ variables: { ids } })
  }

  return (
    <Page title="Projects | FINBACK670">
      <Confirmation
        confirmation={confirmation}
        handleClose={closeConfirmation}
      />
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <TableActions
              deleteRecords={onDeletProjectFirmUnits}
              selectionModel={selectionRecord}
              setConfirmation={setConfirmation}
            />
            <div style={{ height: 600, width: "100%" }}>
              <DataGridPremium
                rows={rows}
                columns={[
                  { editable: false, field: 'name', headerName: "Firm Name" },
                  { editable: false, field: 'firstName', headerName: "Firm Administrator First Name" },
                  { editable: false, field: 'lastName', headerName: "Firm Administrator Last Name" },
                  { editable: false, field: 'email', headerName: "Firm Administrator Email" },
                ]}
                pageSize={5}
                pageSizeOptions={[50]}
                checkboxSelection
                onRowSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRowData = rows.filter((row) =>
                    selectedIDs.has(row.id)
                  );
                  setSelectionRecord(selectedRowData);
                  setSelectionModel(ids);
                }}
                rowSelectionModel={selectionModel}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
