import { Article, Edit, UploadFile } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { FC, createContext, useContext, useState } from "react";
import { ListOfReference } from "./components/ListOfReference";
import { ReferenceEditor } from "./components/ReferenceEditor";

type EditorConfig =
  | {
      type: "edit";
      selectedId: string | null;
    }
  | {
      type: "add";
      referenceType: "document" | "freetext";
    }
  | {
      type: "unselected";
    }
  | {
      type: "promptCreate";
    };

interface ReferenceContext {
  referenceMaterialIds: string[];
  selectable?: boolean;
  config: EditorConfig;
  dialogOpen: boolean;
}

export const ReferenceCtx = createContext<{
  setContext: (ctx: ReferenceContext) => void;
  context: ReferenceContext;
}>({
  setContext: (ctx) => {},
  context: {
    referenceMaterialIds: [],
    config: {
      type: "unselected",
    },
    dialogOpen: false,
  },
});

type ReferenceConfig =
  | {
      selectable: false;
    }
  | {
      selectable: true;
      selctedId: string | null;
    };

export const Reference: FC<{
  config: ReferenceConfig;
  referenceMaterialIds: string[];
}> = ({ referenceMaterialIds, config }) => {
  const { selectable } = config;

  const defaultCtx: ReferenceContext = {
    referenceMaterialIds: referenceMaterialIds,
    selectable: selectable,
    config: selectable
      ? { type: "edit", selectedId: config.selctedId }
      : referenceMaterialIds.length > 0
      ? { type: "unselected" }
      : { type: "promptCreate" },
    dialogOpen: false,
  };
  const [refCtx, setRefCtx] = useState<ReferenceContext>(defaultCtx);

  console.log("REFERENCE IDS");
  console.log(referenceMaterialIds);
  return (
    <ReferenceCtx.Provider
      value={{
        context: { ...refCtx, referenceMaterialIds: referenceMaterialIds },
        setContext: setRefCtx,
      }}
    >
      <>
        <Button
          endIcon={<Article />}
          variant="outlined"
          onClick={(e) => setRefCtx({ ...refCtx, dialogOpen: true })}
        >
          Documentation
        </Button>
        {<ReferenceContent defaultCtx={defaultCtx} />}
      </>
    </ReferenceCtx.Provider>
  );
};

const ReferenceContent: FC<{ defaultCtx: ReferenceContext }> = ({
  defaultCtx,
}) => {
  const { setContext, context } = useContext(ReferenceCtx);
  const { referenceMaterialIds, config } = context;

  return (
    <Dialog
      open={context.dialogOpen}
      onClose={() => setContext(defaultCtx)}
      maxWidth="md"
    >
      <DialogTitle>Create New Reference Material</DialogTitle>
      <DialogContent>
        {config.type === "promptCreate" ? (
          <Stack direction="column" spacing={2}>
            <Button
              endIcon={<UploadFile />}
              onClick={(e) =>
                setContext({
                  ...context,
                  config: { type: "add", referenceType: "document" },
                })
              }
            >
              Add New Document Reference
            </Button>
            <Button
              endIcon={<Edit />}
              onClick={(e) =>
                setContext({
                  ...context,
                  config: { type: "add", referenceType: "freetext" },
                })
              }
            >
              Add New Free Text Reference
            </Button>
          </Stack>
        ) : (
          <Stack direction="row" spacing={2}>
            {referenceMaterialIds.length > 0 && config.type !== "add" && (
              <ListOfReference />
            )}
            <ReferenceEditor />
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

export type ReferenceMaterial = {
  documentId: string | null;
  id: string;
  insertedAt: string;
  isDeleted: boolean;
  title: string;
  updatedAt: string;
  stringContents: string | null;
};

export interface ReferenceMaterialResponse {
  data: Array<{ [id: string]: ReferenceMaterial }>;
}
