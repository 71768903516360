// material
import { gql, useQuery } from "@apollo/client";
import { Edit } from "@mui/icons-material";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import { GridComparatorFn } from "@mui/x-data-grid";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { FC } from "react";
import { Link } from "react-router-dom";
import { processPersonIdsIntoQueryComponents } from "./InspectionAssignment/components/InspectionDisplay/components/utilities/processPersonIdsIntoQueryComponents";
import { getWashoutQueryComponents } from "./InspectionAssignment/components/InspectionDisplay/components/utilities/getWashoutQueryComponents";
import { charBin2dec } from "src/utils/decimalToBinary";

// ----------------------------------------------------------------------

const INSPECTIONS_QUERY = gql`
  query ListInspections {
    listInspections {
      projectId
      id
      inspectionAssignments {
        start
        end
        personId
        id
      }
      inspectionType {
        name
      }
      status
    }
  }
`;

export default function InspectionAssignments() {
  const { data, error } = useQuery(INSPECTIONS_QUERY);

  return (
    // <Page title="Review | FINBACK670">
    <Container maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">Review</Typography>
      </Box>
      {error && <Typography>{error.message}</Typography>}
      {data && (
        <>
          <SecondaryDataQueries inspections={data.listInspections} />
        </>
      )}
    </Container>
    // </Page>
  );
}

// const GET_OPEN_WASHOUTS = gql`
//   query Washouts($washout: InputWashoutParams!) {
//     washouts(washout: $washout) {
//       id
//     }
//   }
// `;

// const WashoutCell: FC<{ inspectionId: string }> = ({ inspectionId }) => {
//   const { data, loading } = useQuery<any>(GET_OPEN_WASHOUTS, {
//     variables: {
//       washout: { inspectionId: inspectionId },
//     },
//   });

//   return (
//     <>
//       {loading && <CircularProgress />}
//       {data && data.washouts.length > 0 ? (
//         <Box
//           sx={{
//             backgroundColor: "#ff9595",
//             borderRadius: 1,
//             paddingX: "1rem",
//           }}
//         >
//           <Typography color="#490000">{data.washouts.length}</Typography>
//         </Box>
//       ) : (
//         <Typography>None</Typography>
//       )}
//     </>
//   );
// };

const getMostRecentAssignment = (assignments: any[]) => {
  return assignments.reduce((prev, current) =>
    prev.start > current.start ? prev : current
  );
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleString("en-US", {
    hour12: false,
  });
};

const dateComparator: GridComparatorFn<string> = (v1, v2) => {
  const oneDate = new Date(v1);
  const twoDate = new Date(v2);

  return (oneDate.getTime() || 0) - (twoDate.getTime() || 0);
};

const SecondaryDataQueries: FC<{ inspections: any[] }> = ({ inspections }) => {
  const [gqlDoc, variables] = processPersonIdsIntoQueryComponents(inspections);
  const [washoutGqlDoc, washoutVariables] = getWashoutQueryComponents(
    inspections.map((inspection) => inspection.id)
  );
  const { data } = useQuery(gqlDoc, { variables: variables });
  const { data: washouts } = useQuery(washoutGqlDoc, {
    variables: washoutVariables,
  });

  let transformInspections = null;
  if (data && washouts) {
    let newInspections: any[] = [];
    inspections.forEach((inspection: any, i: number) => {
      let washoutCount = 0;
      for (const key in washouts) {
        if (charBin2dec(key) === i) {
          washoutCount = washouts[key].length;
        }
      }
      if (inspection.inspectionAssignments.length > 0) {
        let mostRecent = inspection.inspectionAssignments.reduce(
          (prev: any, current: any) =>
            prev.start > current.start ? prev : current
        );
        let person: any = null;
        for (const key in data) {
          if (data[key] && data[key].id === mostRecent.personId) {
            person = data[key];
          }
        }
        mostRecent = { ...mostRecent, person: person };
        newInspections.push({
          ...inspection,
          washoutCount: washoutCount || 0,
          inspectionAssignments: [mostRecent],
        });
      } else {
        newInspections.push({ ...inspection, washoutCount: 0 });
      }
    });
    transformInspections = newInspections;
  }

  return transformInspections ? (
    <DataGridPremium
      autoHeight
      columns={[
        {
          field: "edit",
          headerName: "",
          width: 10,
          renderCell: (params: any) => (
            <Link to={params.id}>
              <Edit />
            </Link>
          ),
        },
        {
          field: "id",
          headerName: "Control #",
        },
        {
          field: "start",
          headerName: "Start Date",
          width: 160,
          sortComparator: dateComparator,
          valueGetter: (params: any) => {
            return (
              (params.row.inspectionAssignments &&
                params.row.inspectionAssignments.length !== 0 &&
                formatDate(
                  getMostRecentAssignment(params.row.inspectionAssignments)
                    .start
                )) ||
              null
            );
          },
        },
        {
          field: "end",
          headerName: "End Date",
          width: 160,
          valueGetter: (params: any) =>
            (params.row.inspectionAssignments &&
              params.row.inspectionAssignments.length !== 0 &&
              formatDate(
                getMostRecentAssignment(params.row.inspectionAssignments).end
              )) ||
            null,
        },
        {
          field: "inspectionAssignments",
          headerName: "Inspector",
          width: 140,
          valueGetter: (params: any) =>
            (params.row.inspectionAssignments &&
              params.row.inspectionAssignments[0] &&
              params.row.inspectionAssignments[0].person &&
              params.row.inspectionAssignments[0].person.firstName +
                " " +
                params.row.inspectionAssignments[0].person.lastName) ||
            "",
        },
        {
          field: "inspectionType",
          headerName: "Type",
          width: 200,
          valueGetter: (params: any) => {
            return params.value.name || "unavailable";
          },
        },
        // {
        //   field: "status",
        //   headerName: "Status",
        //   width: 120,
        // },
        {
          field: "washoutCount",
          headerName: "Open Washouts",
          width: 130,
          align: "right",
        },
      ]}
      rows={transformInspections}
    />
  ) : (
    <CircularProgress />
  );
};
