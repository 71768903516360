import * as React from 'react';
// material
import { Box, Container, Typography, Tabs, Tab } from '@mui/material';
// components
import Page from '../../components/Page';
import UsersList from './list';
import { GQLForm } from 'src/utils/gql-mui-rhf/FormProvider';
// ----------------------------------------------------------------------

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function FirmView() {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Page title="Dashboard | FINBACK670">
            <Container maxWidth="xl" style={{ paddingBottom: '64pt' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Users" {...a11yProps(0)} />
                        <Tab label="Edit User" {...a11yProps(1)} />
                        <Tab label="Delete User" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Box sx={{ pb: 2 }}>
                        <Typography variant="h5">{`Users:`}</Typography>
                    </Box>
                    <UsersList />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Box sx={{ pb: 2 }}>
                        <Typography variant="h5">{`Edit User:`}</Typography>
                    </Box>
                    <GQLForm
                        mutationString="updateUser"
                    />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Box sx={{ pb: 2 }}>
                        <Typography variant="h5">{`Edit User:`}</Typography>
                    </Box>
                    <GQLForm
                        mutationString="deleteUser"
                    />
                </TabPanel>
            </Container>
        </Page >
    );
}
