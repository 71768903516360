import GQLTable from '../../../utils/gql-mui-rhf/Table';
import { useNavigate } from "react-router-dom";

// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
// ----------------------------------------------------------------------

export default function Firms() {
    const navigate = useNavigate()
    return (
        <Page title="Firms | FINBACK670">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">Firms</Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <GQLTable
                            queryString={'firms'}
                            queryVariables={{ "active": true }}

                            addMutationString={'createFirm'}
                            deleteMutationString={'deleteFirm'}
                            updateMutationString={'updateFirm'}
                            updateModal={true}
                            onRowClick={(rowData) => {
                                navigate(`/app/admin/firm/${rowData.id}`)
                              }}
                            // onRowClick={(rowData) => {
                            //     navigate(`/app/admin/role/${rowData.id}`)
                            // }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
