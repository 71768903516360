import { Grid } from "@mui/material";
import { useContext } from "react";
import { GQLForm } from "src/utils/gql-mui-rhf/FormProvider";
import { AuthContext } from "src/utils/contexts/auth";
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function LoginForm(params) {
  let navigate = useNavigate();
  let auth = useContext(AuthContext);
  let link = params.from ? params.from : '/app'
  return <Grid container spacing={3} width={1000}>
    <Grid item xs={12} sm={6} md={3} width={500}>
      <GQLForm mutationString="signup" onCompleted={(data) => {
        auth.signin(data.signup)
        navigate(link)
      }} />
    </Grid>
  </Grid>
}
