import GQLTable from '../../utils/gql-mui-rhf/Table';

// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
// ----------------------------------------------------------------------

export default function People() {
    return (
        <Page title="People | FINBACK670">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">Project People</Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <GQLTable
                            queryString={'projectPersons'}
                            addMutationString={'createProjectPerson'}
                            deleteMutationString={'deleteProjectPerson'}
                            updateMutationString={'updateProjectPerson'}
                            updateModal={true}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
