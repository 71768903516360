import { Configuration } from "src/pages/Inspections/TypeDefinitions";
import {
  IFormField,
  IFormJson,
} from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay";
import { exhaustiveGuard } from "./exhaustiveGuard";

export function excelParse(
  dictionaryObject: DictionaryContents,
  formJson: IFormJson,
  excelObject: any,
  enumerableCount?: number
) {
  switch (dictionaryObject.enumerable) {
    case false: {
      formJson.fields.forEach((field) => {
        if (dictionaryObject.fields === null) return;
        const excelMapping = dictionaryObject.fields[field.formFieldId];
        if (!excelMapping) return;
        if (fieldIsValid(field)) {
          const fieldValue = extractValue(field);
          if (fieldValue !== null) excelObject[excelMapping] = fieldValue;
        }
      });
      if (
        formJson.subFormInstances &&
        formJson.subFormInstances.length &&
        dictionaryObject.subforms
      ) {
        let enumerationLog: EnumerationLog = {};
        formJson.subFormInstances.forEach((subformInstance) => {
          if (!subformInstance.isVisible || subformInstance.isDeleted) return;
          const subDictionary =
            dictionaryObject.subforms![subformInstance.formId];
          switch (subDictionary.enumerable) {
            case false:
              excelParse(subDictionary, subformInstance, excelObject);
              break;

            case "specifiedLocation":
            case "byOffset":
              if (enumerationLog[subformInstance.formId] === undefined)
                enumerationLog[subformInstance.formId] = 0;
              excelParse(
                subDictionary,
                subformInstance,
                excelObject,
                enumerationLog[subformInstance.formId]
              );
              enumerationLog[subformInstance.formId]++;
              break;
            default:
              exhaustiveGuard(subDictionary);
          }
        });
      }
      break;
    }
    case "specifiedLocation":
      if (formJson.fields && formJson.fields.length) {
        formJson.fields.forEach((field) => {
          const fields =
            dictionaryObject.enumerations[enumerableCount as number].fields;
          if (fields === null) return;
          const excelMapping = fields[field.formId];
          if (fieldIsValid(field)) {
            const fieldValue = extractValue(field);
            if (fieldValue !== null) excelObject[excelMapping] = fieldValue;
          }
        });
      }
      if (formJson.subFormInstances && formJson.subFormInstances.length) {
        let enumerationLog: EnumerationLog = {};
        formJson.subFormInstances.forEach((subformInstance) => {
          const subformDictionary =
            dictionaryObject.enumerations[enumerableCount as number].subforms;
          if (subformDictionary === null) return;
          const subformMapping = subformDictionary[subformInstance.formId];
          if (!subformInstance.isDeleted && subformInstance.isVisible) {
            switch (subformMapping.enumerable) {
              case false:
                excelParse(subformMapping, subformInstance, excelObject);
                break;
              case "specifiedLocation":
              case "byOffset":
                if (enumerationLog[subformInstance.formId] === undefined)
                  enumerationLog[subformInstance.formId] = 0;
                excelParse(
                  subformMapping,
                  subformInstance,
                  excelObject,
                  enumerationLog[subformInstance.formId]
                );
                enumerationLog[subformInstance.formId]++;
                break;
              default:
                exhaustiveGuard(subformMapping);
            }
          }
        });
      }
      break;
    case "byOffset":
      // TODO: handle offset case
      break;
    default:
      exhaustiveGuard(dictionaryObject);
  }
}

function fieldIsValid(field: IFormField) {
  return field.isVisible && !field.isDeleted;
}

function extractValue(field: IFormField) {
  const formValue = field.formInput.formValue;
  const fieldType = field.formInput.formValue?.valueType || null;
  let returnValue = null;
  if (fieldType !== null && formValue !== undefined) {
    switch (fieldType) {
      case "InputDataType.STRING":
        returnValue = formValue.textValue;
        break;
      case "InputDataType.BOOL":
        returnValue = formValue.booleanValue;
        break;
      case "InputDataType.DATETIME":
        returnValue = formValue.beginDateValue;
    }
  }
  return returnValue;
}

type EnumerationLog = {
  [key: string]: number;
};

type MappingObject = { [key: string]: string };

export type DictionaryContents =
  | {
      name: string;
      enumerable: "specifiedLocation";
      enumerationGroups?: {
        groups: {
          [key: string]: string[];
        };
        groupMembers: {
          [key: string]: string;
        };
      };
      enumerations: {
        subforms: Dictionary | null;
        fields: MappingObject | null;
      }[];
    }
  | {
      name: string;
      enumerable: "byOffset";
      enumerationGroups?: {
        groups: {
          [key: string]: string[];
        };
        groupMembers: {
          [key: string]: string;
        };
      };
      fields: MappingObject | null;
      subforms: Dictionary | null;
      offsetLocations: string[];
    }
  | {
      name: string;
      enumerable: false;
      subforms: Dictionary | null;
      fields: MappingObject | null;
    };

export type Dictionary = {
  [key: string]: DictionaryContents;
};

export function generateDictionary(
  topLevelFormId: string,
  config: Configuration
) {
  let dictionary: Dictionary = {};
  const form = config.forms.find((form) => form.id === topLevelFormId);
  if (!form) return null;
  const enumerable = form.type === "RoomInspection";
  let fieldsObject: { [key: string]: string } = {};
  if (form.fields)
    form.fields.forEach((field) => {
      fieldsObject[field.id] = "";
    });

  let fields = Object.keys(fieldsObject).length === 0 ? null : fieldsObject;
  let subforms: Dictionary = {};

  if (form.subformIds)
    form.subformIds.forEach((subformId) => {
      let subformDictionary = generateDictionary(subformId, config);
      if (subformDictionary) {
        subforms = { ...subforms, ...subformDictionary };
      }
    });
  const subformsToWrite = Object.keys(subforms).length ? subforms : null;
  dictionary[form.id] = enumerable
    ? {
        enumerable: "byOffset",
        name: form.name,
        fields: fields,
        subforms: subformsToWrite,
        offsetLocations: [],
      }
    : // {
      //   enumerable: "specifiedLocation",
      //   name: form.name,
      //   enumerations: [
      //     {
      //       fields: fields,
      //       subforms: subformsToWrite,
      //     },
      //   ],
      // }
      {
        enumerable: false,
        name: form.name,
        fields: fields,
        subforms: subformsToWrite,
      };

  return dictionary;
}
