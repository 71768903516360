import { useEffect, useState } from 'react';
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ProjectContext } from "../../../utils/contexts/project";
// material
import { Box, Container, Typography } from '@mui/material';
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
// components
import Page from '../../../components/Page';
// ----------------------------------------------------------------------
import { gql } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';

const GET_PROJECT_INSPECTIONS = gql`
query projectInspections($projectId: ID) {
  projectInspections(projectId: $projectId) {
    id
    projectId
    inspectionLocation
    number
    contactName
    contactPhone
    isDeleted
    status
  }}
`;

export default function InspectionList() {
    const apiRef = useGridApiRef();
    let projectContext = useContext(ProjectContext);
    const { project } = projectContext;
    const [selectionModel, setSelectionModel] = useState([]);
    const [setSelectionRecord] = useState([]);
    const navigate = useNavigate()
    const [inspections, setProjectInspections] = useState(null);
    const [getProjectInspections] = useLazyQuery(GET_PROJECT_INSPECTIONS, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setProjectInspections(response.projectInspections)
        },
        onError: (response) => {
        }
    });
    useEffect(
        () => {
            if (project && !inspections) {
                getProjectInspections({ variables: { projectId: project.id } })
            }
        },
        [project, inspections, getProjectInspections]
    )
    let rows = inspections ? inspections : []
    return (
        <Page title="Inspections | FINBACK670">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">Inspections</Typography>
                </Box>
                <div style={{ height: 600, width: "100%" }}>
                    <DataGridPremium
                        rows={rows}
                        apiRef={apiRef}
                        columns={[
                            { field: 'inspectionType.name', headerName: "Inspection Type" },
                            { field: 'number', headerName: "Inspection Number" },
                            { field: 'contactName', headerName: "Contact Name" },
                            { field: 'contactPhone', headerName: "Contact Phone" },
                            { field: 'inspectionLocation', headerName: "Project Location" },
                            { field: 'projectId', headerName: "Project" },
                            { field: 'id', headerName: "ID" },
                        ]}
                        checkboxSelection
                        pageSize={5}
                        onRowClick={(rowData) => {
                            navigate(`/app/inspections/${rowData.id}`)
                        }}
                        pageSizeOptions={[50]}
                        experimentalFeatures={{ newEditingApi: true }}
                        onRowSelectionModelChange={(ids) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData = rows.filter((row) =>
                                selectedIDs.has(row.id)
                            );
                            setSelectionRecord(selectedRowData);
                            setSelectionModel(ids);
                        }}
                        rowSelectionModel={selectionModel}
                    />
                </div>
            </Container>
        </Page>
    );
}
