import { PageState } from "src/pages/Inspections/TypeDefinitions";

export default function updateIsVisibleFields(draft: PageState) {
  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );
  const form = draft.data.inspectionTypeTemplate.configuration.forms[formIndex];
  if (!form) return;
  const fieldsWithShowValidations = form.validations
    .filter((validation) => validation.action === "Show")
    .map((validation) => validation.field);
  draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.forEach((field) => {
    field.isVisible = !fieldsWithShowValidations.includes(field.id);
  });
}
