import { useState } from 'react';
import { useContext } from "react";
import { ProjectContext } from "../../../utils/contexts/project";
import { Icon } from "@iconify/react";
import plusSquareOutline from "@iconify/icons-eva/plus-square-outline";
// material
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import { Box, Grid, Button, Container, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
// components
import { GQLMutationAction } from "../../../utils/gql-mui-rhf/SingleMutation";
import Page from '../../../components/Page';
import createQuery from '../../../utils/gql-mui-rhf/QueryAction';
import {GQLForm} from '../../../utils/gql-mui-rhf/FormProvider';
// ----------------------------------------------------------------------

const filterProjectLocations = (locations, inspections) => {
    const filteredArray = locations.filter((elem) => {
        return !inspections.some((ele) => {
            return ele.inspection_location === elem.id;
        });
    });
    return filteredArray
}
function createInspection(refetch) {
    return GQLMutationAction({
        mutationString: "createInspection",
        onCompleted: (response) => {
            refetch();
        },
    });
}
function renderTableActions(
    selectionModel, setModalOpen
) {
    return (
        <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={4}
        >
            <Grid item>
                <h4>Actions: </h4>
            </Grid>
            <Grid item>
                <Button
                    onClick={() => {
                        setModalOpen(true);
                    }}
                    variant="outlined"
                    startIcon={<Icon icon={plusSquareOutline} />}
                >
                    {`Create ${selectionModel.length} inspection(s)`}
                </Button>
            </Grid>
        </Grid>
    );
}

function createInspections(
    open,
    handleClose,
    selectionModel,
    inspectionType,
    setIT,
    iTData,
    handleCreateInspections
) {
    const handleChange = (event) => {
        setIT(event.target.value);
    };
    return (
        <Dialog open={open} onClose={() => { handleClose(false) }} fullWidth={true} maxWidth='xs'>
            <DialogContent>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Inspection Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={inspectionType}
                        label="Inspection Type"
                        onChange={handleChange}
                    >
                        {iTData.inspectionTypes.map((type) => {
                            return <MenuItem value={type.id}>{type.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <DialogActions>
                    <Button onClick={handleCreateInspections} autoFocus>
                        {`Create ${selectionModel.length} Inspection(s)`}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

export default function Assignments() {
    const apiRef = useGridApiRef();
    let projectContext = useContext(ProjectContext);
    const { project } = projectContext;
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectionRecord, setSelectionRecord] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [inspectionType, setIT] = useState(null);
    if (!project) { return null }

    const { loading: projectsLoading, data: projectsData, refetch: refetchProjects } = createQuery('projectLocations', { projectId: project.id })
    const { loading: inspectionsLoading, data: inspectionsData } = createQuery('inspections', { projectId: project.id })
    const { loading: iTLoading, data: iTData } = createQuery('inspectionTypes', { projectId: project.id });
    const handleCreateInspection = createInspection(refetchProjects, project.id, inspectionType);
    const handleCreateInspections = () => {
        selectionModel.forEach((item) => {
            handleCreateInspection({ number: item, inspectionTypeId: inspectionType, projectId: project.id, inspectionLocation: item })
        })
    }
    if (projectsLoading || inspectionsLoading || iTLoading) {
        return null
    }
    if (projectsData && inspectionsData && iTData) {
        const rows = filterProjectLocations(projectsData.projectLocations, inspectionsData.inspections)
        return (
            <Page title="Inspections | FINBACK670">
                <Container maxWidth="xl">
                    <GQLForm mutationString="createDefaultInspectionType" onCompleted={(data) => {console.log(data)}}/>
                    <GQLForm mutationString="createDefaultInspectionTypeTemplate" onCompleted={(data) => {console.log(data)}}/>
                    <Box sx={{ pb: 5 }}>
                        <Typography variant="h4">Create Inspections</Typography>
                    </Box>
                    {createInspections(modalOpen, setModalOpen, selectionModel, inspectionType, setIT, iTData, handleCreateInspections)}
                    <div style={{ paddingBottom: "16px" }}>
                        {renderTableActions(
                            selectionRecord,
                            setModalOpen
                        )}
                    </div>
                    <div style={{ height: 600, width: "100%" }}>
                        <DataGridPremium
                            rows={rows}
                            apiRef={apiRef}
                            columns={[
                                { field: 'street1', headerName: "Street 1" },
                                { field: 'street2', headerName: "Street 2" },
                                { field: 'street3', headerName: "Street 3" },
                                { field: 'neighborhood', headerName: "Neighborhood" },
                                { field: 'city', headerName: "City " },
                                { field: 'state', headerName: "State" },
                                { field: 'zip', headerName: "Zip" },
                            ]}
                            checkboxSelection
                            pageSize={5}
                            pageSizeOptions={[50]}
                            experimentalFeatures={{ newEditingApi: true }}
                            onRowSelectionModelChange={(ids) => {
                                const selectedIDs = new Set(ids);
                                const selectedRowData = rows.filter((row) =>
                                    selectedIDs.has(row.id)
                                );
                                setSelectionRecord(selectedRowData);
                                setSelectionModel(ids);
                            }}
                            rowSelectionModel={selectionModel}
                        />
                    </div>
                </Container>
            </Page>
        );
    }
}
