import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Select } from "../../../utils/mui-rhf";
import createLazyQuery from "../../../utils/gql-mui-rhf/LazyQuery"
import { GQLForm } from 'src/utils/gql-mui-rhf/FormProvider';
import { Grid } from '@mui/material';

export default function AddContactToProject({ project, open, handleClose, refetch }) {
    const [contactTypes, setContactTypes] = useState([]);
    const onGetContactTypes = (data) => {
        let options = []
        data.projectContactTypes
            .forEach((projectContactType) => {
                options.push({
                    value: projectContactType.id,
                    label: projectContactType.name
                })
            })
        options.sort((a, b) => a.label.localeCompare(b.label));
        setContactTypes(options)
    }
    const onError = () => { debugger }
    const [getContactTypes] = createLazyQuery("projectContactTypes", onGetContactTypes, onError);

    useEffect(() => {
        if (project.id) {
            getContactTypes({
                variables: {
                    projectId: project.id,
                }
            })
        }
    },
        [project, getContactTypes])

    return <Dialog
        open={open}
        onClose={() => {
            handleClose()
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {`Create Contact to Add to Project`}
        </DialogTitle>
        <DialogContent>
            <GQLForm
                mutationString='createProjectContact'
                additionalVariables={{ projectId: project.id }}
                onCompleted={() => {
                    refetch()
                    handleClose()
                }}
                skipFields={{
                    projectId: true,
                    projectContactTypeId: true
                }}
                inputOrder={{
                    "firstName": { order: 1 },
                    "lastName": { order: 2 },
                    "email": { order: 3 },
                    "phone": { order: 4 },
                    "street1": { order: 5 },
                    "street2": { order: 6 },
                    "city": { order: 7 },
                    "state": { order: 8 },
                    "zip": { order: 9 },
                }}
            >
                <Grid item xs={6}>
                    <Select rules={{ required: "select option" }} label={"Contact Type"} name={"projectContactTypeId"} options={contactTypes} />
                </Grid>
            </GQLForm>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => { handleClose() }}>Cancel</Button>
        </DialogActions>
    </Dialog>
}
