import { Switch,   FormLabel,
    FormControl,
    FormHelperText, } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

export const MUIRHFTextField = ({
  name,
  description,
  label,
  defaultValue,
  rules,
  disabled,
  ...rest
}) => {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        rules={rules}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error, invalid } }) => {
          return (
            <FormControl disabled={!!disabled} error={invalid} {...field}>
            <FormLabel>{label}</FormLabel>
            <Switch
              {...field}
              margin="dense"
              checked={!!field.value}
              label={label}
              error={invalid}
              helperText={error?.message || description || ""}
              {...rest}
              defaultValue={false}
            />
                        {error?.message && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
          );
        }}
      />
    </>
  );
};

export default MUIRHFTextField;
