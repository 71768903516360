import {
    Autocomplete,
    Stack,
    TextField,
} from "@mui/material";
import MUITextField from '../../utils/mui-rhf/TextField'
import { useEffect, useState } from "react";

const defaultFields = {
    street1: "",
    street2: "",
    street3: "",
    city: "",
    zip: "",
    state: "",
    unit: "",
    mapboxId: "123",
};
/** INFO:
 *Change between Update and Create type using the *config* prop. If empty, defaults to 'Create'.
 *
 */
const AddressSelect = ({ initialConfig, methods, rules = {}, group = "" }) => {
    const [query, setQuery] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [fields, setFields] = useState(defaultFields);
    // eslint-disable-next-line no-unused-vars
    const [config, setConfig] = useState(initialConfig);

    useEffect(() => {
        async function getSearch() {
            if (!query || query.split(" ").length < 2 || query.split(" ")[1] === "") {
                setOptions([]);
                setLoading(false);
                return;
            }
            let res = await fetch(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
                    query
                )}.json?types=address&access_token=${process.env.REACT_APP_MAPBOX_TOKEN
                }&proximity=ip`
            ).then((response) => response.json());
            setOptions(res.features);
            setLoading(false);
        }

        if (query) {
            setLoading(true);
            setOptions([]);
        }
        let timer = setTimeout(() => {
            getSearch();
        }, 500);
        return () => {
            clearTimeout(timer);
        };
    }, [query]);

    useEffect(() => {
        if (open === false && config?.type !== "update") {
            setTimeout(() => {
                setFields(defaultFields);
                setQuery("");
            }, 200);
        }
    }, [open, config]);

    function handleSelect(value) {
        setSelected(value);
        value && setFields(mapBoxToState(value, methods, group));
    }
    function handleClear() {
        setSelected(null);
    }


    return (
        <>
            <Stack
                direction="column"
                width="30rem"
                spacing={2}
                paddingTop="0.5rem"
            >
                <Autocomplete
                    fullWidth
                    id="address-search"
                    onInputChange={(e, value) => {
                        setSelected(null);
                        setQuery(value);
                    }}
                    includeInputInList
                    autoHighlight
                    value={selected}
                    open={Boolean(query) && !Boolean(selected)}
                    filterOptions={(x) => x}
                    onChange={(e, value, reason) => {
                        if (reason === "clear") handleClear();
                        if (reason !== "clear") handleSelect(value);
                    }}
                    options={options}
                    noOptionsText={
                        query.split(" ").length < 2 || query.split(" ")[1] === ""
                            ? "Be more specific."
                            : "No options."
                    }
                    loading={loading}
                    isOptionEqualToValue={(option, value) =>
                        option.place_name === value.place_name
                    }
                    getOptionLabel={(option) => option.place_name}
                    renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "chrome-off";
                        return (
                            <TextField
                                {...params}
                                inputProps={inputProps}
                                label="Geocode Address"
                            />
                        );
                    }}
                />
                <MUITextField
                    label="Address Line 1"
                    name={`${group}street1`}
                    rules={rules.street1}
                />
                <MUITextField
                    label="Address Line 2"
                    name={`${group}street2`}
                    rules={rules.street2}
                />
                <MUITextField
                    label="Address Line 3"
                    name={`${group}street3`}
                    rules={rules.street3}
                />
                <MUITextField
                    label="City"
                    name={`${group}city`}
                    rules={rules.city}
                />

                <Stack direction="row" spacing={0}>
                    <MUITextField
                        label="Zip"
                        name={`${group}zip`}
                        rules={rules.zip}
                    />
                    <MUITextField
                        label="State"
                        name={`${group}state`}
                        rules={rules.state}
                    />
                </Stack>
            </Stack>
        </>
    );
};

export default AddressSelect;

function mapBoxToState(response, methods, group) {
    let fields = {
        mapboxId: "123",
        street1: "",
        street2: "",
        street3: "",
        city: "",
        zip: "",
        state: "",
        unit: "",
    };
    fields.street1 = response.address + " " + response.text;
    methods.setValue(`${group}mapboxId`, response.id)
    methods.setValue(`${group}lng`, response.geometry.coordinates[0])
    methods.setValue(`${group}lat`, response.geometry.coordinates[1])
    methods.setValue(`${group}street1`, response.address + " " + response.text)
    response.context.forEach((data) => {
        if (data.id.startsWith("postcode")) {
            methods.setValue(`${group}zip`, data.text)
            fields.zip = data.text;
        }
        if (data.id.startsWith("place")) {
            methods.setValue(`${group}city`, data.text)
            fields.city = data.text;
        }
        if (data.id.startsWith("region")) {
            methods.setValue(`${group}state`, data.text)
            fields.state = data.text;
        }
    });
    return fields;
}
