import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import settings from "@iconify/icons-eva/settings-2-fill";
import { alpha } from "@mui/material/styles";
import { Box, Button, IconButton } from "@mui/material";
import MenuPopover from "../../components/MenuPopover";

export default function ConfigPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        size="large"
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}
      >
        <Icon icon={settings} width={20} height={20} />
      </IconButton>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            component={RouterLink}
            to="/app/user/profile"
          >
            My Account
          </Button>
          <Button
            fullWidth
            disableRipple
            component={RouterLink}
            to="/app/admin/credential"
          >
            Configurations
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
