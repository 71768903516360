import { FC, Fragment, useContext } from "react";
import ChildField from "src/pages/Inspections/ChildField";
import { Field, Form } from "src/pages/Inspections/TypeDefinitions";
import { FirmContext } from "src/utils/contexts/firm";
import { PersonContext } from "src/utils/contexts/person";
import { IFormField, IFormJson } from "..";

function extractField(form: Form, fieldId: string): Field | null {
  const field = form.fields.find((field) => field.id === fieldId);
  return field || null;
}

const InspectionItem: FC<{
  field: IFormField;
  fieldUpdater: (updatedField: IFormField) => void;
  form: Form;
  formJson: IFormJson;
}> = ({ field, fieldUpdater, form, formJson }) => {
  let fieldToRender = extractField(form, field.formInput.formFieldId);
  const personCtx: any = useContext(PersonContext);
  const firmCtx: any = useContext(FirmContext);
  if (fieldToRender && personCtx !== null && firmCtx !== null) {
    let date = field.formInput.formValue?.createdAt
      ? new Date(field.formInput.formValue?.createdAt)
      : null;
    return (
      <Fragment key={field.id}>
        <ChildField
          fieldData={fieldToRender}
          formState={{
            firmId: firmCtx.firm,
            personId: personCtx.person.id,
            formJson: formJson,
            completedData: field,
            updateData: fieldUpdater,
          }}
        />
        {date && (
          <p
            style={{ textAlign: "right", marginTop: 0 }}
          >{`${date.toDateString()} ${date.toLocaleTimeString()}`}</p>
        )}
      </Fragment>
    );
  } else return null;
};

export default InspectionItem;
