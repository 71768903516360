// material
import { Box, Container, Typography, TextField, Stack } from "@mui/material";

import { useState } from "react";
// components
import Page from "../../components/Page";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [state] = useState({
    name: "Department of the Army",
    address: "1400 Defense Pentagon",
    address2: "",
    address3: "",
    city: "Washington",
    state: "DC",
    zip: "20301",
    phone: "301-222-2232",
    phone2: "301-222-2769",
  });
  return (
    <Page title="Firm Profile | FINBACK670">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Manage Firm Profile</Typography>
        </Box>
        <Stack direction="column" spacing={2} maxWidth="30rem">
          <TextField label="Name" value={state.name} />
          <TextField label="Address" value={state.address} />
          <TextField label="Address 2" value={state.address2} />
          <TextField label="Address 3" value={state.address3} />
          <TextField label="City" value={state.city} />
          <TextField label="State" value={state.state} />
          <TextField label="Zip" value={state.zip} />
          <TextField label="Phone" value={state.phone} />
          <TextField label="Phone 2" value={state.phone2} />
          <TextField label="Fax" value={state.name} />
        </Stack>
      </Container>
    </Page>
  );
}
