//gql-mui-rhf
import { CircularProgress } from '@mui/material';
import createQuery from '../../utils/gql-mui-rhf/QueryAction';
import RoleTypeCredentialView from './RoleTypeCredentialView';
// material
// components

// ----------------------------------------------------------------------


export default function RoleTypeCredentialViews({roleTypeId, refetchRoleCredentials, shouldRefetch}) {
    const { loading, error, data, refetch } = createQuery('roleTypeCredentials', {roleTypeId: roleTypeId, active: true})
    if(refetchRoleCredentials) {
        refetch()
        shouldRefetch()
    }
    if (loading)
    return <CircularProgress />
    if (error) {
        return <div>{`${error}`}</div>
    }
    if(data) {
    return (
        <>
        {data.roleTypeCredentials.map((roleTypeCredential, idx) => <RoleTypeCredentialView refetchParent={refetch} style={{marginBottom: '64px'}} key={idx} roleTypeCredential={roleTypeCredential}/>)}
        </>
    );
    }
}
