// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------

export default function Admin() {
  return (
    <Page title="Dashboard | FINBACK670">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Administrative Dashboard</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <p>Accessing GraphQL API at endpoint: {process.env.REACT_APP_GRAPHQL_URL}</p>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
