// material
import { useState, useEffect, useCallback } from 'react';
import {
    DataGridPremium,
    GridToolbar
} from "@mui/x-data-grid-premium";
import { Container } from '@mui/material';
// components
import Confirmation from "../../../components/modals/confirmation";
import TableActions from '../../../components/tables/tableActions';
import Page from '../../../components/Page';
import AddProjectContactType from './addProjectContactType';
// ----------------------------------------------------------------------
import { useLazyQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const DELETE_PROJECT_CONTACT_TYPES = gql`
    mutation deleteProjectContactTypes($ids: [ID]!) {
        deleteProjectContactTypes(ids: $ids) {
            id
        }
    }
`
const GET_PROJECT_CONTACT_TYPES = gql`
query ProjectPersons($projectId: ID) {
  projectContactTypes(projectId: $projectId) {
    id
    name
    project {
        id
        name
    }
  }
}
`

const UPDATE_PROJECT_CONTACT_TYPE = gql`
    mutation updateProjectContactType($projectContactType: InputProjectPersonParams) {
        updateProjectContactType(projectContactType: $projectContactType) {
                id
                name
                project {
                    id
                    name
                }
        }
    }
`
export default function ProjectPeople({ project }) {
    const [rows, setRows] = useState([]);
    const [addOpen, setAddOpen] = useState(false);
    const closeAdd = () => {
        setAddOpen(false);
    };
    const openAdd = () => {
        setAddOpen(true);
    };
    const [selectionModel, setSelectionModel] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [selectionRecord, setSelectionRecord] = useState([]);
    const [confirmation, setConfirmation] = useState({ open: false });
    const closeConfirmation = () => {
        setConfirmation({ open: false });
    };
    const [getProjectContactTypes] = useLazyQuery(GET_PROJECT_CONTACT_TYPES, {
        variables: { projectId: project.id },
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setRows(response.projectContactTypes)
        }
    });
    useEffect(() => {
        getProjectContactTypes({ variables: { projectId: project.id } })
    }, [project, getProjectContactTypes]);

    const [updateProjectContactType] = useMutation(UPDATE_PROJECT_CONTACT_TYPE)
    const [deleteProjectContactTypes] = useMutation(DELETE_PROJECT_CONTACT_TYPES, {
        onCompleted: () => {
            refetchCallback()
        }
    })

    const refetchCallback = useCallback(
        () => {
            getProjectContactTypes({ variables: { projectId: project.id } })
        }, [project, getProjectContactTypes]
    )
    const processRowUpdate = useCallback(
        async (newRow) => {
            const projectContactType = { id: newRow.id, name: newRow.name }
            updateProjectContactType({ variables: { projectContactType } });
            refetchCallback()
        },
        [updateProjectContactType, refetchCallback]
    );
    const handleProcessRowUpdateError = useCallback((error) => { }, []);

    const onDeleteProjectContactTypes = (selectionModel) => {
        let ids = selectionModel.map((model) => model.id)
        deleteProjectContactTypes({ variables: { ids } })
    }

    return (
        <Page title="Projects | FINBACK670">
            <Confirmation
                confirmation={confirmation}
                handleClose={closeConfirmation}
            />
            <AddProjectContactType
                project={project}
                open={addOpen}
                handleClose={closeAdd}
                refetch={refetchCallback}
            />
            <Container maxWidth="xl">
                <TableActions
                    addRecord={openAdd}
                    deleteRecords={onDeleteProjectContactTypes}
                    selectionModel={selectionRecord}
                    setConfirmation={setConfirmation}
                />
                <div style={{ height: 600, width: "100%" }}>
                    <DataGridPremium
                        rows={rows}
                        loading={!project}
                        columns={[
                            { editable: false, field: 'name', headerName: "Contact Type" }
                        ]}
                        pageSize={5}
                        pageSizeOptions={[10, 25, 50]}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        slots={{ toolbar: GridToolbar }}
                        checkboxSelection
                        onRowSelectionModelChange={(ids) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData = rows.filter((row) =>
                                selectedIDs.has(row.id)
                            );
                            setSelectionRecord(selectedRowData);
                            setSelectionModel(ids);
                        }}
                        rowSelectionModel={selectionModel}
                    />
                </div>
            </Container>
        </Page>
    );
}
