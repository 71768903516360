import { Action, Expression, PageState } from "../../TypeDefinitions";

export function updateExpression<
  E extends Extract<
    Action,
    {
      type: "updateExpression";
    }
  >
>(action: E, draft: PageState) {
  const { paramToUpdate, validationId, expressionId } = action.payload;

  const currentFormIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );
  const currentValidationIndex =
    draft.data.inspectionTypeTemplate.configuration.forms[
      currentFormIndex
    ].validations.findIndex((validation) => validation.id === validationId);

  const currentExpressionIndex =
    draft.data.inspectionTypeTemplate.configuration.forms[
      currentFormIndex
    ].validations[currentValidationIndex].expressions.findIndex(
      (expression) => expression.id === expressionId
    );

  const currentExpression =
    draft.data.inspectionTypeTemplate.configuration.forms[currentFormIndex]
      .validations[currentValidationIndex].expressions[currentExpressionIndex];

  if (
    currentFormIndex === -1 ||
    currentValidationIndex === -1 ||
    currentExpressionIndex === -1
  )
    return;

  let expression: Expression = { ...currentExpression, ...paramToUpdate };

  draft.data.inspectionTypeTemplate.configuration.forms[
    currentFormIndex
  ].validations[currentValidationIndex].expressions[currentExpressionIndex] =
    expression;
}
