import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { GQLForm } from 'src/utils/gql-mui-rhf/FormProvider';

export default function AddContactToProject({ project, open, handleClose, refetch }) {
    return <Dialog
        open={open}
        onClose={() => {
            handleClose()
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {`Create Contact Type to Add to Project`}
        </DialogTitle>
        <DialogContent>
            <GQLForm
                mutationString='createProjectContactType'
                additionalVariables={{projectId: project.id}}
                onCompleted={()=>{
                    refetch()
                    handleClose()
                }}
                skipFields={{
                    projectId: true,
                }}
                inputOrder={{ "First Name": { order: 1 }, "Last Name": { order: 2 } }}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => { handleClose() }}>Cancel</Button>
        </DialogActions>
    </Dialog>
}
