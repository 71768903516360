import React, { useState } from 'react';

let ProjectContext = React.createContext(null);

function ProjectProvider({ children }) {
    const [project, setProject] = useState(null);
    let value = { project, setProject };

    return <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>;
}

export { ProjectContext, ProjectProvider }

