import React, { FC, Fragment, memo } from "react";
import {
  Configuration,
  InputType,
} from "src/pages/Inspections/TypeDefinitions";
import { decode } from "./components/utilities/decode";
import InspectionDisplay from "./components/InspectionDisplay";

export interface InspectionDisplayProps {
  config: Configuration;
  formJson: IFormJson;
}

const MultiInspectionDisplayWithoutMemo: FC<{
  thoFormInstances: IFormInstance[];
  config: Configuration;
  expandedDisplay?: boolean;
  updateSyncStatus?: (instanceId: string, isSyning: boolean) => void;
}> = ({ thoFormInstances, expandedDisplay, updateSyncStatus, config }) => {
  return (
    <>
      {thoFormInstances.map((thoFormInstance) => {
        const decodedJson = decode(thoFormInstance.formJson);
        return (
          <InspectionDisplay
            expandedDisplay={expandedDisplay}
            key={thoFormInstance.id}
            formJson={decodedJson}
            setIsSyncing={updateSyncStatus}
            config={config}
          />
        );
      })}
    </>
  );
};

const MultiInspectionDisplay = memo(
  MultiInspectionDisplayWithoutMemo,
  (prevProps, nextProps) => {
    return false;
  }
);

export default MultiInspectionDisplay;

export interface IFormInstance {
  formConfigurationId: string;
  formId: string;
  formJson: string;
  id: string;
  inspectionAssignmentId: string;
  inspectionId: string;
  inspectionTypeTemplateId: string;
  isDeleted: boolean;
  name: string;
  reference: string;
  referenceType: string;
  type: string;
}

export interface IFormJson {
  entityId: string | null;
  fields: IFormField[];
  formConfigurationId: string;
  formId: string;
  id: string;
  inspectionAssignmentId: string;
  inspectionId: string;
  inspectionTypeTemplateId: string;
  isDeleted: boolean;
  isDirty: boolean;
  isEmbedded: boolean;
  isValid: boolean;
  isVisible: boolean;
  name: string;
  parentFormId: string | null;
  parentFormInstanceId: string | null;
  reference: string;
  referenceType: string;
  subFormInstances: IFormJson[];
  type: string;
}

export interface IFormField {
  description: string;
  displayIndex: number;
  formFieldId: string;
  formId: string;
  formInput: IFormInput;
  formInstanceId: string;
  id: string;
  inputType: InputType;
  isDeleted: boolean;
  isDirty: boolean;
  isValid: boolean;
  isVisible: boolean;
  label: string;
  name: string;
  parentFormFieldId: string | null;
  parentFormFieldInstanceId: string | null;
}

export interface IFormInput {
  formFieldId: string;
  formFieldInstanceId: string;
  id: string;
  formValue?: IFormValue;
}

export interface IFormValue {
  beginDateValue: null | string;
  booleanValue: null | boolean;
  createdAt: string | null;
  createdBy: string;
  doubleValue: null | number;
  endDateValue: null;
  entityId: string;
  firmId: string;
  formConfigurationId: string;
  formFieldId: string;
  formFieldInstanceId: string;
  formId: string;
  formInstanceId: string;
  id: string;
  inspectionAssignmentId: string;
  inspectionId: string;
  inspectionTemplateId: string;
  integerValue: null | number;
  isDeleted: boolean;
  isDirty: boolean;
  jsonValue: null | any;
  name: string;
  parentId: null;
  photoIds: null;
  referenceType: string;
  textValue: null | string;
  textValues: null | string[];
  valueType: string | null;
}
