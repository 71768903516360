import React, { useState } from 'react';

let FirmUnitContext = React.createContext(null);

function FirmUnitProvider({ children }) {
    const [firmUnit, setFirmUnit] = useState(null);
    let value = { firmUnit, setFirmUnit };
    return <FirmUnitContext.Provider value={value}>{children}</FirmUnitContext.Provider>;
}

export { FirmUnitContext, FirmUnitProvider }

