import SchemaContext from "./SchemaContext";
import React, { useContext } from "react";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { getQueryInputs, getQueryReturn, generateQuery } from "./QueryHelpers";
import { useQuery } from "@apollo/client";
import { Grid, Button, CircularProgress } from "@mui/material";
import { Icon } from "@iconify/react";
import plusSquareOutline from "@iconify/icons-eva/plus-square-outline";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import editOutline from "@iconify/icons-eva/edit-outline";
import { GQLForm } from "./FormProvider";
import { GQLMutationAction } from "./MutationAction";
import { GQLMutationAction as singleMutation } from "./SingleMutation";
import Confirmation from "../../components/modals/confirmation";
import { useSnackbar } from "notistack";

function processColumns(
  data,
  restrictedColumns = {},
  columnOptions = {},
  columnOrder
) {
  let columns = [];
  let inputs = Object.keys(data);
  inputs.forEach((datum) => {
    if (!restrictedColumns[datum]) {
      columns.push({
        field: datum,
        headerName: datum.toUpperCase(),
        ...columnOptions[datum],
      });
    }
  });
  columns.reverse();
  if (columnOrder) {
    const rest = columns
      .filter((column) => !columnOrder.includes(column.field))
      .map((column) => column.field);
    if (columnOrder.includes("rest")) {
      let restIndex = columnOrder.indexOf("rest");
      let before = restIndex === 0 ? [] : columnOrder.slice(0, restIndex);
      let after = columnOrder.slice(restIndex + 1);
      columnOrder = before.concat(rest, after);
    } else {
      columnOrder.push(...rest);
    }

    columns = columns.sort(
      (a, b) => columnOrder.indexOf(a.field) - columnOrder.indexOf(b.field)
    );
  }
  return columns;
}
function actionText(action, length) {
  if (length === 1) {
    return `${action} Record`;
  }
  return `${action} ${length} Records`;
}
function additionalActions(customActions, selectionModel) {
  return customActions.map((action) => {
    const actionComponent = <Grid item>
      <Button
        onClick={() => {
          action.callback(selectionModel);
        }}
        variant="outlined"
        startIcon={<Icon icon={action.icon} />}
      >
        {actionText(action.text, selectionModel.length)}
      </Button>
    </Grid>
    switch (action.type) {
      case 'single':
        return selectionModel.length === 1 && actionComponent
      case 'multiple':
        return selectionModel.length > 0 && actionComponent
      case 'general':
        return <Grid item>
          <Button
            onClick={() => {
              action.callback(selectionModel);
            }}
            variant="outlined"
            startIcon={<Icon icon={action.icon} />}
          >
            {action.text}
          </Button>
        </Grid>
      default:
        return selectionModel.length === 1 && actionComponent
    }

  })
}
function renderTableActions(
  selectionModel,
  setConfirmation,
  addRecord,
  deleteRecords,
  editRecord,
  customActions = []
) {
  if (!addRecord && !deleteRecords && !editRecord) return null;
  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={4}
    >
      <Grid item>
        <h4>Actions: </h4>
      </Grid>
      {selectionModel.length > 0 && (
        <Grid item>
          <Button
            onClick={() => {
              setConfirmation({
                open: true,
                action: () => {
                  deleteRecords(selectionModel);
                },
                actionText: actionText("Delete", selectionModel.length),
              });
            }}
            variant="outlined"
            startIcon={<Icon icon={trash2Outline} />}
          >
            {actionText("Delete", selectionModel.length)}
          </Button>
        </Grid>
      )}
      {addRecord && (
        <Grid item>
          <Button
            onClick={addRecord}
            variant="outlined"
            startIcon={<Icon icon={plusSquareOutline} />}
          >
            Add New
          </Button>
        </Grid>
      )}
      {editRecord && selectionModel.length === 1 && (
        <Grid item>
          <Button
            onClick={editRecord}
            variant="outlined"
            startIcon={<Icon icon={editOutline} />}
          >
            Edit
          </Button>
        </Grid>
      )}
      {additionalActions(customActions, selectionModel, selectionModel)}
    </Grid>
  );
}

function addRecordModal(
  open,
  handleClose,
  addMutationString,
  refetch,
  snackbar,
  restrictedColumns,
  columnOrder,
  addMutationVariables
) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <GQLForm
          mutationString={addMutationString}
          additionalVariables={addMutationVariables}
          inputOrder={columnOrder}
          skipFields={restrictedColumns}
          onCompleted={() => {
            refetch();
            handleClose();
            snackbar("Added Record", "success");
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

function updateRecordModal(
  open,
  handleClose,
  updateMutationString,
  refetch,
  snackbar,
  restrictedColumns,
  columnOrder,
  selectionModel,
  selectionRecord
) {
  if (selectionModel && selectionModel.length !== 1) return;
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <GQLForm
          defaultValues={selectionRecord[0]}
          mutationString={updateMutationString}
          additionalVariables={{ id: selectionModel[0] }}
          inputOrder={columnOrder}
          skipFields={restrictedColumns}
          onCompleted={() => {
            refetch();
            handleClose();
            snackbar("Updated Record", "success");
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
function createDeleteRecord(deleteMutationString, refetch, snackbar) {
  return GQLMutationAction({
    mutationString: deleteMutationString,
    onCompleted: (response) => {
      refetch();
      snackbar("Deleted Record", "success");
    },
  });
}
function createUpdateRecord(updateMutationString, refetch, snackbar) {
  return singleMutation({
    mutationString: updateMutationString,
    onCompleted: (response) => {
      refetch();
      snackbar("Updated Record", "success");
    },
  });
}
// function orderColumns(inputs, inputOrder){
//     // Initialize empty array at length of inputs
//     let orderedInputs = new Array(inputs.length);
//     let unOrderedInputs = []
//     inputs.forEach((input) => {
//       if(inputOrder && inputOrder[input.name]) orderedInputs[inputOrder[input.name].order] = input;
//       else unOrderedInputs.push(input)
//     })
//     return [...orderedInputs, ...unOrderedInputs]
//   }

export default function GQLTable({
  queryString,
  rowOrder,
  queryVariables,
  handleRowOrderChange,
  addMutationString,
  addMutationVariables,
  deleteMutationString,
  updateMutationString,
  restrictedColumns,
  columnOptions,
  columnOrder,
  onRowClick,
  shouldRefetch,
  setShouldRefetch,
  updateModal,
  customActions
}) {
  const { enqueueSnackbar } = useSnackbar();
  const apiRef = useGridApiRef();
  const snackbar = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [selectionRecord, setSelectionRecord] = React.useState([]);
  const [confirmation, setConfirmation] = React.useState({ open: false });
  const closeConfirmation = () => {
    setConfirmation({ open: false });
  };
  const [addRecordModalOpen, setAddRecordModalOpen] = React.useState(false);
  const openAddRecordModal = () => {
    setAddRecordModalOpen(true);
  };
  const closeAddRecordModal = () => {
    setAddRecordModalOpen(false);
  };
  const [updateRecordModalOpen, setUpdateRecordModalOpen] =
    React.useState(false);
  const openUpdateRecordModal = () => {
    setUpdateRecordModalOpen(true);
  };
  const closeUpdateRecordModal = () => {
    setUpdateRecordModalOpen(false);
  };
  const schema = useContext(SchemaContext);
  const query = schema.queries[queryString];
  const queryInputs = getQueryInputs(query, schema);
  const queryReturn = getQueryReturn(query, schema);
  const queryDocument = generateQuery(queryInputs, queryReturn, queryString);
  const addRecord = addMutationString ? openAddRecordModal : null;
  const updateRecordbyModal = updateMutationString
    ? openUpdateRecordModal
    : null;
  const { loading, error, data, refetch } = useQuery(queryDocument, {
    fetchPolicy: 'network-only',
    variables: queryVariables,
  });
  const deleteRecords = deleteMutationString
    ? createDeleteRecord(deleteMutationString, refetch, snackbar)
    : null;
  const updateRecord = updateMutationString
    ? createUpdateRecord(updateMutationString, refetch, snackbar)
    : null;
  const processRowUpdate = React.useCallback(
    async (newRow) => {
      updateRecord(newRow);
    },
    [updateRecord]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => { }, []);
  const handleEvent = onRowClick
    ? (
      params // GridRowParams
    ) => {
      onRowClick(params.row);
    }
    : () => { };

  if (loading) return <CircularProgress />
  if (error) {
    return <div>{`${error}`}</div>;
  }
  if (shouldRefetch) {
    refetch().then((result) => setShouldRefetch(false));
  }
  if (data) {
    const columns = processColumns(
      queryReturn,
      restrictedColumns,
      columnOptions,
      columnOrder
    );
    const dataRows = data[queryString] || [];
    let rows = dataRows.map((row) => {
      return { ...row, __reorder__: row.label || row.name };
    });
    if (rowOrder) {
      const arrayMap = rows.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue.id]: currentValue,
        }),
        {}
      );
      let orderedRows = rowOrder.map((key) => arrayMap[key]);
      let unorderedRows = rows.filter((item) => !rowOrder.includes(item.id));
      rows = [...orderedRows, ...unorderedRows];
    }
    // Clean any undefined row elements
    rows = rows.filter(Boolean);
    return (
      <div style={{ height: 600, width: "100%" }}>
        {addMutationString &&
          addRecordModal(
            addRecordModalOpen,
            closeAddRecordModal,
            addMutationString,
            refetch,
            snackbar,
            restrictedColumns,
            columnOrder,
            addMutationVariables
          )}
        {updateMutationString &&
          updateModal &&
          selectionModel.length === 1 &&
          updateRecordModal(
            updateRecordModalOpen,
            closeUpdateRecordModal,
            updateMutationString,
            refetch,
            snackbar,
            restrictedColumns,
            columnOrder,
            selectionModel,
            selectionRecord
          )}
        <Confirmation
          confirmation={confirmation}
          handleClose={closeConfirmation}
        />
        <div style={{ paddingBottom: "16px" }}>
          {renderTableActions(
            selectionRecord,
            setConfirmation,
            addRecord,
            deleteRecords,
            updateRecordbyModal,
            customActions
          )}
        </div>
        <div style={{ height: 600, width: "100%" }}>
          <DataGridPremium
            rows={rows}
            rowReordering={!!handleRowOrderChange}
            apiRef={apiRef}
            onRowOrderChange={(rowTransaction) => {
              handleRowOrderChange(rows, rowTransaction, refetch);
            }}
            columns={columns}
            pageSize={5}
            pageSizeOptions={[50]}
            checkboxSelection
            disableRowSelectionOnClick
            onRowClick={handleEvent}
            onRowSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = rows.filter((row) =>
                selectedIDs.has(row.id)
              );
              setSelectionRecord(selectedRowData);
              setSelectionModel(ids);
            }}
            rowSelectionModel={selectionModel}
            experimentalFeatures={{ newEditingApi: true }}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          />
        </div>
      </div>
    );
  }
}
