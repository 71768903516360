// material
import { useState, useCallback } from 'react';
import {
    useGridApiRef,
    DataGridPremium
} from "@mui/x-data-grid-premium";
import { Grid, Container, Button, Icon } from '@mui/material';
import plusSquareOutline from "@iconify/icons-eva/plus-square-outline";
// components
import Page from '../../../components/Page';
// ----------------------------------------------------------------------
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import Confirmation from "../../../components/modals/confirmation";

const CREATE_PROJECT_PERSONS = gql`
    mutation createProjectPersons($projectPersons: [InputProjectPersonParams]) {
        createProjectPersons(projectPersons: $projectPersons) {
                id
                personId
                project {
                    id
                    name
                }
                fieldReady
        }
    }
`
const UPDATE_PROJECT_PERSON = gql`
    mutation updateProjectPerson($projectPersons: InputProjectPersonParams) {
        updateProjectPerson(projectPerson: $projectPerson) {
                id
                personId
                project {
                    id
                    name
                }
                fieldReady
        }
    }
`

const GET_PERSONS = gql`
query Persons($ids: [ID]) {
  personsByIds(ids: $ids) {
    id
    avatar
    email
    firmId
    firstName
    lastName
    mailingAddressId
    phone1
    phone2
    preferences
    userId
  }}
`;
const GET_PROJECT_PERSONS = gql`
query ProjectPersons($projectId: ID) {
  projectPersons(projectId: $projectId) {
    id
    personId
    project {
        id
        name
    }
    fieldReady
  }
}
`
const GET_ROLES = gql`
query draftRolesByEntityIds($entityIds: [ID]) {
  draftRolesByEntityIds(entityIds: $entityIds) {
    id
    entityId
    roleType {
        id
        name
    }
  }
}
`

const GET_PROJECT_ROLES = gql`
query ProjectROles($projectId: ID) {
  projectRoles(projectId: $projectId) {
    id
    roleId
    project {
        id
        name
    }
  }
}
`

function renderTableActions(
    project,
    selectionModel,
    setConfirmation,
    addPeopleToProject
) {
    return (
        <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={4}
        >
            <Grid item>
                <h4>Actions: </h4>
            </Grid>
            {selectionModel.length > 0 && (
                <Grid item>
                    <Button
                        onClick={() => {
                            setConfirmation({
                                open: true,
                                action: () => {
                                    const projectPersons = selectionModel.map((model) => ({ projectId: project.id, personId: model.personId }))
                                    addPeopleToProject({ variables: { projectPersons } })
                                },
                                actionText: `Add ${selectionModel.length} to Project`,
                            });
                        }}
                        variant="outlined"
                        startIcon={<Icon icon={plusSquareOutline} />}
                    >
                        {`Add ${selectionModel.length} to Project`}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
}

export default function ProjectPeople({ project, subProjectPersons, refetchSubProjectPersons }) {
    const apiRef = useGridApiRef();
    const [persons, setPersons] = useState([]);
    const [personsRoles, setPersonsRoles] = useState([]);
    const [projectRoles, setProjectRoles] = useState([]);
    const [selectionRecord, setSelectionRecord] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [confirmation, setConfirmation] = useState({ open: false });
    const closeConfirmation = () => {
        setConfirmation({ open: false });
    };
    let merged = [];
    let rows = [];
    const [getPersons] = useLazyQuery(GET_PERSONS, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setPersons(response.personsByIds)
        }
    });
    const [getPersonsRoles] = useLazyQuery(GET_ROLES, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setPersonsRoles(response.draftRolesByEntityIds)
        },
        onError: (response) => {
        }
    });
    const { loading, error, data, refetch } = useQuery(GET_PROJECT_PERSONS, {
        variables: { projectId: project.parentProject.id },
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            getProjectRoles({ variables: { projectId: project.id } })
            getPersons({ variables: { ids: response.projectPersons.map((person) => person.personId) } })
            getPersonsRoles({ variables: { entityIds: response.projectPersons.map((person) => person.personId) } })
        }
    });
    const [getProjectRoles] = useLazyQuery(GET_PROJECT_ROLES, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setProjectRoles(response.projectRoles)
        }
    });
    const [addPeopleToProject] = useMutation(CREATE_PROJECT_PERSONS,
        {
            onCompleted: (response) => {
                refetch();
                refetchSubProjectPersons();
            }
        }
    )
    const [updateProjectPerson] = useMutation(UPDATE_PROJECT_PERSON)
    const processRowUpdate = useCallback(
        async (newRow) => {
            const projectPerson = { id: newRow.id, fieldReady: newRow.fieldReady }
            updateProjectPerson({ variables: { projectPerson } });
        },
        [updateProjectPerson]
    );
    const personHasRole = (person, projectRoles) => {
        return projectRoles.length === 0 || person.roleIds.some(roleId => projectRoles.some(role => role.roleId === roleId));
    }
    const formatRoles = (roles) => {
        let roleString = ""
        let roleIds = []
        roles.forEach((role) => {
            roleIds.push(role.roleType.id)
            if (!!roleString) {
                roleString = roleString + `, ${role.roleType.name}`
            } else {
                roleString = `${role.roleType.name}`
            }
        })
        return { roles: roleString, roleIds }
    }

    if (!project) { return null }
    if (loading) {
        return null
    }
    if (error) {
        debugger
    }
    if (data && data.projectPersons && (persons && persons.length > 0)) {
        for (let i = 0; i < data.projectPersons.length; i++) {
            merged.push({
                ...(formatRoles(personsRoles.filter((role) => role.entityId === data.projectPersons[i].personId))),
                ...data.projectPersons[i],
                ...(persons.find((itmInner) => itmInner.id === data.projectPersons[i].personId)),
            }
            );
        }
        rows = merged.filter((el) => {
            return !subProjectPersons.some((f) => {
                return f.personId === el.personId
            }) && personHasRole(el, projectRoles);
        });
    }

    // Filter Merged and SubProjectPeople to only show merged array without SubProjectPeopel


    return (
        <Page title="Projects | FINBACK670">
            <Container maxWidth="xl">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Confirmation
                            confirmation={confirmation}
                            handleClose={closeConfirmation}
                        />
                        <div style={{ paddingBottom: "16px" }}>
                            {renderTableActions(
                                project,
                                selectionRecord,
                                setConfirmation,
                                addPeopleToProject
                            )}
                        </div>
                        <div style={{ height: 600, width: "100%" }}>
                            <DataGridPremium
                                rows={rows}
                                apiRef={apiRef}
                                checkboxSelection={true}
                                columns={[
                                    { field: 'firstName', headerName: "First Name" },
                                    { field: 'lastName', headerName: "Last Name" },
                                    { field: 'email', headerName: "Email" },
                                    { field: 'phone1', headerName: "Contact Phone" },
                                    { field: 'projectId', headerName: "Project" },
                                    { field: 'roles', headerName: "Roles" },
                                    { field: 'id', headerName: "ID" },
                                ]}
                                pageSize={5}
                                onRowSelectionModelChange={(ids) => {
                                    const selectedIDs = new Set(ids);
                                    const selectedRowData = merged.filter((row) =>
                                        selectedIDs.has(row.id)
                                    );
                                    setSelectionRecord(selectedRowData);
                                    setSelectionModel(ids);
                                }}
                                rowSelectionModel={selectionModel}
                                pageSizeOptions={[50]}
                                experimentalFeatures={{ newEditingApi: true }}
                                processRowUpdate={processRowUpdate}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
