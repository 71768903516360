import * as React from 'react';
import { useContext } from "react";
import { ProjectContext } from "../../utils/contexts/project";
// material
import { Box, Container, Tab, Tabs } from '@mui/material';
// components
import NewRequest from './newRequest';
import Page from '../../components/Page';
import GQLTable from '../../utils/gql-mui-rhf/Table';
import { useNavigate } from "react-router-dom";
// ----------------------------------------------------------------------
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Pending() {
    const navigate = useNavigate()
    const [value, setValue] = React.useState(0);
    let projectContext = useContext(ProjectContext);
    const { project } = projectContext;
    if (!project) {
        return <div>Select Project</div>
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Page title="Dashboard | FINBACK670">
            <Container maxWidth="xl">
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Personnel Credentials" {...a11yProps(0)} />
                    <Tab label="Create Request" {...a11yProps(1)} />
                    <Tab label="Pending Requests" {...a11yProps(2)} />
                    <Tab label="Review Requests" {...a11yProps(3)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <GQLTable
                        queryString={'credentialsRequests'}
                        queryVariables={{ "active": true, "projectId": project.id, "entity": "PERSON", "status": "APPROVED" }}
                        columnOptions={{ category: { width: 150 } }}
                        columnOrder={['name', 'module']}
                        restrictedColumns={{ id: true, name: true, active: true, entityId: true, draftCredentials: true }}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <NewRequest setValue={setValue} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <GQLTable
                        queryString={'credentialsRequests'}
                        queryVariables={{ "active": true, "projectId": project.id, "entity": "PERSON", "status": "PENDING" }}
                        deleteMutationString={'deleteCredentialsRequest'}
                        columnOptions={{ category: { width: 150 } }}
                        columnOrder={['name', 'module']}
                        restrictedColumns={{ id: true, name: true, active: true, entityId: true, draftCredentials: true }}
                        onRowClick={(rowData) => {
                            navigate(`/app/project/credential/request/${rowData.id}`)
                        }}
                    />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <GQLTable
                        queryString={'credentialsRequests'}
                        queryVariables={{ "active": true, "projectId": project.id, "entity": "PERSON", "status": "SUBMITTED" }}
                        // deleteMutationString={'deleteCredentialsRequest'}
                        columnOptions={{ category: { width: 150 } }}
                        columnOrder={['name', 'module']}
                        restrictedColumns={{ id: true, name: true, active: true, entityId: true, draftCredentials: true }}
                        onRowClick={(rowData) => {
                            navigate(`/app/project/credential/review/${rowData.id}`)
                        }}
                    />
                </TabPanel>
            </Container>
        </Page>
    );
}
