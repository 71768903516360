import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Grid, Button, FormGroup, FormControlLabel } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { TextField, Switch, DatePicker } from '../../utils/mui-rhf';
import FileUpload from './FileUpload'

const renderField = (credentialTypeValue, activeField, fieldStatus, setValue, defaultValues) => {
    const disabled = credentialTypeValue.id !== activeField || fieldStatus === "submitted"
    switch (credentialTypeValue.type) {
        case "BOOLEAN":
            return (
                <FormGroup>
                    <FormControlLabel name={credentialTypeValue.name} disabled={disabled} control={<Switch defaultChecked />} label={credentialTypeValue.label ? credentialTypeValue.label : credentialTypeValue.name} />
                </FormGroup>
            )
        case "IMAGE":
            return <FileUpload disabled={disabled} credentialTypeValue={credentialTypeValue} setValue={setValue} defaultValues={defaultValues} />
        case "PDF":
            return <FileUpload disabled={disabled} credentialTypeValue={credentialTypeValue} setValue={setValue} defaultValues={defaultValues} />
        case "TEXT":
            return <TextField fullWidth disabled={disabled} variant={disabled ? "filled" : "outlined"} id="outlined-basic" name={credentialTypeValue.name} label={credentialTypeValue.label ? credentialTypeValue.label : credentialTypeValue.name} placeholder={credentialTypeValue.placeholder} />
        case "INTEGER":
            return <TextField fullWidth type="number" disabled={disabled} variant={disabled ? "filled" : "outlined"} id="outlined-basic" name={credentialTypeValue.name} label={credentialTypeValue.label ? credentialTypeValue.label : credentialTypeValue.name} placeholder={credentialTypeValue.placeholder} />
        case "DECIMAL":
            return <TextField fullWidth type="number" disabled={disabled} variant={disabled ? "filled" : "outlined"} id="outlined-basic" name={credentialTypeValue.name} label={credentialTypeValue.label ? credentialTypeValue.label : credentialTypeValue.name} placeholder={credentialTypeValue.placeholder} />
        case "DATE":
            return <DatePicker fullWidth disabled={disabled} variant={disabled ? "filled" : "outlined"} id="outlined-basic" name={credentialTypeValue.name} label={credentialTypeValue.label ? credentialTypeValue.label : credentialTypeValue.name} placeholder={credentialTypeValue.placeholder} />
        default:
            return <TextField fullWidth disabled={disabled} variant={disabled ? "filled" : "outlined"} id="outlined-basic" name={credentialTypeValue.name} label={credentialTypeValue.label ? credentialTypeValue.label : credentialTypeValue.name} placeholder={credentialTypeValue.placeholder} />

    }
}

const getMutationVariables = (credentialTypeValue, data) => {
    switch (credentialTypeValue.type) {
        case "BOOLEAN":
            return ({ booleanValue: data[credentialTypeValue.name], type: credentialTypeValue.type })
        case "TEXT":
            return ({ stringValue: data[credentialTypeValue.name], type: credentialTypeValue.type })
        case "INTEGER":
            return ({ integerValue: parseInt(data[credentialTypeValue.name]), type: credentialTypeValue.type })
        case "DECIMAL":
            return ({ decimalValue: data[credentialTypeValue.name], type: credentialTypeValue.type })
        case "DATE":
            return ({ dateValue: data[credentialTypeValue.name], type: credentialTypeValue.type })
        case "IMAGE":
            return ({ stringValue: data[credentialTypeValue.name], type: credentialTypeValue.type })
        case "PDF":
            return ({ stringValue: data[credentialTypeValue.name], type: credentialTypeValue.type })
        default:
            return ({ stringValue: data[credentialTypeValue.name], type: credentialTypeValue.type })
    }
}


const FieldGroup = ({ defaultValues, fieldStatus, credentialTypeValue, draftCredential, activeField, setActiveField, addDraftCredentialValue }) => {
    const methods = useForm({ defaultValues });
    const { handleSubmit, setValue } = methods;
    const onSubmit = (data) => {
        let variables = getMutationVariables(credentialTypeValue, data)

        if (variables.type === "INTEGER") {
            variables.integerValue = parseInt(variables.integerValue)
        }
        if (variables.type === "DECIMAL") {
            variables.decimalValue = parseFloat(variables.decimalValue)
        }
        addDraftCredentialValue({ ...variables, type: credentialTypeValue.type, credentialTypeValueId: credentialTypeValue.id, draftCredentialId: draftCredential.id })
        setActiveField(null);
    };
    const editDisabled = activeField !== null || fieldStatus === 'submitted'
    const saveDisabled = credentialTypeValue.id !== activeField || fieldStatus === 'submitted'
    return (
        <Grid item>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid key={credentialTypeValue.id} spacing={2} container direction={"row"} justifyContent={'flex-start'} alignItems={'center'}>
                        <Grid width="200px" item>
                            {`${credentialTypeValue.label ? credentialTypeValue.label : credentialTypeValue.name}${credentialTypeValue.required ? "*" : ""}:`}
                        </Grid>
                        <Grid width="400px" item>
                            {renderField(credentialTypeValue, activeField, fieldStatus, setValue, defaultValues)}
                        </Grid>
                        <Grid item >
                            <Button disabled={editDisabled} onClick={() => {
                                setActiveField(credentialTypeValue.id)
                            }} variant="outlined" startIcon={<EditIcon />}>
                                Edit
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button disabled={saveDisabled} type={"submit"} variant="outlined" startIcon={<SaveIcon />}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </Grid>
    )
}
export default FieldGroup