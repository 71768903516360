// material
import { Box, Grid, Container, Typography } from "@mui/material";
import { GQLForm } from "src/utils/gql-mui-rhf/FormProvider";
// components
import Page from "../components/Page";

// ----------------------------------------------------------------------
export default function Forms() {
  return (
    <Page title="Dashboard | FINBACK670">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Forms UI Examples</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <GQLForm mutationString="createFirm" />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
