import TextField from "../../utils/mui-rhf/TextField";
import Address from "../../utils/mui-rhf/Address";
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from "react-hook-form";
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const CREATE_PROJECT = gql`
mutation createProject($project: InputProjectParams) {
    createProject(project: $project) {
      projectLocation {
        id
      }
      id
    }
  }
`


export default function Forms({ open, handleClose, project, projectId = null, projectOwner, refetch }) {
    const { enqueueSnackbar } = useSnackbar();
    const snackbar = (message, variant) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(message, { variant });
    };
    const methods = useForm({ ...project });
    const { handleSubmit } = methods;
    const [createProject] = useMutation(CREATE_PROJECT, {
        onCompleted: () => {
            snackbar('Project Created', 'success');
            refetch();
            handleClose();
        }
    })


    const onSubmit = (values) => {
        
        debugger
        createProject({variables: {
            project: {
                projectId: projectId,
                projectOwner: projectOwner,
                ...values,
                numberOfUnits: isNaN(parseInt(values.numberOfUnits)) ? null : parseInt(values.numberOfUnits)
            }
        }})
    }
    return (
        <Dialog
            open={open}
            onClose={() => {
                methods.reset()
                handleClose()
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`Create New Project`}
            </DialogTitle>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <div style={{ width: "100%" }}>
                            <Grid container spacing={2} padding={1}>
                                <Grid item spacing={2} xs={12} >
                                    <TextField key={1} fullWidth rules={{ required: "Required Field" }} label={"Project Name"} name={"name"} />
                                </Grid>
                                <Grid item spacing={2} xs={12} >
                                    <TextField key={2} fullWidth rules={{ required: "Required Field" }} label={"Project Number"} name={"projectNumber"} />
                                </Grid>
                                <Grid item spacing={2} xs={12} >
                                    <Address
                                        methods={methods}
                                        group={'projectLocation.'}
                                        initialConfig={{ type: 'create' }}
                                        rules={
                                            {
                                                city: { required: "Required Field" },
                                                state: { required: "Required Field" }
                                            }
                                        }
                                    />
                                </Grid>
                                <Grid item spacing={2} xs={12} >
                                    <TextField key={4} fullWidth label={"Number of Units"} name={"numberOfUnits"} />
                                </Grid>
                                <Grid item spacing={2} xs={12} >
                                    <TextField key={5} fullWidth label={"Public Private Partnership"} name={"publicPrivatePartnership"} />
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" type="submit">
                            {"Submit"}
                        </Button>
                        <Button onClick={() => {
                            methods.reset()
                            handleClose()
                        }}>Cancel</Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
}