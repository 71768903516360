import { gql } from "@apollo/client";
import { dec2bin } from "src/utils/decimalToBinary";

export const getWashoutQueryComponents = (inspectionIds: string[]) => {
  let variables: any = {};
  const queryParams = inspectionIds
    .map((id, i) => `$${dec2bin(i, true)}: InputWashoutParams!`)
    .join(", ");
  const aliasedQueries = () => {
    let queryArray: string[] = [];
    inspectionIds.forEach((id, i) => {
      const binaryChar = dec2bin(i, true);
      variables[binaryChar] = { inspectionId: id };
      queryArray.push(`${binaryChar}: washouts(washout: $${binaryChar}) {
          id
        }
        `);
    });
    return queryArray.join();
  };

  const queryString = `
      query Washouts(${queryParams}) {
        ${aliasedQueries()}
      }
    `;
  return [gql(queryString), variables];
};
