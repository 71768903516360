import * as React from 'react';

//gql-mui-rhf
import Profile from './Profile';
import Credentials from './Credentials';
import Roles from './Roles';
// material
import { Box, Container, Tabs, Tab } from '@mui/material';
// components
import Page from '../../components/Page';
import {PersonContext} from '../../utils/contexts/person';

// ----------------------------------------------------------------------

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function UserView() {
    const [value, setValue] = React.useState(0);
    let {person} = React.useContext(PersonContext);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
        <Page title="Profile | FINBACK670">
            <Container maxWidth="xl" style={{ paddingBottom: '64pt' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Profile" {...a11yProps(0)} />
                        <Tab label="Roles" {...a11yProps(1)} />
                        <Tab label="Credentials" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Profile person={person}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Roles person={person}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Credentials person={person}/>
                </TabPanel>
            </Container>
        </Page >
    );
}
