import {
  Avatar,
  FormControl,
  InputLabel,
  ListItemAvatar,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";

export default function FirmUnitSelect({firmUnits, firmUnit, setFirmUnit}) {
  const handleChange = (e) => {
    setFirmUnit(e.target.value);
  };
  return (
    <FormControl sx={{ width: "12rem", marginRight: "1rem" }}>
      <InputLabel>Firm Unit</InputLabel>
      <Select
        labelId="firmUnit-label"
        id="firmUnit-select"
        value={firmUnit}
        size="small"
        onChange={handleChange}
        input={<OutlinedInput label="FirmUnit" />}
      >
        {firmUnits.map((firmUnit) => (
          <MenuItem value={firmUnit.id} key={firmUnit.id}>
            <Stack direction={"row"} alignItems="center">
              <ListItemAvatar>
                <Avatar
                  alt={`${firmUnit.name}`}
                  src={`${firmUnit.thumbnail}`}
                />
              </ListItemAvatar>
              <Typography>{firmUnit.name}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
