import { Action, PageState } from "../../TypeDefinitions";

export function deleteField<
  E extends Extract<
    Action,
    {
      type: "deleteField";
    }
  >
>(action: E, draft: PageState): void {
  const { fieldId } = action.payload;
  const formId = draft.formId;
  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => formId === form.id
    );

  if (formIndex === -1) return;

  const fieldIndex = draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.findIndex((field) => field.id === fieldId);

  draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.splice(fieldIndex, 1);
  draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.forEach((field, i) => (field.displayIndex = i));
  if (draft.editingFieldId === fieldId) {
    draft.editingFieldId = null;
  }
  draft.deleteConfig = null;

  let filteredValidations =
    draft.data.inspectionTypeTemplate.configuration.forms[
      formIndex
    ].validations.filter((validation) => validation.field !== fieldId);

  draft.data.inspectionTypeTemplate.configuration.forms[formIndex].validations =
    filteredValidations;

  draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].validations.forEach((validation) =>
    validation.expressions.forEach((expression) => {
      if (expression.fieldToCompare === fieldId)
        expression.fieldToCompare = null;
    })
  );
}
