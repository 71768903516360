import SchemaContext from "./SchemaContext";
import { useContext } from "react";
import { useQuery } from "@apollo/client";
import { getQueryInputs, getQueryReturn, generateQuery } from './QueryHelpers';

const GQLQueryAction = (queryString, queryVariables) => {
  const schema = useContext(SchemaContext);
  const query = schema.queries[queryString];
  if(!query) throw Error('Undefined Query')
  const queryInputs = getQueryInputs(query, schema);
  const queryReturn = getQueryReturn(query, schema);
  const queryDocument = generateQuery(queryInputs, queryReturn, queryString)
  return useQuery(queryDocument, {
    variables: queryVariables,
    fetchPolicy: 'network-only'
  });
};

export default GQLQueryAction;
