import * as React from 'react';
import { useContext } from "react";
import { ProjectContext } from "../../utils/contexts/project";
// material
import { Box, Container, Tab, Tabs, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import GQLTable from '../../utils/gql-mui-rhf/Table';
import { useNavigate } from "react-router-dom";
// ----------------------------------------------------------------------
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Review() {
  const navigate = useNavigate()
  const [value, setValue] = React.useState(0);
  let projectContext = useContext(ProjectContext);
  const { project } = projectContext;
  if(!project){
    return <div>Select Project</div>
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Page title="Dashboard | FINBACK670">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Onboarding Requests</Typography>
        </Box>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Personnel Requests" {...a11yProps(0)} />
          <Tab label="Firm Requests" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <GQLTable
            queryString={'rolesRequests'}
            queryVariables={{ "projectId": project.id, "status": "SUBMITTED", "active": true, "entity": "PERSON" }}
            deleteMutationString={'deleteRolesRequest'}
            columnOptions={{ category: { width: 150 } }}
            columnOrder={['name', 'module']}
            restrictedColumns={{ 
              id: true, 
              active: true, 
              entityId: true, 
              draftRoles: true ,
              projectId: true,
              projectFirmId: true,
              projectFirmUnitId: true,
              backgroundCheckSsn: true,
              draftCredentials: true,
              backgroundCheckLink: true,
              backgroundCheckApplicant: true, 
              backgroundCheckOrder: true
            }}
            onRowClick={(rowData) => {
              navigate(`/app/team/onboarding/request-review/${rowData.id}`)
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <GQLTable
            queryString={'rolesRequests'}
            queryVariables={{ "projectId": project.id, "status": "SUBMITTED", "active": true, "entity": "FIRM" }}
            deleteMutationString={'deleteRolesRequest'}
            columnOptions={{ category: { width: 150 } }}
            columnOrder={['name', 'module']}
            restrictedColumns={{ 
              id: true, 
              active: true, 
              entityId: true, 
              draftRoles: true ,
              projectId: true,
              projectFirmId: true,
              projectFirmUnitId: true,
              backgroundCheckSsn: true,
              draftCredentials: true,
              backgroundCheckLink: true,
              backgroundCheckApplicant: true, 
              backgroundCheckOrder: true
            }}
            onRowClick={(rowData) => {
              navigate(`/app/team/onboarding/request-review/${rowData.id}`)
            }}
          />
        </TabPanel>
      </Container>
    </Page>
  );
}
