import { Action, PageState } from "../../TypeDefinitions";

export function toggleDelete<
  E extends Extract<
    Action,
    {
      type: "toggleDelete";
    }
  >
>(action: E, draft: PageState): void {
  draft.deleteConfig = action.payload;
}
