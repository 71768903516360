import { IFormJson } from "../..";
import { Buffer } from "buffer";
const pako = require("pako");

export const encode = (formJson: IFormJson) => {
  const stringified = JSON.stringify(formJson);
  const stringArray = new TextEncoder().encode(stringified);
  const zippedArray: Uint8Array = pako.gzip(stringArray);
  const zipped64String = Buffer.from(zippedArray.buffer).toString("base64");

  return zipped64String;
};
