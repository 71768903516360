import { Route, Routes } from "react-router-dom";
// components
import InspectionAssignment from "./pages/InspectionAssignment";
import InspectionAssignments from "./pages/InspectionAssignments";
import ExpandedDisplayView from "./pages/InspectionAssignment/components/ExpandedDisplayView";

// ----------------------------------------------------------------------

export default function Review() {
  return (
    <Routes>
      <Route path="/" element={<InspectionAssignments />} />
      <Route path="/:inspectionId" element={<InspectionAssignment />} />
      <Route
        path="/:inspectionId/:assignmentId/:inspectionTypeId/expanded"
        element={<ExpandedDisplayView />}
      />
      {/* <Route
        path="/:inspectionTypeId/:inspectionTypeTemplateId/*"
        element={<Test />}
      /> */}
      {/* <Route path="/:inspectionTypeId" element={<InspectionTypePage />} />;
      <Route path="/create" element={<CreateInspectionType />} /> */}
    </Routes>
  );
}
