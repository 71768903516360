import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField,
    Select
} from '@mui/material';

export default function EventHover({ hoverOpen, handleClose, hoverEvent }) {
    return (
        <Dialog open={hoverOpen} onClose={handleClose}>
            <DialogTitle>Event</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container spacing={2} padding={1}>
                        <Grid item spacing={2} xs={12} >
                            <TextField disabled={true} key={1} fullWidth label={"Street 1"} name={"street1"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} >
                            <TextField disabled={true} key={1} fullWidth label={"Street 2"} name={"street2"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} >
                            <TextField disabled={true} key={1} fullWidth label={"Street 3"} name={"street3"} />
                        </Grid>
                        <Grid item spacing={2} xs={4} >
                            <TextField disabled={true} key={2} fullWidth label={"City"} name={"city"} />
                        </Grid>
                        <Grid item spacing={2} xs={4} >
                            <TextField disabled={true} key={3} fullWidth label={"State"} name={"state"} />
                        </Grid>
                        <Grid item spacing={2} xs={4} >
                            <TextField disabled={true} key={3} fullWidth label={"Zip"} name={"zip"} />
                        </Grid>
                        <Grid item spacing={2} xs={12}>
                            <Select disabled={true} options={[]} fullWidth label={"Inspector"} name={"personId"} rules={{ required: true }} />
                        </Grid>
                        <Grid item spacing={2} xs={12}>
                            <TextField disabled={true} fullWidth label={"Start"} name={"start"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} style={{ marginBottom: 32 }}>
                            <TextField disabled={true} fullWidth label={"End"} name={"end"}/>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}