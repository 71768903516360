import { useForm, FormProvider } from 'react-hook-form';
import { Grid, Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import CheckIcon from '@mui/icons-material/Check';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { TextField } from '../../../utils/mui-rhf';
import { GQLMutationAction } from '../../../utils/gql-mui-rhf/SingleMutation';

const processSubmitEmail = (rolesRequest, handleCreateEmail) => {
    let variables = { data: {} }
    variables["templateId"] = "d-abc714f9da754bc1a421d6f10ee74b0a";
    variables["to"] = rolesRequest.email;
    variables["data"]["url"] = rolesRequest.backgroundCheckLink;
    if (rolesRequest.entity === "PERSON")
      variables["data"]["name"] = `${rolesRequest.firstName} ${rolesRequest.lastName}`;
    else
      variables["data"]["name"] = rolesRequest.name;
    variables["data"] = JSON.stringify(variables.data)
    debugger
    handleCreateEmail(variables)
}

function submitBackgroundCheck(refetch, snackbar, handleCreateEmail) {
    return GQLMutationAction({
        mutationString: "submitBackgroundCheck",
        onCompleted: (response) => {
            snackbar('Submitted Background Information', 'success');
            debugger
            processSubmitEmail(response.submitBackgroundCheck, handleCreateEmail)
            refetch();
        }
    })
}

function submitCreateEmail() {
    return GQLMutationAction({
      mutationString: "createEmail",
      onCompleted: (response) => {
      }
    })
  }

function isValidSSN(value, formValues) {
    const ssnRegex = /^\d{3}-\d{2}-\d{4}$/;
    return ssnRegex.test(value);
}

const BackgroundCheck = ({ rolesRequest, refetchRolesRequest }) => {
    const methods = useForm({ defaultValues: { ...rolesRequest } });
    const { enqueueSnackbar } = useSnackbar();
    const snackbar = (message, variant) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(message, { variant });
    };
    const handleCreateEmail = submitCreateEmail();
    const handleSubmitBackgroundCheck = submitBackgroundCheck(refetchRolesRequest, snackbar, handleCreateEmail);

    const { handleSubmit } = methods;

    const onSubmit = (data) => {
        debugger
        handleSubmitBackgroundCheck({
            ...rolesRequest,
            ...data
        })
    };
    const handleButtonClick = (backgroundCheckLink) => {
        window.open(backgroundCheckLink, '_blank');
      };
    return (
        <Grid item style={{ marginBottom: 16 }}>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Typography variant="h5">Background Check</Typography>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>Please Review the Accuracy of Information Previously Collected</Typography>
                        </Grid>
                        <Grid spacing={2} container direction={"row"} justifyContent={'flex-start'} alignItems={'center'}>
                            <Grid width="200px" item>
                                {`First Name:`}
                            </Grid>
                            <Grid width="400px" item>
                                <TextField
                                    fullWidth
                                    disabled={rolesRequest.backgroundCheckOrder}
                                    variant={true ? "filled" : "outlined"}
                                    id="outlined-basic"
                                    name="firstName"
                                    label="First Name"
                                    placeholder="First Name"
                                />
                            </Grid>
                        </Grid>
                        <Grid spacing={2} container direction={"row"} justifyContent={'flex-start'} alignItems={'center'}>
                            <Grid width="200px" item>
                                {`Middle Name:`}
                            </Grid>
                            <Grid width="400px" item>
                                <TextField
                                    fullWidth
                                    disabled={rolesRequest.backgroundCheckOrder}
                                    variant={true ? "filled" : "outlined"}
                                    id="outlined-basic"
                                    name="middleName"
                                    label="Middle Name"
                                    placeholder="Middle Name"
                                />
                            </Grid>
                        </Grid>
                        <Grid spacing={2} container direction={"row"} justifyContent={'flex-start'} alignItems={'center'}>
                            <Grid width="200px" item>
                                {`Last Name:`}
                            </Grid>
                            <Grid width="400px" item>
                                <TextField
                                    fullWidth
                                    disabled={rolesRequest.backgroundCheckOrder}
                                    variant={true ? "filled" : "outlined"}
                                    id="outlined-basic"
                                    name="lastName"
                                    label="Last Name"
                                    placeholder="Last Name"
                                />
                            </Grid>
                        </Grid>
                        <Grid spacing={2} container direction={"row"} justifyContent={'flex-start'} alignItems={'center'}>
                            <Grid width="200px" item>
                                {`SSN:`}
                            </Grid>
                            <Grid width="400px" item>
                                <TextField
                                    fullWidth
                                    disabled={rolesRequest.backgroundCheckOrder}
                                    variant={true ? "filled" : "outlined"}
                                    id="outlined-basic"
                                    name="backgroundCheckSsn"
                                    label="SSN (xxx-xx-xxxx)"
                                    placeholder="SSN"
                                    rules={{ validate: isValidSSN }}
                                />
                            </Grid>

                        </Grid>
                        {
                            rolesRequest.backgroundCheckOrder
                                ?
                                <Grid item xs={12} style={{ marginBottom: 8 }}>
                                    <Typography>A background check has been submitted and is in the process of being completed.</Typography>
                                </Grid>
                                :
                                <Grid item xs={12} style={{ marginBottom: 8 }}>
                                    <Typography>Client hereby certifies that this order shall be made for the following permissble purpose under the Fair Credit Reporting Act only:</Typography>
                                    <Typography>For "employment purposes" but only upon the express written consent of any person that will be screened.</Typography>
                                </Grid>
                        }
                        <Grid item>
                            {
                                rolesRequest.backgroundCheckOrder
                                    ?
                                    <Button onClick={()=>{handleButtonClick(rolesRequest.backgroundCheckLink)}} disabled={false} variant="outlined" startIcon={<AssignmentIcon />}>
                                        {"Complete External Information"}
                                    </Button>
                                    :
                                    <Button disabled={rolesRequest.backgroundCheckOrder} type={"submit"} variant="outlined" startIcon={<CheckIcon />}>
                                        {"Agree and Submit"}
                                    </Button>
                            }
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </Grid>
    )
}
export default BackgroundCheck