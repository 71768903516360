import { Importer, ImporterField } from 'react-csv-importer';

// include the widget CSS file whichever way your bundler supports it
import 'react-csv-importer/dist/index.css';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const CREATE_PROJECT_LOCATION = gql`
    mutation createProjectLocation($projectLocation: InputProjectLocationParams) {
        createProjectLocation(projectLocation: $projectLocation) {
                id
        }
    }
`

async function createProjectLocations(rows, projectId, createProjectLocation, shouldRefetchTable) {

    for (const row of rows) {
        const query = `${row.street1} ${row.street2} ${row.street3} ${row.city}, ${row.state} ${row.zip}`

        const res = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
                query
            )}.json?types=address&access_token=${process.env.REACT_APP_MAPBOX_TOKEN
            }&proximity=ip`
        ).then((response) => response.json());

        const address = res.features[0]
        const createdProjectLocation = await createProjectLocation({
            variables: {
                projectLocation: {
                    ...row,
                    lng: address.geometry.coordinates[0],
                    lat: address.geometry.coordinates[1],
                    mapboxId: address.id,
                    projectId
                }
            }
        })
        console.log("CREATED: ", createdProjectLocation)
        setTimeout(function() {
            // code to be executed after 0.1 seconds
          }, 100);
    }
    shouldRefetchTable()
}
// in your component code:
export default function Inspection({ shouldRefetchTable, projectId, close }) {
    const [createProjectLocation] = useMutation(CREATE_PROJECT_LOCATION)

    return <Importer
        dataHandler={async (rows, { startIndex }) => { createProjectLocations(rows, projectId, createProjectLocation, shouldRefetchTable) }}
        onClose={({ file, preview, fields, columnFields }) => {
            close(false)
        }}
        defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
    >
        <ImporterField name="name" label="Name" optional />
        <ImporterField name="description" label="Description" optional />
        <ImporterField name="street1" label="Street 1" />
        <ImporterField name="street2" label="Street 2" optional />
        <ImporterField name="street3" label="Street 3" optional />
        <ImporterField name="neighborhood" label="Neighborhood" />
        <ImporterField name="city" label="City" />
        <ImporterField name="state" label="State" />
        <ImporterField name="zip" label="Zip" />
    </Importer>;
}