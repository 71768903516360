import ReactMapGL from 'react-map-gl';

const Map = ({ viewState, setViewState, markers }) => {
    return (

        <ReactMapGL
            {...viewState}
            style={{ width: '100%', height: 400 }}
            onMove={evt => setViewState(evt.viewState)}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN}
        >
            {markers}
        </ReactMapGL>

    );
}
export default Map