import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Grid, Button, FormGroup, FormControlLabel } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { TextField, Switch, DatePicker } from '../../../utils/mui-rhf';
import FileUpload from './FileUpload'

const renderField = (roleTypeValue, activeField, fieldStatus, setValue, defaultValues) => {
    const disabled = roleTypeValue.id !== activeField || fieldStatus === "submitted"
    switch (roleTypeValue.type) {
        case "BOOLEAN":
            return (
                <FormGroup>
                    <FormControlLabel name={roleTypeValue.name} disabled={disabled} control={<Switch defaultChecked />} label={roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name} />
                </FormGroup>
            )
        case "IMAGE":
            return <FileUpload disabled={disabled} roleTypeValue={roleTypeValue} setValue={setValue} defaultValues={defaultValues} />
        case "PDF":
            return <FileUpload disabled={disabled} roleTypeValue={roleTypeValue} setValue={setValue} defaultValues={defaultValues} />
        case "TEXT":
            return <TextField fullWidth disabled={disabled} variant={disabled ? "filled" : "outlined"} id="outlined-basic" name={roleTypeValue.name} label={roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name} placeholder={roleTypeValue.placeholder} />
        case "INTEGER":
            return <TextField fullWidth type="number" disabled={disabled} variant={disabled ? "filled" : "outlined"} id="outlined-basic" name={roleTypeValue.name} label={roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name} placeholder={roleTypeValue.placeholder} />
        case "DECIMAL":
            return <TextField fullWidth type="number" disabled={disabled} variant={disabled ? "filled" : "outlined"} id="outlined-basic" name={roleTypeValue.name} label={roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name} placeholder={roleTypeValue.placeholder} />
        case "DATE":
            return <DatePicker fullWidth disabled={disabled} variant={disabled ? "filled" : "outlined"} id="outlined-basic" name={roleTypeValue.name} label={roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name} placeholder={roleTypeValue.placeholder} />
        default:
            return <TextField fullWidth disabled={disabled} variant={disabled ? "filled" : "outlined"} id="outlined-basic" name={roleTypeValue.name} label={roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name} placeholder={roleTypeValue.placeholder} />

    }
}

const getMutationVariables = (roleTypeValue, data) => {
    switch (roleTypeValue.type) {
        case "BOOLEAN":
            return ({ booleanValue: data[roleTypeValue.name], type: roleTypeValue.type })
        case "TEXT":
            return ({ stringValue: data[roleTypeValue.name], type: roleTypeValue.type })
        case "INTEGER":
            return ({ integerValue: parseInt(data[roleTypeValue.name]), type: roleTypeValue.type })
        case "DECIMAL":
            return ({ decimalValue: data[roleTypeValue.name], type: roleTypeValue.type })
        case "DATE":
            return ({ dateValue: data[roleTypeValue.name], type: roleTypeValue.type })
        case "IMAGE":
            return ({ stringValue: data[roleTypeValue.name], type: roleTypeValue.type })
        case "PDF":
            return ({ stringValue: data[roleTypeValue.name], type: roleTypeValue.type })
        default:
            return ({ stringValue: data[roleTypeValue.name], type: roleTypeValue.type })
    }
}


const FieldGroup = ({ defaultValues, fieldStatus, roleTypeValue, draftRole, activeField, setActiveField, addDraftRoleValue }) => {
    const methods = useForm({ defaultValues });
    const { handleSubmit, setValue } = methods;
    const onSubmit = (data) => {
        let variables = getMutationVariables(roleTypeValue, data)

        if (variables.type === "INTEGER") {
            variables.integerValue = parseInt(variables.integerValue)
        }
        if (variables.type === "DECIMAL") {
            variables.decimalValue = parseFloat(variables.decimalValue)
        }
        addDraftRoleValue({ ...variables, type: roleTypeValue.type, roleTypeCredentialValueId: roleTypeValue.id, draftCredentialId: draftRole.id })
        setActiveField(null);
    };
    const editDisabled = activeField !== null || fieldStatus === 'submitted'
    const saveDisabled = roleTypeValue.id !== activeField || fieldStatus === 'submitted'
    return (
        <Grid item>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid key={roleTypeValue.id} spacing={2} container direction={"row"} justifyContent={'flex-start'} alignItems={'center'}>
                        <Grid width="200px" item>
                            {`${roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name}${roleTypeValue.required ? "*" : ""}:`}
                        </Grid>
                        <Grid width="400px" item>
                            {renderField(roleTypeValue, activeField, fieldStatus, setValue, defaultValues)}
                        </Grid>
                        <Grid item >
                            <Button disabled={editDisabled} onClick={() => {
                                setActiveField(roleTypeValue.id)
                            }} variant="outlined" startIcon={<EditIcon />}>
                                Edit
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button disabled={saveDisabled} type={"submit"} variant="outlined" startIcon={<SaveIcon />}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </Grid>
    )
}
export default FieldGroup