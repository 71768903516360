// material
import { useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { GQLForm } from '../../utils/gql-mui-rhf/FormProvider';
// components
import Page from '../../components/Page';
import Toolbar from './Toolbar';
// ----------------------------------------------------------------------

const localizer = momentLocalizer(moment);


const GET_PERSON_EVENTS = gql`
  query personEvents($personId: ID!) {
    personEvents(personId: $personId) {
      id
      title
      start
      end
      allDay
    }
  }
`;
const addReminder = (reminderOpen, event, closeReminder) => (
    <Dialog open={reminderOpen} onClose={closeReminder}>
        <DialogContent>
          {/* Render Existing Reminders */}
        <GQLForm mutationString={"createReminder"} style={{zIndex: 1350}} onCompleted={closeReminder}
            defaultValues={{personId: "b2b69393-cdd2-4ad2-b9ef-54c1833fb06b", eventId: event.id, allDay: false}}
            />
        </DialogContent>
    </Dialog>
)
const addEvent = (eventOpen, event, closeEvent, openReminder) => (
        <Dialog open={eventOpen} onClose={closeEvent}>
            <DialogContent>
            <GQLForm mutationString={"updateEvent"} deleteMutationString={"deleteEvent"} deleteId={event.id} onCompleted={()=>{
                closeEvent()
                }}
                skipFields={{id: true, locationId: true, seriesId: true, createdBy: true}}
                defaultValues={event}
                inputOrder={{event: {order: 1, inputOrder: {title: {order: 1}, start: {order: 2}, end: {order: 3}}}}}
                formActions={[{callback: openReminder, text: 'Reminders'}]}
                />
            </DialogContent>
        </Dialog>
)
const formatEvents = (events) => events.map((event) => {
    return {...event, start: new Date(event.start), end: new Date(event.end)}
})
export default function UserCalendar() {
    const [eventOpen, setEventOpen] = useState(false);
    const [reminderOpen, setReminderOpen] = useState(false);
    const closeEvent = () => {
        setEventOpen(false)
        setEvent(null)
    }
    const openReminder = () => setReminderOpen(true);
    const closeReminder = () => setReminderOpen(false);
    const [event, setEvent] = useState(null);

    // TODO: Change reference to authentication object personId
    const {loading, error, data} = useQuery(GET_PERSON_EVENTS, {
        variables: {personId: "b2b69393-cdd2-4ad2-b9ef-54c1833fb06b"}
    });
    if(loading) return 'Loading'
    if(error) return `Error! ${error.message}`
    const events = data ? data.personEvents : [];
    const formattedEvents = formatEvents(events);
    return (
        <Page title="Calendar | FINBACK670">
            {eventOpen && addEvent(eventOpen, event, closeEvent, openReminder)}
            {reminderOpen && addReminder(reminderOpen, event, closeReminder)}
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">Calendar</Typography>
                </Box>
                
                <div>
                    <Calendar
                        localizer={localizer}
                        startAccessor="start"
                        endAccessor="end"
                        events={formattedEvents}
                        style={{ height: 500 }}
                        onSelectEvent={(event)=>{
                            setEvent(event)
                            setEventOpen(true)
                        }}
                        components={{
                            toolbar: Toolbar
                        }}                 
                        views={{
                            day: true,
                            week: true,
                            month: true,
                            agenda: true
                        }}
                    />
                </div>
            </Container>
        </Page>
    );
}
