import { useEffect, useState, useContext } from "react";
import { PersonContext } from "src/utils/contexts/person";
import { useSnackbar } from 'notistack';
import GQLTable from '../../utils/gql-mui-rhf/Table';
import { GQLMutationAction } from "../../utils/gql-mui-rhf/SingleMutation";
import { useNavigate } from "react-router-dom";
import starOutline from "@iconify/icons-eva/star-outline";
import FirmUnitSelect from '../../layouts/dashboard/FirmUnitSelect';

// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
// ----------------------------------------------------------------------
import { gql } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';

const GET_PERSONS_FIRM_UNITS = gql`
query PersonsFirmUnits($personId: ID) {
  personsFirmUnits(personId: $personId) {
    id
    firmUnit {
        id
        firmId
        dba
        domain
        duns
        fax
        fein
        legalAbbreviation
        legalName
        name
        owner
        phone1
        phone2
        website
    }
  }}
`;
function generateMutation(snackbar) {
    return GQLMutationAction({
        mutationString: "createProjectFavorite",
        onCompleted: (response) => {
            snackbar("Favorited Project", "success");
        },
    });
}

export default function Projects(props) {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    let {person} = useContext(PersonContext);
    const snackbar = (message, variant) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(message, { variant });
    };
    let [firmUnits, setFirmUnits] = useState([]);

    const [getPersonsFirmUnits] = useLazyQuery(GET_PERSONS_FIRM_UNITS, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setFirmUnits(response.personsFirmUnits.map((personsFirmUnit) => personsFirmUnit.firmUnit))
        },
        onError: (response) => {
            debugger
        }
    });
    useEffect(
        () => {
            if (person) {
                getPersonsFirmUnits({ variables: {personId: person.id} })
            }
        },
        [person, getPersonsFirmUnits]
    )
    const favoriteProject = generateMutation(snackbar)
    let projectId = props.projectId
    let [firmUnit, setFirmUnit] = useState(null);
    console.log(firmUnit)
    return (
        <Page title="Projects | FINBACK670">
            <Container maxWidth="xl">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FirmUnitSelect firmUnits={firmUnits} firmUnit={firmUnit} setFirmUnit={setFirmUnit} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ pb: 5 }}>
                            <Typography variant="h4">Projects</Typography>
                        </Box>
                    </Grid>
                    {firmUnit &&
                        <Grid item xs={12}>
                            <GQLTable
                                queryString={'projects'}
                                queryVariables={{ "projectOwner": firmUnit, "projectId": projectId }}
                                addMutationVariables={{ "projectOwner": firmUnit, "projectId": projectId }}
                                addMutationString={'createProject'}
                                deleteMutationString={'deleteProject'}
                                updateMutationString={'updateProject'}
                                updateModal={true}
                                restrictedColumns={{ projectOwner: true, createdBy: true, locationId: true, invoiceTemplate: true, popEnd: true, popStart: true, projectId: true, projectLogo: true, end: true, start: true }}
                                onRowClick={(rowData) => {
                                    navigate(`/app/admin/project/${rowData.id}`)
                                }}
                                customActions={[
                                    {
                                        text: "Favorite",
                                        icon: starOutline,
                                        callback: (selectionModel) => {
                                            favoriteProject({
                                                projectId: selectionModel[0].id,
                                                personId: person.id
                                            })
                                        }
                                    }
                                ]}
                            />
                        </Grid>}
                </Grid>
            </Container>
        </Page>
    );
}
