import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from '@mui/material';
import { Add } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogTitle,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Page from "src/components/Page";
import { GQLForm } from "src/utils/gql-mui-rhf/FormProvider";
import { v4 as uuidv4 } from "uuid";
import TemplatesView from "../TemplatesView";

const INSPECTION_TYPE_QUERY = gql(`
  query Query($inspectionType: InputInspectionTypeParams) {
  inspectionType(inspectionType: $inspectionType) {
    description
    id
    inspectionTypeTemplates {
      configuration
      description
      end
      id
      name
      start
    }
    name
    projectId
  }
}
`);

export default function Index() {
  const { inspectionTypeId } = useParams();
  const navigate = useNavigate();
  const [templateConfig, setTemplateConfig] = useState({ open: false });
  const { data, loading, error } = useQuery(INSPECTION_TYPE_QUERY, {
    variables: {
      inspectionType: {
        id: inspectionTypeId,
      },
    },
  });
  const removeTypeName = (returnVal) => {
    let returnValCopy = Object.assign({}, returnVal);
    delete returnValCopy.__typename;
    delete returnValCopy.defaultInspectionType;
    return returnValCopy;
  };

  function configObj() {
    return {
      id: uuidv4(),
      forms: [],
    };
  }

  return (
    <>
      {loading && <CircularProgress />}
      {data && (
        <Page title={data.inspectionType.name}>
          <Breadcrumbs>
            <Link to="..">Inspection Types List</Link>
            <Link to=".">Inspection Type</Link>
          </Breadcrumbs>
          <Box height="0.5rem" />
          <GQLForm
            update
            defaultValues={removeTypeName(data.inspectionType)}
            mutationString="updateInspectionType"
            skipFields={{
              id: true,
              defaultInspectionTypeId: true,
              projectId: true,
            }}
          />
          <Stack minWidth={"100%"} justifyContent="end" direction="row">
            <Button
              variant="outlined"
              endIcon={<Add />}
              onClick={(e) => setTemplateConfig({ open: "true" })}
            >
              Create New Template
            </Button>
          </Stack>
          <Dialog
            open={templateConfig.open}
            onClose={(e) => setTemplateConfig({ open: false })}
          >
            <DialogTitle>Create New Inspection Type Template</DialogTitle>
            <Box sx={{ margin: "1rem" }}>
              <GQLForm
                mutationString="createInspectionTypeTemplate"
                defaultValues={{
                  inspectionTypeId: data.inspectionType.id,
                  configuration: JSON.stringify(configObj()),
                  start: new Date().toISOString(),
                  end: new Date().toISOString(),
                }}
                skipFields={{
                  id: true,
                  inspectionTypeId: true,
                  configuration: true,
                  start: true,
                  end: true,
                }}
                onCompleted={(data) => {
                  // setRefetch(true);
                  navigate(data.createInspectionTypeTemplate.id);
                }}
              />
            </Box>
          </Dialog>
          <TemplatesView />
          {/* <Box height={700}>
            <InspectionTypeTemplateTable />
          </Box> */}
          {/* <GQLTable
            queryString="inspectionTypeTemplates"
            columnOrder={[
              "name",
              "start",
              "end",
              "description",
              "configuration",
            ]}
            shouldRefetch={refetch}
            queryVariables={{
              inspectionType: {
                id: "f7d37406-0caa-4812-ade7-1aaf55d96379",
              },
            }}
            setShouldRefetch={setRefetch}
            columnOptions={{
              name: { width: 300, headerName: "TEMPLATE NAME" },
              description: { width: 300 },
              start: { width: 150 },
              end: { width: 150 },
              configuration: {
                headerName: "DELETE",
                renderCell: (params) => (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      onClick={(e) => {
                        setDeletePrompt({
                          open: true,
                          templateName: params.row.name,
                          id: params.id,
                        });
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                ),
              },
              id: {
                width: 50,
                headerName: "",
                renderCell: (params) => (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Link to={params.id}>
                      <IconButton>
                        <Edit />
                      </IconButton>
                    </Link>
                  </Box>
                ),
              },
              delete: {
                headerName: "delete",
              },
            }}
            restrictedColumns={{
              inspectionType: true,
            }}
          /> */}
        </Page>
      )}
      {error && <h1>{error.message}</h1>}
    </>
  );
}
