import {
  Select,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";

export const MUIRHFSelect = ({
  defaultValue,
  options,
  children,
  disabled,
  description,
  name,
  rules,
  label,
  onChange,
  ...rest
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? ""}
      rules={rules}
      render={({ field, fieldState: { error, invalid } }) => {
        return (
          <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
              {...field}
              margin="dense"
              width={"60%"}
              label={label}
              select
              error={invalid}
              helperText={error?.message || description || ""}
              onChange={(event) => {
                onChange && onChange(event);
                field.onChange(event);
              }}
              defaultValue={null}
              {...rest}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {invalid && (
              <FormHelperText error={true}>Selection Required</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default MUIRHFSelect;
