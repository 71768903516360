import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";

// material
import { Box, Grid, Container, Typography, Button, CircularProgress } from '@mui/material';
import createQuery from '../../utils/gql-mui-rhf/QueryAction';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { GQLMutationAction } from '../../utils/gql-mui-rhf/SingleMutation';

// components
import CredentialFields from './CredentialFields';
import Confirmation from '../../components/modals/confirmation';
import Page from '../../components/Page';
// ----------------------------------------------------------------------
function createDraftCredentialValue(refetch, snackbar) {
    return GQLMutationAction({
        mutationString: "createDraftCredentialValue",
        onCompleted: (response) => {
            refetch();
            snackbar('Saved Field', 'success');
        }
    })
}
function submitCredentialsRequest(refetch, snackbar, handleSendSubmitEmail, navigate) {
    return GQLMutationAction({
        mutationString: "submitCredentialsRequest",
        onCompleted: (response) => {
            refetch();
            handleSendSubmitEmail();
            snackbar('Submitted Request Information', 'success');
            navigate();
        }
    })
}
function submitCreateEmail(onCompleted) {
    return GQLMutationAction({
        mutationString: "createEmail",
        onCompleted: (response) => {
            onCompleted()
        }
    })
}
const processCredentials = (draftCredentials) => {
    return draftCredentials.map((draftCredential) => {
      return {
        ...draftCredential,
        draftCredentialValues: draftCredential.draftCredentialValues.filter((value) => value.credentialTypeValue.active === true),
        credentialType: {
          ...draftCredential.credentialType,
          credentialTypeValues: draftCredential.credentialType.credentialTypeValues.filter((value) => value.active === true)
        }
      }
    })
  }

const processFormEmail = (credentialsRequest, handleCreateEmail) => {
    let unprocessedCredentials = credentialsRequest.draftCredentials;
    let processedCredentials = processCredentials(unprocessedCredentials);
    let variables = { data: {} }
    variables["templateId"] = "d-d0b307a0a57b44679a384c0e17177d74";
    variables["to"] = credentialsRequest.email;
    variables["data"]["url"] = `${process.env.REACT_APP_URL}/onboarding/request/${credentialsRequest.id}`
    if (credentialsRequest.entity === "PERSON")
        variables["data"]["name"] = `${credentialsRequest.firstName} ${credentialsRequest.lastName}`;
    else
        variables["data"]["name"] = credentialsRequest.name;
    variables["data"]["draftRoles"] = processedCredentials;
    variables["data"] = JSON.stringify(variables.data)
    handleCreateEmail(variables)
}

const processSubmitEmail = (credentialsRequest, handleCreateEmail) => {
    let variables = { data: {} }
    variables["templateId"] = "d-995abb793f0e42b3adeeffcff0f5ad6a";
    variables["to"] = credentialsRequest.email;
    variables["data"]["url"] = `${process.env.REACT_APP_URL}/app/project/credential/request/${credentialsRequest.id}`
    if (credentialsRequest.entity === "PERSON")
        variables["data"]["name"] = `${credentialsRequest.firstName} ${credentialsRequest.lastName}`;
    else
        variables["data"]["credentialName"] = credentialsRequest.draftCredentials[0].credentialType.name;
    variables["data"] = JSON.stringify(variables.data)
    handleCreateEmail(variables)
}
const assessCredentialValue = (value) => {
    switch (value.type) {
        case "BOOLEAN":
            return value.booleanValue
        case "TEXT":
            return value.stringValue
        case "INTEGER":
            return value.integerValue
        case "DECIMAL":
            return value.decimalValue
        case "DATE":
            return value.dateValue
        case "IMAGE":
            return value.stringValue
        case "PDF":
            return value.stringValue
        default:
            return value.stringValue
    }
}

const getCredentialDefaultValues = (draftCredentialValues) => {
    const defaultValues = {}
    draftCredentialValues.forEach((value) => {
        if (value?.credentialTypeValue?.name)
            defaultValues[value.credentialTypeValue.name] = assessCredentialValue(value)
    })
    return defaultValues;
}
const draftCredentialValuesObject = (draftCredentialValues) => {
    const defaultValues = {}
    draftCredentialValues.forEach((value) => {
        if (value?.credentialTypeValue?.name)
            defaultValues[value.credentialTypeValue.name] = { value: assessCredentialValue(value), status: value.status }
    })
    return defaultValues;
}


const getRequiredFields = (draftCredentials) => {
    let requiredFields = []
    let requiredFieldsWithValues = []
    let requiredFieldsWithoutValues = []
    let draftCredentialValues = {}
    draftCredentials.forEach(draftCredential => {
        if (draftCredential.draftCredentialValues) {
            draftCredential.draftCredentialValues.forEach((value) => {
                if (!value?.credentialTypeValue?.active) { return null }
                draftCredentialValues[value.credentialTypeValue.name] = !!assessCredentialValue(value)
            })
        }
    })
    // Cycle through all fields and note whether they're required
    draftCredentials.forEach((draftCredential => {
        draftCredential?.credentialType?.credentialTypeValues?.forEach((value => {
            if (!value.active) { return null }
            if (value.required && draftCredentialValues[value.name]) {
                requiredFields.push(value)
                requiredFieldsWithValues.push(value)
            } else if (value.required && !draftCredentialValues[value.name]) {
                requiredFields.push(value)
                requiredFieldsWithoutValues.push(value)
            }
        }))
    }))
    return { requiredFields, requiredFieldsWithValues, requiredFieldsWithoutValues }
}
export default function CredentialRequest() {
    const navigate = useNavigate();
    const params = useParams()
    const [activeField, setActiveField] = useState(null);
    const credentialsRequestId = params.id
    const { enqueueSnackbar } = useSnackbar();
    const snackbar = (message, variant) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(message, { variant });
    };
    const { loading, error, data, refetch } = createQuery('credentialsRequest', { id: credentialsRequestId })
    const addDraftCredentialValue = createDraftCredentialValue(refetch, snackbar);
    const [confirmation, setConfirmation] = useState({ open: false });
    const closeConfirmation = () => { setConfirmation({ open: false }) };
    const handleSetConfirmation = () => setConfirmation({
        open: true,
        action: closeConfirmation,
        actionText: `Email has been sent${data.credentialsRequest ? ` to ${data.credentialsRequest.email}` : "."}`,
        alternateDisplay: "INFORMATION"
    })
    const handleSendSubmitEmail = () => {
        processSubmitEmail(data.credentialsRequest, handleCreateEmail)
    };
    const handleCreateEmail = submitCreateEmail(handleSetConfirmation);
    const handleSendFormEmail = (e) => {
        processFormEmail(data.credentialsRequest, handleCreateEmail)
    };
    const navigateToReview = () => { navigate('/app/project/credentials') }
    const handleSubmitCredentialsRequest = submitCredentialsRequest(refetch, snackbar, handleSendSubmitEmail, navigateToReview);
    if (loading)
        return <CircularProgress />
    if (error) {
        return <div>{`${error}`}</div>
    }
    if (data) {
        const requiredFieldStatus = getRequiredFields(data.credentialsRequest.draftCredentials)
        return (
            <Page title="Onboarding | FINBACK670">
                <Confirmation confirmation={confirmation} handleClose={closeConfirmation} />
                <Container maxWidth="xl">
                    <Grid container direction={"column"} spacing={2}>
                        <Grid item>
                            <Typography variant="h4">Request Form</Typography>
                        </Grid>
                        <Grid item container direction={"row"} spacing={2} alignItems={"center"}>
                            <Grid item>
                                <Typography variant="h4h7">Please complete all required fields, or optionally: </Typography>
                            </Grid>
                            <Grid item>
                                <Button sx={{ textTransform: 'none' }} disabled={data.credentialsRequest.status === 'submitted'} onClick={handleSendFormEmail} variant="outlined">
                                    {`Send to ${data.credentialsRequest.email} to complete`}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4h7">Note: All inputted, edited, and deleted data is saved in real-time by utilizing the icons to the right of each data field.</Typography>
                        </Grid>
                        <Grid item container direction={"row"} spacing={2} alignItems={"center"}>
                            <Grid item>
                                <Typography variant="h4h7">To edit a data field, click: </Typography>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" startIcon={<EditIcon />}>
                                    Edit
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item container direction={"row"} spacing={2} alignItems={"center"}>
                            <Grid item>
                                <Typography variant="h4h7">To save edits to a datafield, click: </Typography>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" startIcon={<SaveIcon />}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid item>
                                <Button variant="outlined" color="primary">
                                    * indicates a required data field
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {data.credentialsRequest.status === "submitted" && <Box sx={{ pb: 5 }}>
                                <Typography variant="h7">Request has been submitted for approval and is locked.</Typography>
                            </Box>}
                        </Grid>
                        {data.credentialsRequest.draftCredentials && data.credentialsRequest.draftCredentials.map((draftCredential) => {
                            if (!draftCredential?.credentialType?.credentialTypeValues?.length ?? true) {
                                return null;
                            }
                            const defaultCredentialValues = draftCredential.draftCredentialValues ? getCredentialDefaultValues(draftCredential.draftCredentialValues) : {}
                            const draftCredentialValues = draftCredential.draftCredentialValues ? draftCredentialValuesObject(draftCredential.draftCredentialValues) : {};
                            return (
                                <Grid item key={draftCredential.credentialType.id}>
                                    <Box sx={{ pb: 5 }}>
                                        <Grid container direction="column" alignItems={"flex-start"} justifyContent={"center"} spacing={2}>
                                            <Box sx={{ pb: 5 }}>
                                                <Typography variant="h5">{draftCredential.credentialType.name}</Typography>
                                            </Box>
                                            <CredentialFields draftCredential={draftCredential} defaultCredentialValues={defaultCredentialValues} draftCredentialValues={draftCredentialValues} activeField={activeField} setActiveField={setActiveField} addDraftCredentialValue={addDraftCredentialValue} />
                                        </Grid>
                                    </Box>
                                </Grid>
                            )
                        })}
                        {(requiredFieldStatus.requiredFields.length !== requiredFieldStatus.requiredFieldsWithValues.length) && <Grid item>
                            <Typography>{`${requiredFieldStatus.requiredFields.length - requiredFieldStatus.requiredFieldsWithValues.length} fields missing`}</Typography>
                        </Grid>}
                        <Grid item container spacing={8} direction={"row"}>
                            <Grid item>
                                <Button sx={{ textTransform: 'none' }} disabled={data.credentialsRequest.status === 'submitted'} onClick={handleSendFormEmail} variant="outlined">
                                    {`Send to ${data.credentialsRequest.email} to complete.`}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button disabled={(requiredFieldStatus.requiredFields.length !== requiredFieldStatus.requiredFieldsWithValues.length) || data.credentialsRequest.status === 'submitted'} onClick={() => { handleSubmitCredentialsRequest({ id: credentialsRequestId }) }} variant="outlined" startIcon={<SaveIcon />}>
                                    Submit Request for Approval
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Page >
        );
    }
}
