import TextField from "../../../utils/mui-rhf/TextField";
import Address from "../../../utils/mui-rhf/Address";
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from "react-hook-form";
import { Grid, Button, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const UPDATE_CLIENT = gql`
mutation updateClient($client: InputClientParams) {
    updateClient(client: $client) {
      clientLocation {
        id
      }
      id
    }
  }
`
const clientLocationParams = (client) => {
    const allowedProps = ["city", "description", "mapboxId", "name", "owner", "state", "street1", "street2", "street3", "tenant", "zip", "lat", "lng", "neighborhood"]
    const result = {};

    for (const prop of allowedProps) {
        if (client.hasOwnProperty(prop) && !!client[prop]) {
            result[prop] = client[prop];
        }
    }
    return result;
}
const clientParams = (client) => {
    const allowedProps = [
        'id',
        'contractNumber',
        'solicitationNumber',
        'dateIssued',
        'requisition',
        'issuedBy',
        'name',
        'pointOfContact',
        'email',
        'telephone',
        'fax',
        'code',
        'contractingOfficerName',
        'contractingOfficerEmail',
        'promptPaymentDiscount',
        'address'

    ];
    const result = {};

    for (const prop of allowedProps) {
        if (client.hasOwnProperty(prop) && !!client[prop]) {
            if (prop === 'address' || prop === 'administeredOfficerAddress' || prop === 'remittanceAddress' || prop === 'paymentAgencyAddress') {
                result[prop] = clientLocationParams(client[prop])
            } else {
                result[prop] = client[prop];
            }
        }
    }
    return result;
}

export default function Forms({ client, refetch }) {
    const { enqueueSnackbar } = useSnackbar();
    const snackbar = (message, variant) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(message, { variant });
    };
    const defaultValues = clientParams(client)
    const methods = useForm({ defaultValues });
    const { handleSubmit } = methods;
    const [updateClient] = useMutation(UPDATE_CLIENT, {
        onCompleted: () => {
            snackbar('Client Updated', 'success');
            refetch();
        }
    })


    const onSubmit = (values) => {
        updateClient({
            variables: {
                client: {
                    id: client.id,
                    ...values
                }
            }
        })
    }
    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ width: "100%" }}>
                    <Grid container spacing={2} padding={1}>
                        <Grid item xs={12} >
                            <TextField fullWidth rules={{ required: "Required Fields" }} name={"contractNumber"} label={"Contract Number"} />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField fullWidth rules={{ required: "Required Fields" }} name={"solicitationNumber"} label={"Solicitation Number"} />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField fullWidth rules={{ required: "Required Fields" }} name={"dateIssued"} label={"Date Issued"} />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField fullWidth rules={{ required: "Required Fields" }} name={"issuedBy"} label={"Issued By"} />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField fullWidth rules={{ required: "Required Fields" }} name={"requisition"} label={"Requisition"} />
                        </Grid>
                        <Grid item xs={12}><Typography>Issued By: </Typography></Grid>
                        <Grid item xs={12} >
                            <TextField fullWidth rules={{ required: "Required Fields" }} name={"name"} label={"Name"} />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField fullWidth rules={{ required: "Required Fields" }} name={"pointOfContact"} label={"Point of Contact"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} >
                            <Address
                                methods={methods}
                                group={'address.'}
                                initialConfig={{ type: 'create' }}
                                rules={
                                    {
                                        street1: { required: "Required Field" },
                                        city: { required: "Required Field" },
                                        state: { required: "Required Field" },
                                        zip: { required: "Required Field" }
                                    }
                                }
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField fullWidth rules={{ required: "Required Fields" }} name={"email"} label={"Email"} />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField fullWidth rules={{ required: "Required Fields" }} name={"telephone"} label={"Telephone"} />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField fullWidth rules={{ required: "Required Fields" }} name={"fax"} label={"Fax"} />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField fullWidth rules={{ required: "Required Fields" }} name={"code"} label={"Code"} />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField fullWidth rules={{ required: "Required Fields" }} name={"contractingOfficerName"} label={"Contracting Officer Name"} />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField fullWidth rules={{ required: "Required Fields" }} name={"contractingOfficerEmail"} label={"Contracting Officer Email"} />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField fullWidth rules={{ required: "Required Fields" }} name={"promptPaymentDiscount"} label={"Prompt Payment Discount"} />
                        </Grid>
                    </Grid>
                </div>
                <Button variant="contained" type="submit">
                    {"Update"}
                </Button>
            </form>
        </FormProvider>
    );
}