import React, { useState } from 'react';

let FirmContext = React.createContext(null);

function FirmProvider({ children }) {
    const [firm, setFirm] = useState('ae891170-28b5-4cb3-88fd-ed295e4a58d1');
    let value = { firm, setFirm };
    return <FirmContext.Provider value={value}>{children}</FirmContext.Provider>;
}

export { FirmContext, FirmProvider }

