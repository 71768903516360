export function dec2bin(dec: number, representAsCharacters?: boolean) {
  const binaryString = (dec >>> 0).toString(2);
  if (representAsCharacters) {
    return binaryString.replace(/1/g, "x").replace(/0/g, "o");
  } else {
    return binaryString;
  }
}

export function charBin2dec(charBin: string) {
  const numericBinary = charBin.replace(/x/g, "1").replace(/o/g, "0");
  const decimalNumber = parseInt(numericBinary, 2);
  return decimalNumber;
}
