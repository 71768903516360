import { useState } from 'react';
import { useSnackbar } from 'notistack';
//gql-mui-rhf
import createQuery from '../../utils/gql-mui-rhf/QueryAction';
import { GQLMutationAction } from '../../utils/gql-mui-rhf/SingleMutation';
import GQLTable from '../../utils/gql-mui-rhf/Table';
import { GQLForm } from '../../utils/gql-mui-rhf/FormProvider';

// material
import { Grid, Typography, IconButton, Checkbox, FormGroup, FormControlLabel, CircularProgress } from '@mui/material';
import { Icon } from '@iconify/react';
import leftArrow from '@iconify/icons-eva/arrow-circle-left-fill';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

// ----------------------------------------------------------------------

function generateMutation(refetch, snackbar, shouldRefetch) {
    return GQLMutationAction({
        mutationString: "createRoleTypeValue",
        onCompleted: (response) => {
            refetch();
            shouldRefetch();
            snackbar('Added Field', 'success');
        }
    })
}
function updateRoleType(refetch, snackbar, shouldRefetch) {
    return GQLMutationAction({
        mutationString: "updateRoleType",
        onCompleted: (response) => {
            refetch();
            shouldRefetch();
            snackbar('Updated Role', 'success');
        }
    })
}
const renderDefaultArray = (array, addRoleTypeValue, roleTypeId, existingValues) => {
    const activeValues = existingValues.filter(item => item.active)
    const filteredArray = array.filter(item => !activeValues.some(item2 => item2.name === item.name))
    return filteredArray.map((defaultField, idx) => (
        <Grid item container alignItems="center" key={idx}>
            <IconButton
                size="large"
                color={'primary'}
                onClick={() => {
                    addRoleTypeValue({ name: defaultField.name, type: defaultField.type, roleTypeId: roleTypeId })
                }}
            >
                <Icon icon={leftArrow} width={30} height={30} />
            </IconButton>
            <div>{defaultField.name}</div>
        </Grid>
    ))
}
const renderDefaults = (roleTypeValues, roleType, personDefaults, firmDefaults, addRoleTypeValue) => {
    if (roleType.entity === "PERSON") {
        return renderDefaultArray(personDefaults, addRoleTypeValue, roleType.id, roleTypeValues)
    }
    if (roleType.entity === "FIRM") {
        return renderDefaultArray(firmDefaults, addRoleTypeValue, roleType.id, roleTypeValues)
    }
}
function addCustomField(open, handleClose, addMutationString, additionalVariables, shouldRefetch, snackbar, restrictedColumns) {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <GQLForm mutationString={addMutationString} additionalVariables={additionalVariables} inputOrder={{ name: { order: 1 }, type: { order: 2 } }} skipFields={{ id: true, roleTypeId: true, active: true, label: true, required: true, tier1: true, tier2: true, inputMask: true, helperText: true, placeholder: true }} onCompleted={() => {
                    shouldRefetch();
                    handleClose()
                    snackbar('Added Custom Field', 'success')
                }} />
            </DialogContent>
        </Dialog>
    )
}

function immutableMove(handleUpdateRoleType, arr, rowTransaction) {
    const from = rowTransaction.oldIndex
    const to = rowTransaction.targetIndex
    const newOrder = arr.reduce((prev, current, idx, self) => {
        if (from === to) {
            prev.push(current);
        }
        if (idx === from) {
            return prev;
        }
        if (from < to) {
            prev.push(current);
        }
        if (idx === to) {
            prev.push(self[from]);
        }
        if (from > to) {
            prev.push(current);
        }
        return prev;
    }, []);
    const roleTypeId = newOrder[0].roleTypeId;
    const idOrder = newOrder.map((e) => e.id);
    handleUpdateRoleType({ id: roleTypeId, valueOrder: idOrder })
}


export default function RoleTypeView({ roleId }) {
    const [refetchTable, setRefetchTable] = useState(false);
    const [customFieldModal, setCustomFieldModal] = useState(false);
    const shouldRefetchTable = () => setRefetchTable(true)
    const openCustomFieldModal = () => setCustomFieldModal(true)
    const closeCustomFieldModal = () => setCustomFieldModal(false)
    const { enqueueSnackbar } = useSnackbar();
    const snackbar = (message, variant) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(message, { variant });
    };
    const roleType = createQuery('roleType', { id: roleId })
    const roleTypeValues = createQuery('roleTypeValues', { id: roleId, active: true })
    const personDefaults = [{ name: "First Name", type: "TEXT" }, { name: "Last Name", type: "TEXT" }]
    const firmDefaults = [{ name: "Name", type: "TEXT" }, { name: "DBA", type: "TEXT" }]
    const addRoleTypeValue = generateMutation(roleTypeValues.refetch, snackbar, shouldRefetchTable);
    const handleUpdateRoleType = updateRoleType(roleTypeValues.refetch, snackbar, shouldRefetchTable);
    if (roleType.loading)
        return <CircularProgress />
    if (roleType.error) {
        return <div>{`${roleType.error}`}</div>
    }
    if (roleTypeValues.loading)
        return <CircularProgress />
    if (roleTypeValues.error) {
        return <div>{`${roleTypeValues.error}`}</div>
    }
    if (roleType.data && roleTypeValues.data) {
        return (
            <Grid container>
                {addCustomField(customFieldModal, closeCustomFieldModal, "createRoleTypeValue", { roleTypeId: roleType.data.roleType.id }, shouldRefetchTable, snackbar)}
                <Grid item xs={10} style={{ padding: '16pt' }}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roleType.data.roleType.faRole}
                                    onChange={() => { 
                                        handleUpdateRoleType({ id: roleType.data.roleType.id, faRole: !roleType.data.roleType.faRole }) 
                                    }}
                                />}
                            label="Firm Administrator Role"
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roleType.data.roleType.backgroundCheck}
                                    onChange={() => { 
                                        handleUpdateRoleType({ id: roleType.data.roleType.id, backgroundCheck: !roleType.data.roleType.backgroundCheck }) 
                                    }}
                                />}
                            label="Background Check Required"
                        />
                    </FormGroup>
                    <Typography variant="h6">Selected</Typography>
                    <GQLTable
                        queryString={'roleTypeValues'}
                        rowOrder={roleType.data.roleType.valueOrder}
                        handleRowOrderChange={immutableMove.bind(null, handleUpdateRoleType)}
                        queryVariables={{ "active": true, "roleTypeId": roleType.data.roleType.id }}
                        deleteMutationString={'deleteRoleTypeValue'}
                        updateMutationString={'updateRoleTypeValue'}
                        columnOptions={{
                            "label": {
                                editable: true,
                                width: 150,
                                headerName: 'CUSTOM LABEL'
                            },
                            "name": {
                                editable: true,
                                headerName: 'FIELD'
                            },
                            "inputMask": {
                                headerName: "INPUT MASK",
                                editable: true,
                                width: 150
                            },
                            "helperText": {
                                headerName: "HELPER TEXT",
                                editable: true,
                                width: 150
                            },
                            "placeholder": {
                                editable: true,
                                width: 150
                            },
                            "tier1": {
                                headerName: "TIER 1",
                                type: 'boolean',
                                editable: true,
                            },
                            "tier2": {
                                headerName: "TIER 2",
                                type: 'boolean',
                                editable: true,
                            },
                            "required": {
                                type: 'boolean',
                                editable: true,
                            }

                        }}
                        columnOrder={["name", "label", "required", "tier1", "tier2", "helperText", "inputMask", "placeholder", "type", "roleTypeId"]}
                        restrictedColumns={{ valueOrder: true, id: true, type: false, roleTypeId: true, active: true, module: true }}
                        shouldRefetch={refetchTable}
                        setShouldRefetch={setRefetchTable}
                    />
                </Grid>
                <Grid item container direction="column" justifyContent="flex-start" alignItems="flex-start" xs={2} style={{ padding: '16pt' }} >
                    <Typography variant="h6">Not Selected</Typography>
                    <Typography variant="h7">Field</Typography>
                    {renderDefaults(roleTypeValues.data.roleTypeValues, roleType.data.roleType, personDefaults, firmDefaults, addRoleTypeValue)}
                    <Grid item container alignItems="center">
                        <IconButton
                            size="large"
                            color={'primary'}
                            onClick={openCustomFieldModal}
                        >
                            <Icon icon={leftArrow} width={30} height={30} />
                        </IconButton>
                        <div>{"[Custom Field]"}</div>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
