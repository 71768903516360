import { Action, PageState } from "../../TypeDefinitions";

export function setEditor<
  E extends Extract<
    Action,
    {
      type: "setEditor";
    }
  >
>(action: E, draft: PageState): void {
  draft.editingFieldId = action.payload.fieldId;
}
