import { gql, useQuery } from "@apollo/client";
import { Photo } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { FC, useState } from "react";

const PHOTOS_QUERY = gql`
  query Photos($photo: InputPhotoParams!) {
    photos(photo: $photo) {
      id
      capturedAt
      description
      image
      metadata
      referenceId
      referenceType
      title
      url
      isDeleted
      photoCollectionId
      photoCollectionTitle
      personId
      inspectionId
      inspectionAssignmentId
      thoFormId
      thoFormInstanceId
      thoFormFieldId
      thoFormFieldInstanceId
    }
  }
`;

const PhotoDisplay: FC<{ assignmentId: string }> = ({ assignmentId }) => {
  const {
    loading,
    data: photoData,
    // error,
  } = useQuery(PHOTOS_QUERY, {
    variables: {
      photo: {
        inspectionAssignmentId: assignmentId,
      },
    },
  });

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        endIcon={!loading ? <Photo /> : <CircularProgress />}
        disabled={loading || (photoData && photoData.photos.length === 0)}
        variant="outlined"
        onClick={(e) => setOpen(true)}
      >
        Photos
      </Button>
      <Dialog
        open={open}
        maxWidth="md"
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Photos</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {photoData &&
              photoData.photos &&
              photoData.photos.map((photo: any) => (
                <PhotoCard
                  img={photo.image}
                  key={photo.id}
                  description={photo.title}
                />
              ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

const PhotoCard: FC<{ img: string; description: string }> = ({
  img,
  description,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Grid item xs={6} sm={4} md={3}>
      <img
        src={"data:image/png;base64, " + img}
        alt={description}
        onClick={() => setOpen(true)}
      />
      <Typography>{description}</Typography>
      <Dialog open={open} fullWidth onClose={() => setOpen(false)}>
        <img
          src={"data:image/png;base64, " + img}
          alt={description}
          height="100%"
        />
      </Dialog>
      {/* <Card>
        <CardMedia
          component="img"
          sx={{ height: 145 }}
          src={"data:image/png;base64, " + img}
          title="kitchen"
        />
        <CardContent>
          <Typography>{description}</Typography>
        </CardContent>
      </Card> */}
    </Grid>
  );
};

export default PhotoDisplay;
