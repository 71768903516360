import {
  Card,
  Grid,
  Col,
  Text,
  Flex,
  CategoryBar,
  Metric,
  Title,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  BadgeDelta,
} from "@tremor/react";

const salesPeople = [
  {
    name: "Peter Doe",
    inspections: 45,
    washouts: 0,
    post: "Region A",
    delta: "overperforming",
    deltaType: "moderateIncrease",
  },
  {
    name: "Lena Whitehouse",
    inspections: 35,
    washouts: 2,
    post: "Region B",
    delta: "average",
    deltaType: "unchanged",
  },
  {
    name: "Phil Less",
    inspections: 52,
    washouts: 3,
    post: "Region C",
    delta: "underperforming",
    deltaType: "moderateDecrease",
  },
  {
    name: "John Camper",
    inspections: 22,
    washouts: 1,
    post: "Region A",
    delta: "overperforming",
    deltaType: "increase",
  },
  {
    name: "Max Balmoore",
    inspections: 49,
    washouts: 0,
    post: "Region B",
    delta: "overperforming",
    deltaType: "increase",
  },
];

const subCategoryPercentageValues = [10, 25, 45, 20];

export default function Example() {
  return (
    <main>
      <Title>Fort Leavenworth Inspector Performance</Title>

      <Grid numColsLg={6} className="gap-6 mt-6">
        {/* Main section */}
        <Col numColSpanLg={4}>
          <Card className="h-full">
            <div className="h-60">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell> Name </TableHeaderCell>
                    <TableHeaderCell className="text-right">
                      {" "}
                      Inspections{" "}
                    </TableHeaderCell>
                    <TableHeaderCell className="text-right">
                      {" "}
                      Washouts{" "}
                    </TableHeaderCell>
                    <TableHeaderCell className="text-right">
                      {" "}
                      Post{" "}
                    </TableHeaderCell>
                    <TableHeaderCell className="text-right">
                      {" "}
                      Status{" "}
                    </TableHeaderCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {salesPeople.map((item) => (
                    <TableRow key={item.name}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell className="text-right">
                        {item.inspections}
                      </TableCell>
                      <TableCell className="text-right">
                        {item.washouts}
                      </TableCell>
                      <TableCell className="text-right">{item.post}</TableCell>
                      <TableCell className="text-right">
                        <BadgeDelta deltaType={item.deltaType} size="xs">
                          {item.delta}
                        </BadgeDelta>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Card>
        </Col>

        {/* KPI sidebar */}
        <Col numColSpanLg={2}>
          <div className="space-y-6">
            <Card>
              <div className="h-24">
                <Text>{"Scheduled"}</Text>
                <Flex
                  justifyContent="start"
                  alignItems="baseline"
                  className="space-x-1"
                >
                  <Metric>{9.5}</Metric>
                  <Text>/ 10</Text>
                </Flex>
                <CategoryBar
                  categoryPercentageValues={subCategoryPercentageValues}
                  colors={["rose", "orange", "yellow", "emerald"]}
                  percentageValue={95}
                  tooltip={9.5}
                  showLabels={false}
                  className="mt-5"
                />
              </div>
            </Card>
            <Card>
              <div className="h-24">
                <Text>{"On-site Complete"}</Text>
                <Flex
                  justifyContent="start"
                  alignItems="baseline"
                  className="space-x-1"
                >
                  <Metric>{8.5}</Metric>
                  <Text>/ 10</Text>
                </Flex>
                <CategoryBar
                  categoryPercentageValues={subCategoryPercentageValues}
                  colors={["rose", "orange", "yellow", "emerald"]}
                  percentageValue={85}
                  tooltip={8.5}
                  showLabels={false}
                  className="mt-5"
                />
              </div>
            </Card>
            <Card>
              <div className="h-24">
                <Text>{"Submitted to eMH"}</Text>
                <Flex
                  justifyContent="start"
                  alignItems="baseline"
                  className="space-x-1"
                >
                  <Metric>{10}</Metric>
                  <Text>/ 10</Text>
                </Flex>
                <CategoryBar
                  categoryPercentageValues={subCategoryPercentageValues}
                  colors={["rose", "orange", "yellow", "emerald"]}
                  percentageValue={100}
                  tooltip={10}
                  showLabels={false}
                  className="mt-5"
                />
              </div>
            </Card>
          </div>
        </Col>
      </Grid>
    </main>
  );
}
