import { PageState, Action, PossibleValue } from "../../TypeDefinitions";
import { v4 as uuidv4 } from "uuid";

export function createPossibleValue<
  E extends Extract<
    Action,
    {
      type: "createPossibleValue";
    }
  >
>(action: E, draft: PageState): void {
  if (draft.editingFieldId === null) return;

  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );

  const fieldIndex = draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.findIndex((field) => field.id === draft.editingFieldId);

  const field = draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.splice(fieldIndex, 1)[0];

  switch (field.inputType) {
    case "Checklist":
    case "OptionSelect":
    case "DropDown":
      {
        let newPossibleValue: PossibleValue = {
          id: uuidv4(),
          formFieldId: field.id,
          description: "",
          displayIndex: field.possibleValues.length,
          label: "",
          name: "",
          value: "",
        };
        field.possibleValues.push(newPossibleValue);
      }
      break;
    case "Text":
    case "Date":
    case "DateTime":
    case "Email":
    case "LongText":
    case "Number":
    case "Phone":
    case "PhotoCollection":
    case "Signature":
    case "Time":
    case "YesNo":
      break;
    default: {
      const _exhaustiveCheck: never = field;
      return _exhaustiveCheck;
    }
  }

  draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.splice(fieldIndex, 0, field);
}
