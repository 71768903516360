import { gql, useLazyQuery } from "@apollo/client";
import { AttachFile } from "@mui/icons-material";
import { Button, CircularProgress, Stack, styled } from "@mui/material";
import React, { ChangeEvent, FC, useEffect } from "react";

const QUERY_DOCUMENT = gql`
  query Documents($document: InputDocumentParams!) {
    documents(document: $document) {
      id
      title
      description
      documentBytes
      url
      fileExtension
      fileName
      isDeleted
      insertedAt
      updatedAt
    }
  }
`;

type ReferenceParams = {
  title: string;
  id: string;
  stringContents: string | null;
  documentId?: string | null;
  [x: string]: any;
} | null;

export const DocumentationHandler: FC<{
  title: string;
  setReference: (newData: ReferenceParams) => void;
  reference: ReferenceParams;
  file: Blob | null;
  setFile: (blob: Blob) => void;
}> = ({ title, file, setFile, reference }) => {
  const [getDocument, { data, loading }] = useLazyQuery(QUERY_DOCUMENT, {
    onCompleted: (data) => {
      console.log("RESPONSE DATA");
      console.log(data);
      setBase64ref(
        data.documents[0].fileExtension,
        data.documents[0].documentBytes
      );
    },
  });

  useEffect(() => {
    if (reference && reference.documentId) {
      getDocument({ variables: { document: { id: reference.documentId } } });
    }
  }, [reference, getDocument]);

  const handleSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    let fileInput = event.target.files ? event.target.files[0] : null;
    if (fileInput === null) return;
    setFile(fileInput);
  };

  async function setBase64ref(extension: string, base64: string) {
    const mimeType = extension.includes("pdf")
      ? "application/pdf"
      : "image/png";

    const fetchString = `data:${mimeType};base64,${base64}`;
    const base64response = await fetch(fetchString);
    console.log(fetchString);
    const res = await base64response.blob();

    setFile(res);
  }

  if (data && data.updateDocument) {
  }

  const disabled = !title || loading;
  return (
    <Stack direction="column" height={"100%"} spacing={2}>
      <CustomButton
        sx={{ alignSelf: "center" }}
        endIcon={loading ? <CircularProgress size={12} /> : <AttachFile />}
        variant="outlined"
        component="label"
        disabled={disabled}
      >
        Upload Document <input type="file" hidden onChange={handleSelect} />
      </CustomButton>
      {file && <FileDisplay file={file} />}
    </Stack>
  );
};

type ExtraProps = {
  component: React.ElementType;
};
const CustomButton = styled(Button)<ExtraProps>({});

const FileDisplay: FC<{ file: Blob }> = ({ file }) => {
  const url = URL.createObjectURL(file);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(url);
    };
  });

  if (file.type.includes("image")) {
    return <img src={url} alt={"asdf"} />;
  }
  return <iframe src={url} title="document" />;
};
