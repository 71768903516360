import { CheckCircle } from '@mui/icons-material'
import { Box, CircularProgress, Typography } from '@mui/material'
import React, { FC } from 'react'

export const SyncDisplay: FC<{isLoading: boolean, children: React.ReactNode}> = ({isLoading, children}) => {
    const color = isLoading ? "orange" : "green";

  return (
    <>
      <Box
        position="fixed"
        top={90}
        right={100}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="start"
        border={`1px dashed ${color}`}
        borderRadius={1}
        paddingX={1}
        zIndex={9999}
      >
        <Typography variant="h5" color={color} marginRight={1}>
          {isLoading ? "Syncing" : "Synced"}
        </Typography>
        {isLoading ? (
          <CircularProgress size={14} sx={{ color: "orange" }} />
        ) : (
          <CheckCircle fontSize="small" htmlColor="green" />
        )}
      </Box>
      {children}
      </>
  )
}
