// material
import { Grid, Container } from '@mui/material';
// components
import Page from '../../components/Page';
import GQLTable from '../../utils/gql-mui-rhf/Table'
// ----------------------------------------------------------------------

export default function UserProfile({person}) {
    return (
        <Page title="User Profile | FINBACK670">
            <Container maxWidth="xl">
                <Grid container spacing={3}>
                    <Grid minWidth={"100%"} item xs={12} sm={6} md={3}>
                        <GQLTable 
                            queryString={'draftRoles'}
                            queryVariables={{status: "APPROVED", entityId: person.id}}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
