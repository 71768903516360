import { Action, PageState } from "../../TypeDefinitions";

export function deleteExpression<
  E extends Extract<
    Action,
    {
      type: "deleteExpression";
    }
  >
>(action: E, draft: PageState): void {
  const { validationId, expressionId } = action.payload;
  const formId = draft.formId;
  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => formId === form.id
    );

  if (formIndex === -1) return;

  const validationIndex = draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].validations.findIndex((validation) => validation.id === validationId);

  const expressionIndex = draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].validations[validationIndex].expressions.findIndex(
    (expression) => expression.id === expressionId
  );

  draft.data.inspectionTypeTemplate.configuration.forms[formIndex].validations[
    validationIndex
  ].expressions.splice(expressionIndex, 1);
  draft.data.inspectionTypeTemplate.configuration.forms[formIndex].validations[
    validationIndex
  ].expressions.forEach((expression, i) => {
    if (i === 0) expression.linkedBy = null;
    expression.order = i;
  });
}
