import { Action, PageState } from "../../TypeDefinitions";

export function closeEditor<
  E extends Extract<
    Action,
    {
      type: "closeEditor";
    }
  >
>(action: E, draft: PageState): void {
  draft.editingFieldId = null;
}
