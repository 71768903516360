import { Add, Clear, Error, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import Expressions from "./Expressions";
import { FormCtx } from "./FormWrapper/BuilderWrapper";

const actions = ["Show", "Error"];

const triggers = [
  {
    label: "Value Change",
    value: "ValueChange",
  },
  {
    label: "Form Save",
    value: "FormSave",
  },
];

export default function LogicEditor() {
  const { state, dispatch } = useContext(FormCtx);
  const form = state.data.inspectionTypeTemplate.configuration.forms.find(
    (form) => form.id === state.formId
  );
  const field = form.fields.find((field) => field.id === state.editingFieldId);
  const validations = form.validations.filter(
    (validation) => validation.field === field.id
  );

  return (
    <>
      {validations.length > 0 ? (
        <Stack
          sx={{ backgroundColor: "#F0F0F0" }}
          padding={1}
          maxHeight="100%"
          borderRadius={1}
        >
          {validations.map((validation, i) => {
              const validationHasError = state.errors.some(error => error.validationId === validation.id);
            return (
            <Accordion key={validation.id}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Stack direction='row' justifyContent='space-between' width={'100%'}>
                <Typography>{"Validation " + (i + 1)}</Typography>
                {validationHasError && <Error htmlColor="red"/>}
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="column" alignItems="stretch">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <FormControl>
                      <FormLabel>Run validation on:</FormLabel>
                      <RadioGroup
                        value={validation.onTrigger}
                        onChange={(e) => {
                          let newValue = e.target.value;
                          if (
                            newValue !== "FormSave" &&
                            newValue !== "ValueChange"
                          ) {
                            return;
                          }
                          dispatch({
                            type: "updateValidationTrigger",
                            payload: {
                              validationId: validation.id,
                              newValue: newValue,
                            },
                          });
                        }}
                      >
                        {triggers.map((trigger) => (
                          <FormControlLabel
                            label={trigger.label}
                            control={<Radio />}
                            value={trigger.value}
                            key={trigger.value}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                    <Button
                      color="error"
                      endIcon={<Clear />}
                      onClick={() =>
                        dispatch({
                          type: "deleteValidation",
                          payload: {
                            validationId: validation.id,
                          },
                        })
                      }
                    >
                      Delete
                    </Button>
                  </Stack>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "1.5rem",
                    }}
                  >
                    <TextField
                      select
                      sx={{ flex: 5 }}
                      size="small"
                      label="Action"
                      value={validation?.action ?? null}
                      error={validation?.action === null}
                      onChange={(e) =>
                        dispatch({
                          type: "updateValidationAction",
                          payload: {
                            validationId: validation.id,
                            newValue: e.target.value,
                          },
                        })
                      }
                    >
                      {actions.map((option, i) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Box
                      sx={{
                        flex: 3,
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Typography textAlign="center" fontSize="1rem">
                        this field when
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      flex: 3,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginTop: "0.5rem",
                    }}
                  >
                    <Typography textAlign="center" fontSize="1rem">
                      all of the following conditions are met.
                    </Typography>
                    {validation.action === "Error" && (
                      <Stack width="100%" marginTop="1rem">
                        <TextField
                          label="Error Message"
                          size="small"
                          value={validation.message}
                          onChange={(e) =>
                            dispatch({
                              type: "updateValidationMessage",
                              payload: {
                                messageValue: e.target.value,
                                validationId: validation.id,
                              },
                            })
                          }
                        />
                      </Stack>
                    )}
                    <Button
                      sx={{ alignSelf: "center", marginY: "1rem" }}
                      onClick={() =>
                        dispatch({
                          type: "createExpression",
                          payload: { validationId: validation.id },
                        })
                      }
                      variant="outlined"
                      startIcon={<Add />}
                    >
                      Add Condition
                    </Button>
                  </Box>
                  <Expressions validation={validation} />
                </Stack>
              </AccordionDetails>
            </Accordion>
          )})}
        </Stack>
      ) : (
        <>
          <Typography
            sx={{ display: "flex", alignSelf: "center" }}
            color="GrayText"
          >
            No validations on this field yet.
          </Typography>
        </>
      )}
      <Button
        sx={{ display: "flex", alignSelf: "center", marginTop: "0.5rem" }}
        variant="contained"
        size="small"
        endIcon={<Add />}
        onClick={() => dispatch({ type: "createValidation" })}
      >
        Add Validation
      </Button>
    </>
  );
}
