import { useState } from 'react';
import { useSnackbar } from 'notistack';
//gql-mui-rhf
import createQuery from '../../utils/gql-mui-rhf/QueryAction';
import { GQLMutationAction } from '../../utils/gql-mui-rhf/SingleMutation';
import GQLTable from '../../utils/gql-mui-rhf/Table';
import { GQLForm } from '../../utils/gql-mui-rhf/FormProvider';
import { Delete } from "@mui/icons-material";

// material
import { Grid, Typography, IconButton, Dialog, DialogContent, CircularProgress } from '@mui/material';
import { Icon } from '@iconify/react';
import leftArrow from '@iconify/icons-eva/arrow-circle-left-fill';
import Confirmation from '../../components/modals/confirmation';


// ----------------------------------------------------------------------

function generateMutation(refetch, snackbar, shouldRefetch) {
    return GQLMutationAction({
        mutationString: "createRoleTypeCredentialValue",
        onCompleted: (response) => {
            refetch();
            shouldRefetch();
            snackbar('Added Field', 'success');
        }
    })
}
function deleteRoleTypeCredential(refetch, snackbar) {
    return GQLMutationAction({
        mutationString: "deleteRoleTypeCredential",
        onCompleted: (response) => {
            refetch();
            snackbar('Deleted Credential', 'success');
        }
    })
}
function updateRoleTypeCredential(refetch, snackbar, shouldRefetch) {
    return GQLMutationAction({
        mutationString: "updateRoleTypeCredential",
        onCompleted: (response) => {
            refetch();
            shouldRefetch();
            snackbar('Updated Credential', 'success');
        }
    })
}
const renderDefaultArray = (array, addRoleTypeCredentialValue, roleTypeCredential, existingValues) => {
    const activeValues = existingValues.filter(item => item.active)
    const filteredArray = array.filter(item => !activeValues.some(item2 => item2.name === item.name))
    return filteredArray.map((defaultField, idx) => (
        <Grid item container alignItems="center" key={idx}>
            <IconButton
                size="large"
                color={'primary'}
                onClick={() => {
                    addRoleTypeCredentialValue({ name: defaultField.name, type: defaultField.type, roleTypeCredentialId: roleTypeCredential.id, roleTypeId: roleTypeCredential.roleType.id, credentialTypeId: roleTypeCredential.credentialType.id })
                }}
            >
                <Icon icon={leftArrow} width={30} height={30} />
            </IconButton>
            <div>{defaultField.name}</div>
        </Grid>
    ))
}
const renderDefaults = (roleTypeCredentialValues, roleTypeCredential, personDefaults, firmDefaults, addRoleTypeCredentialValue) => {
    if (roleTypeCredential.credentialType.entity === "PERSON") {
        return renderDefaultArray(personDefaults, addRoleTypeCredentialValue, roleTypeCredential, roleTypeCredentialValues)
    }
    if (roleTypeCredential.credentialType.entity === "FIRM") {
        return renderDefaultArray(firmDefaults, addRoleTypeCredentialValue, roleTypeCredential, roleTypeCredentialValues)
    }
}
function addCustomField(open, handleClose, addMutationString, additionalVariables, shouldRefetch, snackbar, restrictedColumns) {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <GQLForm mutationString={addMutationString}
                    additionalVariables={additionalVariables}
                    inputOrder={{ name: { order: 1 }, type: { order: 2 } }}
                    skipFields={{ id: true, roleTypeId: true, credentialTypeId: true, roleTypeCredentialId: true, active: true, label: true, required: true, tier1: true, tier2: true, inputMask: true, helperText: true, placeholder: true }} onCompleted={() => {
                        shouldRefetch();
                        handleClose()
                        snackbar('Added Custom Field', 'success')
                    }} />
            </DialogContent>
        </Dialog>
    )
}

function immutableMove(handleUpdateRoleTypeCredential, arr, rowTransaction) {
    const from = rowTransaction.oldIndex
    const to = rowTransaction.targetIndex
    const newOrder = arr.reduce((prev, current, idx, self) => {
        if (from === to) {
            prev.push(current);
        }
        if (idx === from) {
            return prev;
        }
        if (from < to) {
            prev.push(current);
        }
        if (idx === to) {
            prev.push(self[from]);
        }
        if (from > to) {
            prev.push(current);
        }
        return prev;
    }, []);
    const roleTypeId = newOrder[0].roleTypeCredentialId;
    const idOrder = newOrder.map((e) => e.id);
    handleUpdateRoleTypeCredential({ id: roleTypeId, valueOrder: idOrder })
}

export default function RoleTypeCredentialView({ roleTypeCredential, refetchParent }) {
    const roleTypeCredentialId = roleTypeCredential.id;
    const [refetchTable, setRefetchTable] = useState(false);
    const [customFieldModal, setCustomFieldModal] = useState(false);
    const shouldRefetchTable = () => setRefetchTable(true)
    const openCustomFieldModal = () => setCustomFieldModal(true)
    const closeCustomFieldModal = () => setCustomFieldModal(false)
    const { enqueueSnackbar } = useSnackbar();
    const snackbar = (message, variant) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(message, { variant });
    };
    const [confirmation, setConfirmation] = useState({ open: false });
    const closeConfirmation = () => { setConfirmation({ open: false }) };
    const { loading, error, data, refetch } = createQuery('roleTypeCredentialValues', { active: true, roleTypeCredentialId: roleTypeCredentialId })
    const personDefaults = [{ name: "First Name", type: "TEXT" }, { name: "Last Name", type: "TEXT" }]
    const firmDefaults = [{ name: "Name", type: "TEXT" }, { name: "DBA", type: "TEXT" }]
    const addRoleTypeCredentialValue = generateMutation(refetch, snackbar, shouldRefetchTable);
    const handleDeleteRoleTypeCredential = deleteRoleTypeCredential(refetchParent, snackbar);
    const handleUpdateRoleTypeCredential = updateRoleTypeCredential(refetch, snackbar, shouldRefetchTable);
    if (loading)
        return <CircularProgress />
    if (error) {
        return <div>{`${error}`}</div>
    }
    if (data) {
        return (
            <Grid container>
                <Confirmation confirmation={confirmation} handleClose={closeConfirmation} />
                {addCustomField(customFieldModal, closeCustomFieldModal, "createRoleTypeCredentialValue", { roleTypeId: roleTypeCredential.roleType.id, credentialTypeId: roleTypeCredential.credentialType.id, roleTypeCredentialId: roleTypeCredential.id }, shouldRefetchTable, snackbar)}
                <Grid item xs={8} style={{ padding: '16pt' }}>
                    <Grid container direction="row" alignItems={"center"}>
                        <IconButton
                            className="revealOnHover"
                            onClick={() => {
                                setConfirmation({
                                    open: true,
                                    action: () => { handleDeleteRoleTypeCredential({ id: roleTypeCredential.id }) },
                                    actionText: `delete this ${roleTypeCredential.credentialType.name}?`
                                })
                            }}
                        >
                            <Delete color="disabled" />
                        </IconButton>
                        <Typography variant="h6">{`Credential Attributes for ${roleTypeCredential.credentialType.name}`}</Typography>
                    </Grid>
                    <GQLTable
                        queryString={'roleTypeCredentialValues'}
                        queryVariables={{ "active": true, "roleTypeCredentialId": roleTypeCredential.id }}
                        rowOrder={roleTypeCredential.valueOrder}
                        handleRowOrderChange={immutableMove.bind(null, handleUpdateRoleTypeCredential)}
                        deleteMutationString={'deleteRoleTypeCredentialValue'}
                        updateMutationString={'updateRoleTypeCredentialValue'}
                        columnOptions={{
                            "label": {
                                editable: true,
                                width: 150
                            },
                            "name": {
                                editable: true
                            },
                            "inputMask": {
                                headerName: "INPUT MASK",
                                editable: true,
                                width: 150
                            },
                            "helperText": {
                                headerName: "HELPER TEXT",
                                editable: true,
                                width: 150
                            },
                            "placeholder": {
                                editable: true,
                                width: 150
                            },
                            "tier1": {
                                headerName: "TIER 1",
                                type: 'boolean',
                                editable: true
                            },
                            "tier2": {
                                headerName: "TIER 2",
                                type: 'boolean',
                                editable: true
                            },
                            "required": {
                                type: 'boolean',
                                editable: true
                            },
                            "type": {
                                editable: false
                            }

                        }}
                        columnOrder={["name", "type", "label", "required", "tier1", "tier2", "inputMask", "helperText", "placeholder", "roleTypeCredentialId"]}
                        restrictedColumns={{ valueOrder: true, id: true, roleTypeId: true, roleType: true, credentialType: true, credentialTypeId: true, roleTypeCredentialId: true, active: true }}
                        shouldRefetch={refetchTable}
                        setShouldRefetch={setRefetchTable}
                    />
                </Grid>
                <Grid item container direction="column" justifyContent="flex-start" alignItems="flex-start" xs={4} style={{ padding: '16pt' }} >
                    <Typography variant="h6">Not Selected</Typography>
                    <Typography variant="h7">Field</Typography>
                    {renderDefaults(data.roleTypeCredentialValues, roleTypeCredential, personDefaults, firmDefaults, addRoleTypeCredentialValue)}
                    <Grid item container alignItems="center">
                        <IconButton
                            size="large"
                            color={'primary'}
                            onClick={openCustomFieldModal}
                        >
                            <Icon icon={leftArrow} width={30} height={30} />
                        </IconButton>
                        <div>{"[Custom Field]"}</div>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
