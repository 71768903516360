import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AddressSelect from "src/components/map/AddressSelect";
import AssignmentSelect from "./components/AssignmentSelect";
import Documentation from "./components/Documentation";
import { IFormInstance } from "./components/InspectionDisplay";
import PhotoDisplay from "./components/PhotoDisplay";
import { SyncDisplay } from "./components/SyncDisplay";
import Washouts from "./Washouts";
import MultiInspectionDisplay from "./components/InspectionDisplay";
import { OpenInNew } from "@mui/icons-material";

const INSPECTION_QUERY = gql`
  query Inspection($inspection: InputInspectionParams!) {
    inspection(inspection: $inspection) {
      id
      number
      contactPhone
      contactName
      inspectionLocation
      inspectionType {
        id
      }
      inspectionAssignments {
        id
        start
      }
    }
  }
`;

const INSPECTION_TYPE_QUERY = gql`
  query InspectionType($inspectionType: InputInspectionTypeParams) {
    inspectionType(inspectionType: $inspectionType) {
      id
      name
      inspectionTypeTemplates {
        id
        name
        description
        start
        end
        configuration
        isDeleted
      }
      isDeleted
    }
  }
`;

const THO_FORM_INSTANCES = gql`
  query ThoFormInstances($thoFormInstance: InputThoFormInstanceParams) {
    thoFormInstances(thoFormInstance: $thoFormInstance) {
      id
      inspectionId
      inspectionAssignmentId
      formId
      formConfigurationId
      inspectionTypeTemplateId
      reference
      referenceType
      formJson
      name
      type
      isDeleted
    }
  }
`;

export const InspectionCtx = createContext<any>(null);

interface ISyncHandler {
  [id: string]: boolean;
}

export default function InspectionAssignment() {
  const { inspectionId } = useParams();
  const { data, loading, error, refetch } = useQuery(INSPECTION_QUERY, {
    variables: { inspection: { id: inspectionId } },
  });
  const [inspectionTypeQuery, { data: inspectionTypeData }] = useLazyQuery(
    INSPECTION_TYPE_QUERY
  );

  const [thoFormInstances, setThoFormInstance] = useState<IFormInstance[]>([]);

  const [formInstancesQuery] = useLazyQuery(THO_FORM_INSTANCES, {
    onCompleted: (data) => {
      setThoFormInstance(data.thoFormInstances ?? []);
    },
  });

  const [assignmentId, setAssignmentId] = useState<string | null>(null);

  const [isSyncing, setIsSyncing] = useState<ISyncHandler>({});

  useEffect(() => {
    if (!loading && data) {
      if (data.inspection.inspectionAssignments.length > 0) {
        const getMostRecentInspectionAssignment = () => {
          let currentMostRecent: any;
          let index = 0;
          data.inspection.inspectionAssignments.forEach(
            (ia: any, i: number) => {
              if (i === 0) {
                currentMostRecent = ia.start;
              } else {
                if (ia.start > currentMostRecent) {
                  index = i;
                  currentMostRecent = ia.start;
                }
              }
            }
          );
          return data.inspection.inspectionAssignments[index].id;
        };
        setAssignmentId(getMostRecentInspectionAssignment());
      }

      inspectionTypeQuery({
        variables: {
          inspectionType: {
            id: data.inspection.inspectionType.id,
          },
        },
      });
    }
  }, [loading, data, inspectionTypeQuery]);

  useEffect(() => {
    if (assignmentId) {
      formInstancesQuery({
        variables: {
          thoFormInstance: { inspectionAssignmentId: assignmentId },
        },
      });
    }
  }, [assignmentId, formInstancesQuery]);

  const isLoading = Object.keys(isSyncing).some(
    (formInstance) => isSyncing[formInstance] === true
  );

  const updateSyncStatus = useCallback(
    (instanceId: string, syncStatus: boolean) => {
      setIsSyncing((currentState) => {
        return {
          ...currentState,
          [instanceId]: syncStatus,
        };
      });
    },
    []
  );

  return (
    <>
      {error && <Typography>{error.message}</Typography>}
      {loading && <CircularProgress />}
      {data && inspectionTypeData && (
        <InspectionCtx.Provider
          value={{ inspection: data.inspection, refetch: refetch }}
        >
          <SyncDisplay isLoading={isLoading}>
            <Stack direction="row" height="100%" spacing={2}>
              <Box maxWidth="sm" paddingTop="1rem" flex={1}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>
                    {inspectionTypeData.inspectionType.name}
                  </Typography>
                  {thoFormInstances && assignmentId && (
                    <PhotoDisplay assignmentId={assignmentId} />
                  )}
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  paddingBottom="0.5rem"
                >
                  {assignmentId ? (
                    <AssignmentSelect
                      possibleAssignments={
                        data.inspection.inspectionAssignments
                      }
                      selectedAssignmentId={assignmentId}
                      updateSelectedAssignment={(assignmentId) => {
                        setAssignmentId(assignmentId);
                      }}
                    />
                  ) : (
                    <Typography>No scheduled assignments.</Typography>
                  )}
                  <AddressSelect
                    initialConfig={{
                      type: "update",
                      addressId: data.inspection.inspectionLocation,
                      state: { type: "unloaded" },
                    }}
                  />
                </Stack>
                <Stack sx={{ backgroundColor: "#ECECEC" }} padding={1}>
                  <Washouts />
                </Stack>
                {inspectionTypeData.inspectionType.inspectionTypeTemplates[0]
                  .id !== null &&
                  thoFormInstances &&
                  thoFormInstances.length > 0 && (
                    <Stack direction="column">
                      <Link
                        style={{
                          alignSelf: "end",
                          paddingTop: "0.5rem",
                          paddingBottom: "0.5rem",
                        }}
                        to={`./${assignmentId}/${inspectionTypeData.inspectionType.id}/expanded`}
                        target="_blank"
                      >
                        <Button endIcon={<OpenInNew />}>Open Full View</Button>
                      </Link>
                      <MultiInspectionDisplay
                        thoFormInstances={thoFormInstances}
                        config={
                          inspectionTypeData.inspectionType
                            .inspectionTypeTemplates[0].configuration
                        }
                        updateSyncStatus={updateSyncStatus}
                      />
                    </Stack>
                  )}
              </Box>
              <Documentation />
            </Stack>
          </SyncDisplay>
        </InspectionCtx.Provider>
      )}
    </>
  );
}
