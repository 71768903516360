import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "src/utils/contexts/auth";
import { gql } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';

const GET_PERSON = gql`
query Person($person: InputPersonParams) {
  person(person: $person) {
    id
    avatar
    email
    firmId
    firstName
    lastName
    mailingAddressId
    phone1
    phone2
    preferences
    userId
  }}
`;
let PersonContext = React.createContext(null);

function PersonProvider({ children }) {
    let {token, user} = useContext(AuthContext);
    console.log("TOKEN: ", token)
    console.log("USER: ", user)
    const [person, setPerson] = useState(null);
    const [getPerson] = useLazyQuery(GET_PERSON, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            console.log("PERSON: ", response.person)
            setPerson(response.person)
        },
        onError: (response) => {
            debugger
        }
    });
    useEffect(
        () => {
            if (token && user && !person) {
                getPerson({ variables: {person: {userId: user.id} }})
            }
        },
        [token, user, person, getPerson]
    )
    let value = { person, setPerson };
    return <PersonContext.Provider value={value}>{children}</PersonContext.Provider>;
}

export { PersonContext, PersonProvider }

