import { Action, PageState } from "../../TypeDefinitions";

export function updateFormName<
  E extends Extract<
    Action,
    {
      type: "updateFormName";
    }
  >
>(action: E, draft: PageState): void {
  const { formName } = action.payload;

  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );

  draft.data.inspectionTypeTemplate.configuration.forms[formIndex].name =
    formName;
}
