import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import BackgroundCheck from '../../Onboarding/RoleRequest/BackgroundCheck'
// material
import { CircularProgress } from "@mui/material";
// components
import createQuery from "../../../utils/gql-mui-rhf/QueryAction";
// ----------------------------------------------------------------------


export default function AddBackgroundCheck({ open, selectionRecord, handleClose, project }) {
    const record = selectionRecord[0]
    if(!record) return null
    const { loading, error, data, refetch } = createQuery("rolesRequestByEntity", {
        entityId: record.id,
    });

    if (loading)
        return <CircularProgress />
    if (error) {
        return <div>{`${error}`}</div>;
    }
    if (data) {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >  <div style={{margin: 16}}>
                <BackgroundCheck rolesRequest={data.rolesRequestByEntity} refetchRolesRequest={refetch} />
                </div>
            </Dialog>
        );
    }
}