import { Action, PageState } from "../../TypeDefinitions";

export function moveField<
  E extends Extract<
    Action,
    {
      type: "moveField";
    }
  >
>(action: E, draft: PageState): void {
  const { oldDisplayIndex, newDisplayIndex } = action.payload;
  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );
  const pulledOutField = draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.splice(oldDisplayIndex, 1)[0];
  draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.splice(newDisplayIndex, 0, pulledOutField);

  draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.forEach((field, i) => (field.displayIndex = i));
}
