import TextField from "../../utils/mui-rhf/TextField";
import Address from "../../utils/mui-rhf/Address";
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from "react-hook-form";
import { Grid, Button, Dialog, DialogContent, DialogActions, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const CREATE_OFFEROR = gql`
mutation createOfferor($offeror: InputOfferorParams) {
    createOfferor(offeror: $offeror) {
      id
    }
  }
`


export default function Forms({ open, handleClose, project, projectId = null, projectOwner, refetch }) {
    const { enqueueSnackbar } = useSnackbar();
    const snackbar = (message, variant) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(message, { variant });
    };
    const methods = useForm({ ...project });
    const { handleSubmit } = methods;
    const [createOfferor] = useMutation(CREATE_OFFEROR, {
        onCompleted: () => {
            snackbar('Offeror Created', 'success');
            refetch();
            handleClose();
        }
    })


    const onSubmit = (values) => {

        createOfferor({
            variables: {
                offeror: {
                    projectId: projectId,
                    ...values
                }
            }
        })
    }
    return (
        <Dialog
            open={open}
            onClose={() => {
                methods.reset()
                handleClose()
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <div style={{ width: "100%" }}>
                            <Grid container spacing={2} padding={1}>
                                <Grid item xs={12}><Typography>Offeror Information</Typography></Grid>
                                <Grid item spacing={2} xs={12} >
                                    <TextField key={1} fullWidth rules={{ required: "Required Field" }} label={"Name"} name={"name"} />
                                </Grid>
                                <Grid item spacing={2} xs={12} >
                                    <TextField key={2} fullWidth rules={{ required: "Required Field" }} label={"Email"} name={"email"} />
                                </Grid>
                                <Grid item spacing={2} xs={12} >
                                    <TextField key={2} fullWidth rules={{ required: "Required Field" }} label={"Phone"} name={"phone"} />
                                </Grid>
                                <Grid item spacing={2} xs={12} >
                                    <Address
                                        methods={methods}
                                        group={'address.'}
                                        initialConfig={{ type: 'create' }}
                                        rules={
                                            {
                                                street1: { required: "Required Field" },
                                                city: { required: "Required Field" },
                                                state: { required: "Required Field" },
                                                zip: { required: "Required Field" }
                                            }
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}><Typography>Authorized Signer Information</Typography></Grid>
                                <Grid item spacing={2} xs={12} >
                                    <TextField key={4} fullWidth label={"Authorized Signer Name"} name={"authorizedSignerName"} />
                                </Grid>
                                <Grid item spacing={2} xs={12} >
                                    <TextField key={4} fullWidth label={"Authorized Signer Title"} name={"authorizedSignerTitle"} />
                                </Grid>
                                <Grid item spacing={2} xs={12} >
                                    <Address
                                        methods={methods}
                                        group={'remittanceAddress.'}
                                        initialConfig={{ type: 'create' }}
                                        rules={
                                            {
                                                street1: { required: "Required Field" },
                                                city: { required: "Required Field" },
                                                state: { required: "Required Field" },
                                                zip: { required: "Required Field" }
                                            }
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}><Typography>Administered Officer Information</Typography></Grid>
                                <Grid item spacing={2} xs={12} >
                                    <TextField key={1} fullWidth rules={{ required: "Required Field" }} label={"Administered Officer Name"} name={"administeredOfficerName"} />
                                </Grid>
                                <Grid item spacing={2} xs={12} >
                                    <TextField key={2} fullWidth rules={{ required: "Required Field" }} label={"Administered Officer Email"} name={"administeredOfficerEmail"} />
                                </Grid>
                                <Grid item spacing={2} xs={12} >
                                    <TextField key={2} fullWidth rules={{ required: "Required Field" }} label={"Administered Officer Phone"} name={"administeredOfficerPhone"} />
                                </Grid>
                                <Grid item spacing={2} xs={12} >
                                    <Address
                                        methods={methods}
                                        group={'administeredOfficerAddress.'}
                                        initialConfig={{ type: 'create' }}
                                        rules={
                                            {
                                                street1: { required: "Required Field" },
                                                city: { required: "Required Field" },
                                                state: { required: "Required Field" },
                                                zip: { required: "Required Field" }
                                            }
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}><Typography>Payment Agency Information</Typography></Grid>
                                <Grid item spacing={2} xs={12} >
                                    <TextField key={1} fullWidth rules={{ required: "Required Field" }} label={"Payment Agency Name"} name={"paymentAgencyName"} />
                                </Grid>
                                <Grid item spacing={2} xs={12} >
                                    <TextField key={2} fullWidth rules={{ required: "Required Field" }} label={"Payment Agency Email"} name={"paymentAgencyEmail"} />
                                </Grid>
                                <Grid item spacing={2} xs={12} >
                                    <TextField key={2} fullWidth rules={{ required: "Required Field" }} label={"Payment Agency Phone"} name={"paymentAgencyPhone"} />
                                </Grid>
                                <Grid item spacing={2} xs={12} >
                                    <Address
                                        methods={methods}
                                        group={'paymentAgencyAddress.'}
                                        initialConfig={{ type: 'create' }}
                                        rules={
                                            {
                                                street1: { required: "Required Field" },
                                                city: { required: "Required Field" },
                                                state: { required: "Required Field" },
                                                zip: { required: "Required Field" }
                                            }
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" type="submit">
                            {"Submit"}
                        </Button>
                        <Button onClick={() => {
                            methods.reset()
                            handleClose()
                        }}>Cancel</Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
}