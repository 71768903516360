import { Add } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import React, { useContext } from "react";
import { ReferenceCtx } from "..";

export const AddReferenceToListButton = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { setContext, context } = useContext(ReferenceCtx);

  const handleAddNew = (type: "document" | "freetext") => {
    setContext({
      ...context,
      config: {
        type: "add",
        referenceType: type,
      },
    });
    handleClose();
  };

  return (
    <>
      <Button
        size="small"
        endIcon={<Add />}
        variant="outlined"
        sx={{ borderRadius: 1000 }}
        onClick={handleClick}
      >
        Add
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleAddNew("document")}>Document</MenuItem>
        <MenuItem onClick={() => handleAddNew("freetext")}>Free Text</MenuItem>
      </Menu>
    </>
  );
};
