// material
import { useState, useCallback } from 'react';
import {
    DataGridPremium,
    GridToolbar
} from "@mui/x-data-grid-premium";
import { Box, Container, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
// components
import Page from '../../../components/Page';
// ----------------------------------------------------------------------
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import AddFromParent from './AddFromParent';

const GET_PERSONS = gql`
query Persons($ids: [ID]) {
  personsByIds(ids: $ids) {
    id
    avatar
    email
    firmId
    firstName
    lastName
    mailingAddressId
    phone1
    phone2
    preferences
    userId
  }}
`;
const GET_FIRM_PERSONS = gql`
query firmPersonsByIds($ids: [ID]) {
    firmPersonsByIds(ids: $ids) {
    id
    personId
    firm {
    id
    dba
    domain
    duns
    fax
    fein
    legalAbbreviation
    legalName
    mailingAddressId
    name
    owner
    phone1
    phone2
    physicalAddressId
    website
    }
  }
}
`
const GET_PROJECT_PERSONS = gql`
query ProjectPersons($projectId: ID) {
  projectPersons(projectId: $projectId) {
    id
    personId
    project {
        id
        name
    }
    fieldReady
  }
}
`

const UPDATE_PROJECT_PERSON = gql`
    mutation updateProjectPerson($projectPerson: InputProjectPersonParams) {
        updateProjectPerson(projectPerson: $projectPerson) {
                id
                personId
                project {
                    id
                    name
                }
                fieldReady
        }
    }
`

const AddPersons = (project, merged, open, handleClose) => {
    return (
        <Dialog
            maxWidth={'xl'}
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Add Parent Project People
            </DialogTitle>
            <DialogContent>
                <AddFromParent project={project} subProjectPersons={merged} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    handleClose()
                }} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default function ProjectPeople({ project }) {
    const [persons, setPersons] = useState([]);
    const [firmPersons, setFirmPersons] = useState([]);
    const [open, setOpen] = useState(false);
    const handleClose = () => { setOpen(false) }
    let merged = [];

    const [getPersons] = useLazyQuery(GET_PERSONS, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setPersons(response.personsByIds)
        }
    });
    const [getFirmPersons] = useLazyQuery(GET_FIRM_PERSONS, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setFirmPersons(response.firmPersonsByIds)
        }, onError: (e) => {
        }
    });
    const { loading, error, data } = useQuery(GET_PROJECT_PERSONS, {
        variables: { projectId: project.id },
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            getPersons({ variables: { ids: response.projectPersons.map((person) => person.personId) } })
            getFirmPersons({ variables: { ids: response.projectPersons.map((person) => person.personId) } })
        }
    });
    const [updateProjectPerson] = useMutation(UPDATE_PROJECT_PERSON)
    const processRowUpdate = useCallback(
        async (newRow) => {
            const projectPerson = { id: newRow.id, fieldReady: newRow.fieldReady }
            updateProjectPerson({ variables: { projectPerson } });
        },
        [updateProjectPerson]
    );
    const handleProcessRowUpdateError = useCallback((error) => { }, []);

    const formatFirm = (firmPerson) => ({
        firmPersonId: firmPerson.id,
        firmName: firmPerson.firm.name,
        firmId: firmPerson.firm.id,
    })

    if (!project) { return null }
    if (loading) {
        return null
    }
    if (error) {
        debugger
    }
    if (data && data.projectPersons && (persons && persons.length > 0) && (firmPersons && firmPersons.length > 0)) {
        for (let i = 0; i < data.projectPersons.length; i++) {
            merged.push({
                ...(persons.find((itmInner) => itmInner.id === data.projectPersons[i].personId)),
                ...(formatFirm(firmPersons.find((itmInner) => itmInner.personId === data.projectPersons[i].personId))),
                ...data.projectPersons[i]
            }
            );
        }
    }

    return (
        <Page title="Projects | FINBACK670">
            <Container maxWidth="xl">
                {AddPersons(project, merged, open, handleClose)}
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">{`Manage People for ${project.name}`}</Typography>
                </Box>

                {project.parentProject?.id && <Button onClick={() => { setOpen(true) }} variant="outlined">Add Persons from Parent Project</Button>}
                <div style={{ height: 600, width: "100%" }}>
                    <DataGridPremium
                        rows={merged}
                        loading={!project}
                        columns={[
                            { editable: true, type: 'boolean', field: 'fieldReady', headerName: "Field Ready?" },
                            { editable: false, field: 'firstName', headerName: "First Name" },
                            { editable: false, field: 'lastName', headerName: "Last Name" },
                            { editable: false, field: 'firmName', headerName: "Firm" },
                            { editable: false, field: 'email', headerName: "Email" },
                            { editable: false, field: 'phone1', headerName: "Contact Phone" },
                        ]}
                        pageSize={5}
                        pageSizeOptions={[10, 25, 50]}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        slots={{ toolbar: GridToolbar }}
                    />
                </div>
            </Container>
        </Page>
    );
}
