import { useContext } from "react";
import { FirmContext } from "../../../utils/contexts/firm";
import GQLTable from '../../../utils/gql-mui-rhf/Table';
import { useNavigate } from "react-router-dom";

// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
// ----------------------------------------------------------------------

export default function FirmUnits(props) {
    const navigate = useNavigate()
    let firmId
    let firmUnitId = props.firmUnitId
    let firmContext = useContext(FirmContext);
    if (props.firmId) {
        firmId = props.firmId
    } else {
        firmId = firmContext.firmId
    }
    return (
        <Page title="Firm Units | FINBACK670">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">Firm Units</Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <GQLTable
                            queryString={'firmUnits'}
                            queryVariables={{ "firmId": firmId, "firmUnitId": firmUnitId }}
                            addMutationVariables={{ "firmId": firmId, "firmUnitId": firmUnitId, mailingAddressId: null, physicalAddressId: null }}
                            addMutationString={'createFirmUnit'}
                            deleteMutationString={'deleteFirmUnit'}
                            updateMutationString={'updateFirmUnit'}
                            updateModal={true}
                            restrictedColumns={{ firmId: true, mailingAddress: true, physicalAddress: true, mailingAddressId: true, physicalAddressId: true}}
                            onRowClick={(rowData) => {
                                navigate(`/app/admin/firm-unit/${rowData.id}`)
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
