import { getPersonQueryComponents } from "./getPersonQueryComponents";

export const processPersonIdsIntoQueryComponents = (
  inspectionAssignments: any[]
) => {
  let personIds: string[] = [];
  function addIfNotPresent(personId: string) {
    if (!personIds.includes(personId)) {
      personIds.push(personId);
    }
  }
  inspectionAssignments.forEach((inspection: any) => {
    const inspectionAssignmentLength = inspection.inspectionAssignments.length;
    switch (inspectionAssignmentLength) {
      case 0:
        return;
      case 1:
        addIfNotPresent(inspection.inspectionAssignments[0].personId);
        break;
      default:
        let mostRecent = inspection.inspectionAssignments.reduce(
          (a: any, b: any) => (a.start > b.start ? a : b)
        );
        addIfNotPresent(mostRecent.personId);
    }
  });
  return getPersonQueryComponents(personIds);
};
