import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
  Alert,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ReferenceCtx, ReferenceMaterial } from "..";
import MDEditor from "@uiw/react-md-editor";
import { FormCtx } from "../../FormWrapper/BuilderWrapper";
import { Close, Delete } from "@mui/icons-material";
import { DocumentationHandler } from "./DocumentationHandler";

const UPLOAD_REFERENCE_MATERIAL = gql`
  mutation UploadReferenceMaterial(
    $referenceMaterial: InputReferenceMaterialParams
  ) {
    uploadReferenceMaterial(referenceMaterial: $referenceMaterial) {
      id
    }
  }
`;

const GET_REFERENCE_MATERIAL_BY_ID = gql`
  query ReferenceMaterials($referenceMaterial: InputReferenceMaterialParams!) {
    referenceMaterials(referenceMaterial: $referenceMaterial) {
      id
      documentId
      stringContents
      isDeleted
      title
    }
  }
`;

const DELETE_REFERENCE_MATERIAL = gql`
  mutation DeleteReferenceMaterial($deleteReferenceMaterialId: ID!) {
    deleteReferenceMaterial(id: $deleteReferenceMaterialId) {
      id
      documentId
      stringContents
      isDeleted
      insertedAt
      updatedAt
      title
    }
  }
`;

const CREATE_DOCUMENT = gql`
  mutation CreateDocument($document: InputDocumentParams) {
    createDocument(document: $document) {
      id
      title
      description
      documentBytes
      url
      fileExtension
      fileName
      isDeleted
      insertedAt
      updatedAt
    }
  }
`;

const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($document: InputDocumentParams) {
    updateDocument(document: $document) {
      id
      title
      description
      documentBytes
      url
      fileExtension
      fileName
      isDeleted
      insertedAt
      updatedAt
    }
  }
`;

export const ReferenceEditor = () => {
  const { dispatch } = useContext(FormCtx);
  const { context, setContext } = useContext(ReferenceCtx);
  const [errMessage, setErrMessage] = useState<string | null>(null);
  const [file, setFile] = useState<Blob | null>(null);

  const [reference, setReference] = useState<{
    title: string;
    id: string;
    stringContents: string | null;
    documentId?: string | null;
    [x: string]: any;
  } | null>(null);

  const [getReference, { data, loading }] = useLazyQuery<{
    referenceMaterials: ReferenceMaterial[];
  }>(GET_REFERENCE_MATERIAL_BY_ID, {
    onCompleted: (res) => {
      setReference(res.referenceMaterials[0]);
    },
    fetchPolicy: "network-only",
  });

  const [createDocument] = useMutation(CREATE_DOCUMENT);
  const [updateDocument] = useMutation(UPDATE_DOCUMENT);

  const [deleteReferenceMaterial] = useMutation(DELETE_REFERENCE_MATERIAL);

  const [createReferenceMaterial] = useMutation(UPLOAD_REFERENCE_MATERIAL, {
    onCompleted: (data) => {
      dispatch({
        type: "addReferenceMaterial",
        payload: {
          referenceMaterialId: data.uploadReferenceMaterial.id,
        },
      });
      setContext({
        ...context,
        config: {
          type: "edit",
          selectedId: data.uploadReferenceMaterial.id,
        },
      });
    },
    onError: (e) => {
      setErrMessage(e.message);
    },
  });

  const { config } = context;
  const handleClick = async () => {
    const date = new Date(Date.now());
    const isoString = date.toISOString();
    let referenceWithDate: any = { ...reference, updatedAt: isoString };
    if (config.type === "add") {
      referenceWithDate = { ...referenceWithDate, insertedAt: isoString };
    }

    if (
      context.config.type === "add" &&
      context.config.referenceType === "document" &&
      file
    ) {
      const id = await pushFileReturnId({
        fileInput: file,
      });
      referenceWithDate.documentId = id;
    }

    console.log("referenceWithDate");
    console.log(referenceWithDate);
    delete referenceWithDate["__typename"];
    createReferenceMaterial({
      variables: { referenceMaterial: referenceWithDate },
    });
  };

  const handleDelete = async () => {
    if (!reference) return;
    try {
      await deleteReferenceMaterial({
        variables: {
          deleteReferenceMaterial: {
            id: reference.id,
          },
        },
      });
    } catch (err) {
      console.log(err);
    }
    setContext({
      ...context,
      config: {
        type:
          context.referenceMaterialIds.length === 1
            ? "promptCreate"
            : "unselected",
      },
    });
    dispatch({
      type: "deleteReferenceMaterial",
      payload: { referenceMaterialId: reference.id },
    });
  };

  useEffect(() => {
    if (context.config.type === "unselected") {
      setReference(null);
    }
    if (context.config.type === "edit") {
      getReference({
        variables: {
          referenceMaterial: {
            id: context.config.selectedId,
          },
        },
      });
    }
    if (context.config.type === "add") {
      let now = new Date(Date.now());
      let nowString = now.toISOString();
      setReference({
        id: uuidv4(),
        insertedAt: nowString,
        updatedAt: nowString,
        title: "",
        isDeleted: false,
        documentId: null,
        stringContents: context.config.referenceType === "freetext" ? "" : null,
      });
    }
  }, [context.config, getReference]);

  const pushFileReturnId = async ({
    fileInput,
  }: {
    fileInput: Blob;
  }): Promise<string | null> => {
    if (!reference) return null;
    // const base64 = await toBase64(fileInput);
    const base64 = await fileInput.text();
    if (!base64 || typeof base64 !== "string") return null;

    const extension = /(?:\.([^.]+))?$/.exec(fileInput.name);
    const fileName = fileInput.name;
    // const fileName = fileInput.name.replace(/\.[^/.]+$/, "");

    const documentId = reference?.documentId;

    const promRes = documentId
      ? updateDocument({
          variables: {
            document: {
              id: documentId,
              title: reference.title,
              description: reference.title,
              documentBytes: base64,
              fileExtension: extension,
              fileName: fileName,
            },
          },
          onError: (e) => {
            debugger;
          },
        })
      : createDocument({
          variables: {
            document: {
              title: reference.title,
              description: reference.title,
              documentBytes: base64,
              fileExtension: extension ? extension[0] : ".txt",
              fileName: fileName,
            },
          },
          onError: (e) => {
            debugger;
          },
        });

    const res: any = await promRes;

    let responseObject: any = Object.values(res.data)[0];
    let id = responseObject.id;

    return id;
  };

  const undoChanges = () => {
    data && setReference(data.referenceMaterials[0]);
  };

  const disabled =
    !reference ||
    (!reference.stringContents && !file) ||
    !reference.title ||
    (config.type === "edit" &&
      data &&
      reference &&
      data.referenceMaterials[0].stringContents === reference.stringContents &&
      data.referenceMaterials[0].title === reference.title);

  return (
    <Stack
      sx={{ height: 400, width: 600 }}
      direction="column"
      spacing={2}
      alignItems={"stretch"}
      paddingTop={"1rem"}
    >
      <>
        {loading ? (
          <CircularProgress />
        ) : reference !== null ? (
          <>
            <TextField
              label="Title"
              value={reference.title}
              onChange={(e) =>
                setReference({ ...reference, title: e.target.value })
              }
            />
            {typeof reference.stringContents === "string" ? (
              <MDEditor
                data-color-mode="light"
                value={reference.stringContents}
                onChange={(e) =>
                  setReference({ ...reference, stringContents: e || "" })
                }
              />
            ) : (
              <DocumentationHandler
                title={reference.title}
                setReference={setReference}
                reference={reference}
                file={file}
                setFile={setFile}
              />
            )}
            <div style={{ flex: 1 }} />
            {errMessage && (
              <Alert
                severity="error"
                action={
                  <IconButton onClick={(e) => setErrMessage(null)}>
                    <Close />
                  </IconButton>
                }
              >
                {errMessage}
              </Alert>
            )}
            <Stack direction="row" justifyContent="end" spacing={1}>
              {config.type === "edit" && (
                <Button
                  color="warning"
                  onClick={handleDelete}
                  endIcon={<Delete />}
                >
                  Delete
                </Button>
              )}
              {<div style={{ flex: 1 }}></div>}
              {!disabled && config.type === "edit" && (
                <Button onClick={undoChanges}>Undo Changes</Button>
              )}
              {config.type === "add" && (
                <Button
                  onClick={() =>
                    setContext({
                      ...context,
                      config: {
                        type:
                          context.referenceMaterialIds.length === 0
                            ? "promptCreate"
                            : "unselected",
                      },
                    })
                  }
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="contained"
                onClick={handleClick}
                disabled={disabled}
              >
                {config.type === "edit" ? "Update" : "Add"}
              </Button>
            </Stack>
          </>
        ) : (
          <Stack
            height={"100%"}
            width={"100%"}
            justifyContent="center"
            alignItems="center"
          >
            No reference material selected.
          </Stack>
        )}
      </>
    </Stack>
  );
};

// const toBase64 = (file: Blob) =>
//   new Promise<string | ArrayBuffer | null>((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => {
//       let res = null;
//       if (reader.result && typeof reader.result === "string") {
//         res = reader.result;
//         // res = reader.result.split(",")[1];
//       }
//       console.log("UPLOADED BLOB");
//       console.log(reader.result);
//       console.log(res);
//       resolve(res);
//     };
//     reader.onerror = (error) => reject(error);
//   });
