import TextField from "../../../utils/mui-rhf/TextField";
import Address from "../../../utils/mui-rhf/Address";
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from "react-hook-form";
import { Grid, Button, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const UPDATE_OFFEROR = gql`
mutation updateOfferor($offeror: InputOfferorParams) {
    updateOfferor(offeror: $offeror) {
      offerorLocation {
        id
      }
      id
    }
  }
`
const offerorLocationParams = (offeror) => {
    const allowedProps = ["city", "description", "mapboxId", "name", "owner", "state", "street1", "street2", "street3", "tenant", "zip", "lat", "lng", "neighborhood"]
    const result = {};

    for (const prop of allowedProps) {
        if (offeror.hasOwnProperty(prop) && !!offeror[prop]) {
            result[prop] = offeror[prop];
        }
    }
    return result;
}
const offerorParams = (offeror) => {
    const allowedProps = [
        "name",
        "phone",
        "email",
        "authorizedSignerName",
        "authorizedSignerTitle",
        "administeredOfficerName",
        "administeredOfficerEmail",
        "administeredOfficerPhone",
        "paymentAgencyName",
        "paymentAgencyEmail",
        "paymentAgencyPhone",
        'address',
        'administeredOfficerAddress',
        'remittanceAddress',
        'paymentAgencyAddress'

    ];
    const result = {};

    for (const prop of allowedProps) {
        if (offeror.hasOwnProperty(prop) && !!offeror[prop]) {
            if (prop === 'address' || prop === 'administeredOfficerAddress' || prop === 'remittanceAddress' || prop === 'paymentAgencyAddress') {
                result[prop] = offerorLocationParams(offeror[prop])
            } else {
                result[prop] = offeror[prop];
            }
        }
    }
    return result;
}

export default function Forms({ offeror, refetch }) {
    const { enqueueSnackbar } = useSnackbar();
    const snackbar = (message, variant) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(message, { variant });
    };
    const defaultValues = offerorParams(offeror)
    const methods = useForm({ defaultValues });
    const { handleSubmit } = methods;
    const [updateOfferor] = useMutation(UPDATE_OFFEROR, {
        onCompleted: () => {
            snackbar('Offeror Updated', 'success');
            refetch();
        }
    })


    const onSubmit = (values) => {
        updateOfferor({
            variables: {
                offeror: {
                    id: offeror.id,
                    ...values
                }
            }
        })
    }
    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ width: "100%" }}>
                    <Grid container spacing={2} padding={1}>
                        <Grid item xs={12}><Typography variant="h5">Offeror Information</Typography></Grid>
                        <Grid item spacing={2} xs={12} sm={6} md={4}  >
                            <TextField key={1} fullWidth rules={{ required: "Required Field" }} label={"Name"} name={"name"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} sm={6} md={4}  >
                            <TextField key={2} fullWidth rules={{ required: "Required Field" }} label={"Email"} name={"email"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} sm={6} md={4}  >
                            <TextField key={2} fullWidth rules={{ required: "Required Field" }} label={"Phone"} name={"phone"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} sm={6} md={4}  >
                            <Address
                                methods={methods}
                                group={'address.'}
                                initialConfig={{ type: 'create' }}
                                rules={
                                    {
                                        street1: { required: "Required Field" },
                                        city: { required: "Required Field" },
                                        state: { required: "Required Field" },
                                        zip: { required: "Required Field" }
                                    }
                                }
                            />
                        </Grid>
                        <Grid item xs={12} ><Typography variant="h5">Authorized Signer Information</Typography></Grid>
                        <Grid item spacing={2} xs={12} sm={6} md={4}  >
                            <TextField key={4} fullWidth label={"Authorized Signer Name"} name={"authorizedSignerName"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} sm={6} md={4}  >
                            <TextField key={4} fullWidth label={"Authorized Signer Title"} name={"authorizedSignerTitle"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} sm={6} md={4}  >
                            <Address
                                methods={methods}
                                group={'remittanceAddress.'}
                                initialConfig={{ type: 'create' }}
                                rules={
                                    {
                                        street1: { required: "Required Field" },
                                        city: { required: "Required Field" },
                                        state: { required: "Required Field" },
                                        zip: { required: "Required Field" }
                                    }
                                }
                            />
                        </Grid>
                        <Grid item xs={12}><Typography variant="h5">Administered Officer Information</Typography></Grid>
                        <Grid item spacing={2} xs={12} sm={6} md={4}  >
                            <TextField key={1} fullWidth rules={{ required: "Required Field" }} label={"Administered Officer Name"} name={"administeredOfficerName"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} sm={6} md={4}  >
                            <TextField key={2} fullWidth rules={{ required: "Required Field" }} label={"Administered Officer Email"} name={"administeredOfficerEmail"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} sm={6} md={4}  >
                            <TextField key={2} fullWidth rules={{ required: "Required Field" }} label={"Administered Officer Phone"} name={"administeredOfficerPhone"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} sm={6} md={4}  >
                            <Address
                                methods={methods}
                                group={'administeredOfficerAddress.'}
                                initialConfig={{ type: 'create' }}
                                rules={
                                    {
                                        street1: { required: "Required Field" },
                                        city: { required: "Required Field" },
                                        state: { required: "Required Field" },
                                        zip: { required: "Required Field" }
                                    }
                                }
                            />
                        </Grid>
                        <Grid item xs={12}><Typography variant="h5">Payment Agency Information</Typography></Grid>
                        <Grid item spacing={2} xs={12} sm={6} md={4}  >
                            <TextField key={1} fullWidth rules={{ required: "Required Field" }} label={"Payment Agency Name"} name={"paymentAgencyName"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} sm={6} md={4}  >
                            <TextField key={2} fullWidth rules={{ required: "Required Field" }} label={"Payment Agency Email"} name={"paymentAgencyEmail"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} sm={6} md={4}  >
                            <TextField key={2} fullWidth rules={{ required: "Required Field" }} label={"Payment Agency Phone"} name={"paymentAgencyPhone"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} sm={6} md={4}  >
                            <Address
                                methods={methods}
                                group={'paymentAgencyAddress.'}
                                initialConfig={{ type: 'create' }}
                                rules={
                                    {
                                        street1: { required: "Required Field" },
                                        city: { required: "Required Field" },
                                        state: { required: "Required Field" },
                                        zip: { required: "Required Field" }
                                    }
                                }
                            />
                        </Grid>
                    </Grid>
                </div>
                <Button variant="contained" type="submit">
                    {"Update"}
                </Button>
            </form>
        </FormProvider>
    );
}