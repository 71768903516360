// material
import { Box, Grid, Container, Typography, Button } from "@mui/material";
import { GQLMutationAction } from '../utils/gql-mui-rhf/SingleMutation';
// components
import Page from "../components/Page";
import Address from "../components/radar/address";
// ----------------------------------------------------------------------
function generateMutation(refetch, snackbar, shouldRefetch) {
  return GQLMutationAction({
      mutationString: "createEmail",
      onCompleted: (response) => {
      }
  })
}
export default function Messaging() {
  const sendEmailTest = generateMutation();
  return (
    <Page title="Dashboard | FINBACK670">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Messaging UI Examples</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Address
              callback={(value) => {
                debugger;
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
              <Button onClick={()=> {sendEmailTest({ templateId: "d-6bf594f3b39f44378288c6d254c81d6e", to: "msteiner531@gmail.com", data: JSON.stringify({password: "whAl4Tqn"}) })}}>Test Email API</Button>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
