import React from 'react';
import CredentialFieldGroup from './CredentialFieldGroup';

export default function CredentialFields({ draftCredential, defaultCredentialValues, draftCredentialValues, activeField, setActiveField, addDraftCredentialValue }) {
    let credential = { ...draftCredential.credentialType };
    if (!credential.credentialTypeValues) { return null }
    let fieldGroups = [...credential.credentialTypeValues]
    const order = credential.valueOrder;
    if (order) {
        const arrayMap = fieldGroups.reduce(
            (accumulator, currentValue) => ({
                ...accumulator,
                [currentValue.id]: currentValue,
            }),
            {}
        );
        let orderedRows = order.map(key => arrayMap[key]);
        let unorderedRows = fieldGroups.filter(item => !order.includes(item.id))
        fieldGroups = [...orderedRows, ...unorderedRows]
    }
    // Clean any undefined row elements
    fieldGroups = fieldGroups.filter(Boolean)
    fieldGroups = fieldGroups.filter(el => el.active)
    if (fieldGroups.length < 1) { return null }
    return <>
        {fieldGroups.map((credentialTypeValue, idx) => {
            return credentialTypeValue.active &&
                <CredentialFieldGroup
                    key={idx}
                    defaultValues={defaultCredentialValues}
                    fieldStatus={draftCredentialValues[credentialTypeValue.name] ? draftCredentialValues[credentialTypeValue.name].status : "pending"}
                    draftCredential={draftCredential}
                    credentialTypeValue={credentialTypeValue}
                    activeField={activeField}
                    setActiveField={setActiveField}
                    addDraftCredentialValue={addDraftCredentialValue}
                />
        })}
    </>


}