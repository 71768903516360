import { Action, PageState } from "../../TypeDefinitions";

export function linkSubForm<
  E extends Extract<
    Action,
    {
      type: "linkSubForm";
    }
  >
>(action: E, draft: PageState): void {
  const { subFormId, parentFormId } = action.payload;

  draft.data.inspectionTypeTemplate.configuration.forms.every((form) => {
    if (form.id === parentFormId) {
      form.subformIds.push(subFormId);
      return false;
    }
    return true;
  });
}
