import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import React, { FC, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { PersonContext } from "src/utils/contexts/person";
import { InspectionCtx } from ".";
import { fDateTimeSuffix } from "src/utils/formatTime";

const WASHOUTS_QUERY = gql`
  query Washouts($washout: InputWashoutParams!) {
    washouts(washout: $washout) {
      id
      assignedPersonId
      currentStatus
      description
      isDeleted
      referenceId
      referenceType
      reportedDate
      reportingPersonId
      title
      inspectionId
      inspectionAssignmentId
    }
  }
`;

type CreateWashout =
  | {
      type: "closed";
    }
  | {
      type: "open";
      description: string;
      title: string;
      assignedPersonId: string;
      currentStatus: string;
      isDeleted: boolean;
      reportedDate: string;
      inspectionId: string;
      inspectionAssignmentId: string;
      referenceId: string;
      referenceType: string;
      reportingPersonId: string;
    };

export default function Washouts() {
  const { inspection } = useContext<any>(InspectionCtx);
  const { person } = useContext<any>(PersonContext);
  const { inspectionId } = useParams();
  const [createWashout, setCreateWashout] = useState<CreateWashout>({
    type: "closed",
  });
  const { data, error, refetch } = useQuery(WASHOUTS_QUERY, {
    variables: { washout: { inspectionId: inspectionId } },
    fetchPolicy: "network-only",
  });

  const [createMutation] = useMutation(CREATE_WASHOUT);

  async function handleCreate() {
    const newWashoutObj: any = createWashout;
    delete newWashoutObj.type;
    const date = new Date(Date.now()).toISOString();
    newWashoutObj.reportedDate = date;
    createMutation({ variables: { washout: { ...createWashout } } }).then(
      (res) => refetch()
    );
  }

  if (createWashout.type === "open") {
    console.log(person);
  }
  return (
    <>
      {error && <p>{error.message}</p>}
      {inspection && data && (
        <>
          <Stack direction="row" alignItems="start" spacing={1}>
            <Accordion sx={{ flex: 1 }}>
              <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                <Typography>{data.washouts.length || "No"} washouts</Typography>
                {/* <div style={{ flex: 1 }} />

<div
style={{
  backgroundColor: "pink",
  padding: "0 0.25rem",
  borderRadius: 5,
}}
>
<Typography variant="subtitle2" color="darkred">
3 Open
</Typography>
</div> */}
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="column">
                  {data.washouts.map((washout: any, i: number) => (
                    <Washout even={i % 2 === 0} washout={washout} />
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Button
              variant="contained"
              sx={{ height: "3rem", borderRadius: "8" }}
              onClick={(e) =>
                setCreateWashout({
                  type: "open",
                  title: "",
                  description: "",
                  assignedPersonId: "",
                  isDeleted: false,
                  reportedDate: "2022-12-02T15:41:42Z",
                  referenceId: "61553380-770c-11ed-835b-3ba24caa99f6",
                  referenceType: "washout",
                  reportingPersonId: "7ff9591c-3d53-46ff-8513-2ceda963cb6e",
                  currentStatus: "open",
                  inspectionId: inspection.id,
                  inspectionAssignmentId:
                    inspection.inspectionAssignments[0].id,
                })
              }
            >
              Add
            </Button>
          </Stack>
          <Dialog
            open={createWashout.type === "open"}
            onClose={(e) => setCreateWashout({ type: "closed" })}
            fullWidth
          >
            <DialogTitle>Create New Washout</DialogTitle>
            <DialogContent>
              <Stack
                width="100%"
                direction="column"
                spacing={2}
                paddingY="1rem"
              >
                <PersonSelector
                  assignedTo={
                    createWashout.type === "open"
                      ? createWashout.assignedPersonId
                      : ""
                  }
                  onChange={(personId) =>
                    createWashout.type === "open" &&
                    setCreateWashout({
                      ...createWashout,
                      assignedPersonId: personId,
                    })
                  }
                />
                <TextField
                  fullWidth
                  label="Title"
                  multiline
                  value={
                    createWashout.type === "open" ? createWashout.title : ""
                  }
                  onChange={(e) =>
                    createWashout.type === "open" &&
                    setCreateWashout({
                      ...createWashout,
                      title: e.target.value,
                    })
                  }
                />
                <TextField
                  fullWidth
                  label="Description"
                  onChange={(e) =>
                    createWashout.type === "open" &&
                    setCreateWashout({
                      ...createWashout,
                      description: e.target.value,
                    })
                  }
                  value={
                    createWashout.type === "open"
                      ? createWashout.description
                      : ""
                  }
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => handleCreate()}>Create</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}

const CREATE_WASHOUT = gql`
  mutation CreateWashout($washout: InputWashoutParams) {
    createWashout(washout: $washout) {
      id
      assignedPersonId
      currentStatus
      description
      isDeleted
      referenceId
      referenceType
      reportedDate
      reportingPersonId
      title
      inspectionId
      inspectionAssignmentId
    }
  }
`;

const UPDATE_WASHOUT = gql`
  mutation UpdateWashout($washout: InputWashoutParams) {
    updateWashout(washout: $washout) {
      id
      assignedPersonId
      currentStatus
      description
      isDeleted
      referenceId
      referenceType
      reportedDate
      reportingPersonId
      title
      inspectionId
      inspectionAssignmentId
    }
  }
`;

const Washout: FC<{
  even: boolean;
  washout: any;
}> = ({ even, washout }) => {
  const [updateWashout, { data: updateData }] = useMutation(UPDATE_WASHOUT);
  const { id, assignedPersonId, description, title } =
    updateData?.data?.updateWashout || washout;
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="0.5rem"
      >
        <PersonSelector
          assignedTo={assignedPersonId}
          onChange={(personId) =>
            updateWashout({
              variables: { washout: { id: id, assignedPersonId: personId } },
            })
          }
        />
        <div
          style={{
            backgroundColor:
              washout.currentStatus === "open" ? "pink" : "lightgreen",
            padding: "0 0.25rem",
            borderRadius: 5,
          }}
        >
          <Typography
            variant="subtitle2"
            color={
              washout.currentStatus === "open" ? "darkred" : "darkolivegreen"
            }
          >
            {washout.currentStatus}
          </Typography>
        </div>
      </Stack>
      <Typography fontWeight="bold">{title}</Typography>
      <Typography>{description}</Typography>
      <Typography variant="subtitle2">{`${fDateTimeSuffix(
        washout.reportedDate
      )}`}</Typography>
    </>
  );
};

const PersonSelector: FC<{
  assignedTo: string;
  onChange: (personId: string) => any;
}> = ({ assignedTo, onChange }) => {
  const { data } = useQuery(GET_PERSONS, {
    variables: {
      person: {
        firmId: "f6c94235-3e00-4896-8bbd-7c0f20ddc2a8",
      },
    },
  });

  return (
    <>
      {data && (
        <Select
          onChange={(e) => onChange(e.target.value as string)}
          size="small"
          value={assignedTo}
          sx={{ borderRadius: 100, width: "13rem" }}
          renderValue={(value) => {
            const selectedPerson = data.persons.find(
              (person: any) => person.id === value
            );
            if (!selectedPerson) {
              return null;
            }
            return (
              <ListItemText
                primary={`${selectedPerson.firstName} ${selectedPerson.lastName}`}
              />
            );
          }}
        >
          {data.persons.map((person: any) => (
            <MenuItem value={person.id}>
              <ListItemText
                primary={`${person.firstName} ${person.lastName}`}
              />
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};

const GET_PERSONS = gql`
  query Persons($person: InputPersonParams) {
    persons(person: $person) {
      id
      avatar
      email
      firmId
      firstName
      lastName
      mailingAddressId
      phone1
      phone2
      preferences
      userId
      isDeleted
    }
  }
`;
