import { TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";

export const MUIRHFDate = ({
  label,
  name,
  description,
  disabled,
  defaultValue,
  rules,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      control={control}
      render={({ field, fieldState: { error, invalid } }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label={label}
              {...field}
              value={field.value || null}
              disabled={disabled || false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={error?.message || description || null}
                  error={invalid}
                />
              )}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};

export default MUIRHFDate;
