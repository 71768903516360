import React from "react";
// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";
import SchemaContext from "./utils/gql-mui-rhf/SchemaContext";
import SchemaDefinition from "./utils/gql-mui-rhf/SchemaDefinition";
import ProcessSchema from "./utils/gql-mui-rhf/ProcessSchema";
import { AuthProvider } from "./utils/contexts/auth";
import { FirmProvider } from "./utils/contexts/firm";
import { FirmUnitProvider } from "./utils/contexts/firmUnit";
import { ProjectProvider } from "./utils/contexts/project";
import { PersonProvider } from "./utils/contexts/person";
import TimeoutTimer from './components/authentication/TimeoutTimer'
import Radar from 'radar-sdk-js';
import 'mapbox-gl/dist/mapbox-gl.css';

// ----------------------------------------------------------------------
Radar.initialize(process.env.REACT_APP_RADAR_PUBLISHABLE_KEY);

export default function App() {
  const { data, loading } = SchemaDefinition();
  if (loading) return null;
  const processedSchema = ProcessSchema(data);
  return (
    <AuthProvider>
      <SchemaContext.Provider value={processedSchema}>
        <PersonProvider>
          <FirmProvider>
            <FirmUnitProvider>
              <ProjectProvider>
                <ThemeConfig>
                  <TimeoutTimer />
                  <ScrollToTop />
                  <GlobalStyles />
                  <BaseOptionChartStyle />
                  <Router />
                </ThemeConfig>
              </ProjectProvider>
            </FirmUnitProvider>
          </FirmProvider>
        </PersonProvider>
      </SchemaContext.Provider>
    </AuthProvider>
  );
}
