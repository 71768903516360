// material
import { Box, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import GQLTable from '../../utils/gql-mui-rhf/Table';
// ----------------------------------------------------------------------

export default function UserManagement() {
  return (
    <Page title="Dashboard | FINBACK670">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Administrative User Management</Typography>
        </Box>
        <GQLTable 
          queryString={'users'} 
          addMutationString={'signup'}
          deleteMutationString={'deleteUser'} 
          columnOptions={{email: {width: 200}}}
          restrictedColumns={{id: true, password: true}}
          />
      </Container>
    </Page>
  );
};