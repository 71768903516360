import { gql, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { Configuration } from "../TypeDefinitions";
import { Dispatch, FC, createContext, useContext } from "react";
import {
  Dictionary,
  DictionaryContents,
  generateDictionary,
} from "src/utils/excelParse";
import { useImmerReducer } from "use-immer";
import { exhaustiveGuard } from "src/utils/exhaustiveGuard";

const getInspectionQuery = gql`
  query getFieldsQuery($inspectionTypeTemplateId: ID!) {
    inspectionTypeTemplate(id: $inspectionTypeTemplateId) {
      id
      configuration
      start
      end
    }
  }
`;
const ExcelMapping = () => {
  const { inspectionTypeTemplateId } = useParams();
  const { data, loading } = useQuery(getInspectionQuery, {
    variables: {
      inspectionTypeTemplateId: inspectionTypeTemplateId,
    },
  });

  return (
    <>
      {loading && <CircularProgress />}
      {data && (
        <ExcelMapDisplay config={data.inspectionTypeTemplate.configuration} />
      )}
    </>
  );
};

export type Action =
  | {
      type: "updateField";
      payload: {
        chain: DictionaryLevel[];
        fieldId: string;
        newExcelValue: string;
      };
    }
  | {
      type: "addOffsetLocation";
      payload: {
        chain: DictionaryLevel[];
      };
    }
  | {
      type: "deleteOffsetLocation";
      payload: {
        chain: DictionaryLevel[];
        index: number;
      };
    }
  | {
      type: "updateOffsetLocation";
      payload: {
        chain: DictionaryLevel[];
        index: number;
        newValue: string;
      };
    };

function reducer(draft: Dictionary | null, action: Action) {
  if (draft === null) return;
  switch (action.type) {
    case "updateField":
      for (const content in draft) {
        updateField(
          draft[content],
          action.payload.chain,
          action.payload.fieldId,
          action.payload.newExcelValue
        );
        break;
      }
      break;
    case "addOffsetLocation":
      for (const content in draft) {
        offsetAdd(draft[content], action.payload.chain);
        break;
      }
      break;
    case "deleteOffsetLocation":
      for (const content in draft) {
        offsetRemove(
          draft[content],
          action.payload.chain,
          action.payload.index
        );
      }
      break;
    case "updateOffsetLocation":
      for (const content in draft) {
        offsetUpdate(
          draft[content],
          action.payload.chain,
          action.payload.index,
          action.payload.newValue
        );
      }
      break;
    default:
      exhaustiveGuard(action);
  }
}

function offsetRemove(
  dictionary: DictionaryContents,
  chain: DictionaryLevel[],
  index: number
) {
  if (chain.length > 1) {
    switch (dictionary.enumerable) {
      case "byOffset":
      case false:
        {
          const newChain = chain.slice(1);
          if (dictionary.subforms === null) return;
          offsetRemove(dictionary.subforms[chain[1].formId], newChain, index);
        }
        break;
      case "specifiedLocation":
        {
          const newChain = chain.slice(1);
          const nextEnumeration = chain[0].enumeration;
          if (nextEnumeration === false) return;
          offsetRemove(
            dictionary.enumerations[nextEnumeration].subforms![chain[1].formId],
            newChain,
            index
          );
        }
        break;
      default:
        exhaustiveGuard(dictionary);
    }
  } else {
    if (dictionary.enumerable !== "byOffset") return;
    dictionary.offsetLocations.splice(index, 1);
  }
}
function offsetUpdate(
  dictionary: DictionaryContents,
  chain: DictionaryLevel[],
  index: number,
  newValue: string
) {
  if (chain.length > 1) {
    switch (dictionary.enumerable) {
      case "byOffset":
      case false:
        {
          const newChain = chain.slice(1);
          if (dictionary.subforms === null) return;
          offsetUpdate(
            dictionary.subforms[chain[1].formId],
            newChain,
            index,
            newValue
          );
        }
        break;
      case "specifiedLocation":
        {
          const newChain = chain.slice(1);
          const nextEnumeration = chain[0].enumeration;
          if (nextEnumeration === false) return;
          offsetUpdate(
            dictionary.enumerations[nextEnumeration].subforms![chain[1].formId],
            newChain,
            index,
            newValue
          );
        }
        break;
      default:
        exhaustiveGuard(dictionary);
    }
  } else {
    if (dictionary.enumerable !== "byOffset") return;
    dictionary.offsetLocations[index] = newValue;
  }
}

function offsetAdd(dictionary: DictionaryContents, chain: DictionaryLevel[]) {
  if (chain.length > 1) {
    switch (dictionary.enumerable) {
      case "byOffset":
      case false:
        {
          const newChain = chain.slice(1);
          if (dictionary.subforms === null) return;
          offsetAdd(dictionary.subforms[chain[1].formId], newChain);
        }
        break;
      case "specifiedLocation":
        {
          const newChain = chain.slice(1);
          const nextEnumeration = chain[0].enumeration;
          if (nextEnumeration === false) return;
          offsetAdd(
            dictionary.enumerations[nextEnumeration].subforms![chain[1].formId],
            newChain
          );
        }
        break;
      default:
        exhaustiveGuard(dictionary);
    }
  } else {
    if (dictionary.enumerable !== "byOffset") return;
    dictionary.offsetLocations.push("");
  }
}
function updateField(
  dictionary: DictionaryContents,
  chain: DictionaryLevel[],
  fieldId: string,
  newExcelValue: string
) {
  if (chain.length > 1) {
    switch (dictionary.enumerable) {
      case "specifiedLocation":
        {
          const enumeration = chain[1].enumeration;
          if (enumeration === false) return;
          const newChain = chain.slice(1);
          if (chain.length === 2) {
            if (chain[1].formId === chain[0].formId) {
              dictionary.enumerations[enumeration].fields![fieldId] =
                newExcelValue;
            } else {
              let subform =
                dictionary.enumerations[enumeration].subforms![chain[1].formId];
              if (subform.enumerable === false) {
                subform.fields![fieldId] = newExcelValue;
              }
            }
          } else {
            const nextDictionaryContent =
              dictionary.enumerations[enumeration].subforms;
            if (!nextDictionaryContent) return;
            updateField(
              nextDictionaryContent[chain[2].formId],
              newChain,
              fieldId,
              newExcelValue
            );
          }
        }
        break;
      case "byOffset":
      case false:
        {
          const newChain = chain.slice(1);
          if (!dictionary.subforms) return;
          const nextDictionaryContent = dictionary.subforms[chain[1].formId];
          updateField(nextDictionaryContent, newChain, fieldId, newExcelValue);
        }
        break;
      default:
        exhaustiveGuard(dictionary);
    }
  } else {
    switch (dictionary.enumerable) {
      case "specifiedLocation":
        if (chain[0].enumeration !== false) {
          dictionary.enumerations[chain[0].enumeration].fields![fieldId] =
            newExcelValue;
        }
        break;
      case "byOffset":
        dictionary.fields![fieldId] = newExcelValue;
        break;
      case false:
        dictionary.fields![fieldId] = newExcelValue;
        break;
      default:
        exhaustiveGuard(dictionary);
    }
    // if (dictionary.enumerable !== false) return;
    // if (!dictionary.fields) return;
    // dictionary.fields[fieldId] = newExcelValue;
  }
}

const ExcelMapCtx = createContext<{
  draft: null | Dictionary;
  dispatch: Dispatch<Action>;
} | null>(null);

const ExcelMapDisplay: FC<{ config: Configuration }> = ({ config }) => {
  const topLevelFormId =
    config.forms.find((form) => form.type === "Questionnaire")?.id || null;
  const initialDictionary = topLevelFormId
    ? generateDictionary(topLevelFormId, config)
    : null;

  const [dictionary, dispatch] = useImmerReducer(reducer, initialDictionary);

  console.log(config);
  return (
    <>
      {dictionary && topLevelFormId && (
        <ExcelMapCtx.Provider value={{ draft: dictionary, dispatch: dispatch }}>
          <DictionaryRecurse
            config={config}
            topLevelFormId={topLevelFormId}
            dictionary={dictionary[topLevelFormId]}
          />
        </ExcelMapCtx.Provider>
      )}
    </>
  );
};

export type DictionaryLevel = {
  formId: string;
  enumeration: number | false;
};

const DictionaryRecurse = ({
  dictionary,
  topLevelFormId,
  config,
  dictionaryLevel,
}: {
  dictionary: DictionaryContents;
  config: Configuration;
  topLevelFormId: string;
  dictionaryLevel?: DictionaryLevel[];
}) => {
  const form = config.forms.find((form) => topLevelFormId === form.id);
  const ctx = useContext(ExcelMapCtx);

  if (!form || !ctx) return <></>;

  const { dispatch } = ctx;

  const level =
    dictionaryLevel === undefined
      ? ([{ formId: form.id, enumeration: false }] as DictionaryLevel[])
      : dictionaryLevel;

  switch (dictionary.enumerable) {
    case "byOffset":
    case false:
      return (
        <div
          style={{
            border: "1px solid green",
            paddingLeft: "1rem",
            marginTop: "0.5rem",
            paddingBottom: "1rem",
          }}
        >
          {dictionary.enumerable === "byOffset" ? (
            <h1 style={{ fontWeight: "bold" }}>
              ENUMERATED GROUP: {form.name} [
              {dictionary.offsetLocations.length + 1}]
            </h1>
          ) : (
            <h1 style={{ fontWeight: "bold" }}>{form.name}</h1>
          )}
          {dictionary.enumerable === "byOffset" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "0.25rem",
              }}
            >
              <h1 style={{ fontWeight: "bold" }}>
                Offset Count:{" "}
                {dictionary.offsetLocations.length ? (
                  `${dictionary.offsetLocations.length}`
                ) : (
                  <span style={{ fontWeight: "bold" }}>NONE</span>
                )}
              </h1>
              {dictionary.offsetLocations.map((location, i) => (
                <div>
                  <input
                    type="text"
                    placeholder="Excel Column"
                    value={location}
                    onChange={(e) =>
                      dispatch({
                        type: "updateOffsetLocation",
                        payload: {
                          chain: level,
                          index: i,
                          newValue: e.target.value,
                        },
                      })
                    }
                    style={{
                      border: "1px solid lightgrey",
                      borderRadius: "5px",
                      paddingLeft: "3px",
                      marginRight: "1rem",
                    }}
                  />
                  <button
                    onClick={(e) =>
                      dispatch({
                        type: "deleteOffsetLocation",
                        payload: {
                          chain: level,
                          index: i,
                        },
                      })
                    }
                    style={{ color: "red" }}
                  >
                    Delete
                  </button>
                </div>
              ))}
              <button
                style={{ color: "blue", textDecoration: "underline" }}
                onClick={(e) =>
                  dispatch({
                    type: "addOffsetLocation",
                    payload: {
                      chain: level,
                    },
                  })
                }
              >
                Add Enumeration Offset
              </button>
            </div>
          )}
          {form.fields.map((field) => {
            const excelMapping = dictionary.fields![field.id] || "";
            return (
              <div style={{ paddingBottom: "0.5rem" }}>
                <p>{field.label}</p>
                <input
                  type="text"
                  placeholder="Excel Column"
                  value={excelMapping}
                  onChange={(e) => {
                    dispatch({
                      type: "updateField",
                      payload: {
                        fieldId: field.id,
                        newExcelValue: e.target.value,
                        chain: level,
                      },
                    });
                  }}
                  style={{
                    border: `1px solid ${
                      excelMapping === "" ? "orange" : "lightgrey"
                    }`,
                    borderRadius: "5px",
                    paddingLeft: "3px",
                  }}
                />
              </div>
            );
          })}
          {dictionary.subforms &&
            form.subformIds.map((subformId) => (
              <DictionaryRecurse
                dictionary={dictionary.subforms![subformId]}
                config={config}
                topLevelFormId={subformId}
                dictionaryLevel={[
                  ...level,
                  { enumeration: false, formId: subformId },
                ]}
              />
            ))}
        </div>
      );
    case "specifiedLocation":
      return (
        <div
          style={{
            border: "1px dashed red",
            paddingLeft: "1rem",
            paddingBottom: "1rem",
            marginTop: "0.5rem",
            marginBottom: "0.5rem",
          }}
        >
          <h1 style={{ fontWeight: "bold" }}>
            ENUMERATED GROUP: {form.name} [{dictionary.enumerations.length}]
          </h1>
          {dictionary.enumerations.map((enumeration, i) => {
            return (
              <div
                style={{
                  border: "1px solid red",
                  paddingLeft: "1rem",
                  marginTop: "0.5rem",
                  marginBottom: "0.5rem",
                  paddingBottom: "1rem",
                }}
              >
                <h1>{form.name + " " + (i + 1)}</h1>
                {form.fields.map((field) => {
                  const excelMapping = enumeration.fields![field.id] || "";
                  return (
                    <div style={{ paddingBottom: "0.5rem" }}>
                      <p>{field.label}</p>
                      <input
                        type="text"
                        placeholder="Excel Column"
                        value={excelMapping}
                        onChange={(e) => {
                          let editChain = [...level];
                          editChain[editChain.length - 1].enumeration = i;
                          dispatch({
                            type: "updateField",
                            payload: {
                              fieldId: field.id,
                              chain: [...editChain],
                              newExcelValue: e.target.value,
                            },
                          });
                        }}
                        style={{
                          border: `1px solid ${
                            excelMapping === "" ? "orange" : "lightgrey"
                          }`,
                          borderRadius: "5px",
                          paddingLeft: "3px",
                        }}
                      />
                    </div>
                  );
                })}
                {enumeration.subforms &&
                  form.subformIds.map((subformId) => {
                    const dictionary = enumeration.subforms![subformId];
                    return (
                      <DictionaryRecurse
                        topLevelFormId={subformId}
                        config={config}
                        dictionary={dictionary}
                        dictionaryLevel={[
                          ...level,
                          { formId: subformId, enumeration: i },
                        ]}
                      />
                    );
                  })}
              </div>
            );
          })}
        </div>
      );
    default:
      exhaustiveGuard(dictionary);
  }
};
export default ExcelMapping;
