import SchemaContext from "./SchemaContext";
import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { getMutationInputs, getMutationReturn, generateMutation } from './MutationHelpers';

export const GQLSyncMutationAction = ({ mutationString, onCompleted }) => {

  const schema = useContext(SchemaContext);
  const mutation = schema.mutations[mutationString];
  if (!mutation) {
    debugger
    throw Error(`Undefined Mutation: ${mutationString}`)
  }
  const mutationInputs = getMutationInputs(mutation, schema);
  const mutationReturn = getMutationReturn(mutation, schema);
  const mutationDocument = generateMutation(mutationInputs, mutationReturn, mutationString)
  const [mutate, { error }] = useMutation(mutationDocument, { errrorPolicy: 'all', onCompleted });
  if (error)
  console.error("Error in Mutation Action for", mutationString, "\nError is: ", error)
  return (record) => {
    return mutate({ variables: record })
  }
};
