import { gql, useQuery } from "@apollo/client";
import { Edit } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import React from "react";
import { Link } from "react-router-dom";
import Page from "src/components/Page";

const inspectionTypesQuery = gql`
  query ExampleQuery {
    inspectionTypes {
      id
      name
      description
      projectId
      inspectionTypeTemplates {
        id
      }
    }
  }
`;

export default function InspectionTypesTable() {
  const columns = [
    {
      field: "Edit",
      width: 50,
      renderCell: (params) => (
        <Link to={params.id}>
          <Edit color="primary" />
        </Link>
      ),
    },
    {
      field: "name",
      headerName: "Inspection Type",
      width: 250,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "projectId",
      headerName: "Project ID",
      flex: 1,
    },
    // {
    //   headerName: "Template Count",
    //   width: 70,
    //   renderCell: (params) => params.inspectionTypeTemplates.length,
    // },
  ];

  const { loading, data } = useQuery(inspectionTypesQuery, {
    fetchPolicy: "network-only",
  });

  const filteredData = data
    ? !process.env.NODE_ENV || process.env.NODE_ENV !== "development"
      ? data.inspectionTypes.filter((type) => type.description !== "Test")
      : data.inspectionTypes
    : [];

  return (
    <Page
      title="Inspection Types | FINBACK670"
      sx={{ height: "100%", maxHeight: "100%" }}
    >
      <Breadcrumbs>
        <Link to=".">Inspection Types List</Link>
      </Breadcrumbs>
      <Box height="0.5rem" />
      {
        <Button
          variant="outlined"
          component={Link}
          to={"create"}
          sx={{ marginBottom: "1rem" }}
        >
          Create New Inspection Type
        </Button>
      }
      {loading && <CircularProgress />}
      {data && <DataGrid rows={filteredData} columns={columns} />}
    </Page>
  );
}
