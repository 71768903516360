import * as React from "react";
// material
import { Box, Container, Typography } from "@mui/material";
// components
import Page from "../../components/Page";
import GQLTable from "../../utils/gql-mui-rhf/Table";

// ----------------------------------------------------------------------

export default function Credential() {

  return (
    <Page title="Dashboard | FINBACK670">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Category Management</Typography>
        </Box>
        <GQLTable
          queryString={'categories'}
          queryVariables={{ "active": true }}
          addMutationString={'createCategory'}
          updateMutationString={'updateCategory'}
          deleteMutationString={'deleteCategory'}
          columnOptions={{ name: { width: 250 }, module: { width: 200 } }}
          restrictedColumns={{ id: true, active: true }}
        />
      </Container>
    </Page>
  );
}
