// material
import { useState, useEffect, useCallback } from 'react';
import {
    DataGridPremium,
    GridToolbar
} from "@mui/x-data-grid-premium";
import { Container } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import plusSquareOutline from "@iconify/icons-eva/plus-square-outline";
// components
import Confirmation from "../../../components/modals/confirmation";
import TableActions from './tableActions';
import Page from '../../../components/Page';
// ----------------------------------------------------------------------
import { useLazyQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import AddFromParent from './AddFromParent';
import AddCredential from './AddCredential';
import AddRole from './AddRole';
import AddBackgroundCheck from './AddBackgroundCheck';

const DELETE_PROJECT_PERSONS = gql`
    mutation deleteProjectPersons($ids: [ID]!) {
        deleteProjectPersons(ids: $ids) {
            id
        }
    }
`

const GET_PERSONS = gql`
query Persons($ids: [ID]) {
  personsByIds(ids: $ids) {
    id
    avatar
    email
    firmId
    firstName
    lastName
    phone1
  }}
`;
const GET_FIRM_PERSONS = gql`
query firmPersonsByIds($ids: [ID]) {
    firmPersonsByIds(ids: $ids) {
    id
    personId
    firm {
    id
    name
    }
  }
}
`
const GET_PROJECT_PERSONS = gql`
query ProjectPersons($projectId: ID) {
  projectPersons(projectId: $projectId) {
    id
    personId
    project {
        id
        name
    }
    fieldReady
  }
}
`

const GET_ROLES = gql`
query draftRolesByEntityIds($entityIds: [ID]) {
  draftRolesByEntityIds(entityIds: $entityIds) {
    id
    entityId
    roleType {
        id
        name
    }
  }
}
`
const GET_FIELD_READY_ROLES = gql`
query FieldReadyRoles($projectId: ID) {
  fieldReadyRoles(projectId: $projectId) {
    id
    roleId
    project {
        id
        name
    }
  }
}
`

const UPDATE_PROJECT_PERSON = gql`
    mutation updateProjectPerson($projectPerson: InputProjectPersonParams) {
        updateProjectPerson(projectPerson: $projectPerson) {
                id
                personId
                project {
                    id
                    name
                }
                fieldReady
        }
    }
`

const AddPersons = (project, merged, open, handleClose, refetch) => {
    return (
        <Dialog
            maxWidth={'xl'}
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Add Parent Project People
            </DialogTitle>
            <DialogContent>
                <AddFromParent project={project} subProjectPersons={merged} refetchSubProjectPersons={refetch} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    handleClose()
                }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default function ProjectPeople({ project }) {
    const [persons, setPersons] = useState(null);
    const [firmPersons, setFirmPersons] = useState(null);
    const [projectPersons, setProjectPersons] = useState(null);
    const [personsRoles, setPersonsRoles] = useState(null);
    const [rows, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [selectionRecord, setSelectionRecord] = useState([]);
    const [open, setOpen] = useState(false);
    const [fieldReadyRoles, setFieldReadyRoles] = useState([]);
    const handleClose = () => { setOpen(false) }
    const [openCredential, setOpenCredential] = useState(false);
    const handleCredentialClose = () => { setOpenCredential(false) }
    const handleCredentialOpen = () => { setOpenCredential(true) }
    const [openRole, setOpenRole] = useState(false);
    const handleRoleClose = () => { setOpenRole(false) }
    const handleRoleOpen = () => { setOpenRole(true) }
    const [openBackgroundCheck, setOpenBackgroundCheck] = useState(false);
    const handleBackgroundCheckClose = () => { setOpenBackgroundCheck(false) }
    const handleBackgroundCheckOpen = () => { setOpenBackgroundCheck(true) }
    const [confirmation, setConfirmation] = useState({ open: false });
    const closeConfirmation = () => {
        setConfirmation({ open: false });
    };
    const [getPersonsRoles] = useLazyQuery(GET_ROLES, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setPersonsRoles(response.draftRolesByEntityIds)
        },
        onError: (response) => {

        }
    });
    const [getFieldReadyRoles] = useLazyQuery(GET_FIELD_READY_ROLES, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setFieldReadyRoles(response.fieldReadyRoles.map((fieldReadyRole) => fieldReadyRole?.roleId))
        },
        onError: (response) => {

        }
    });
    const [getPersons] = useLazyQuery(GET_PERSONS, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setPersons(response.personsByIds)
        }
    });
    const [getFirmPersons] = useLazyQuery(GET_FIRM_PERSONS, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setFirmPersons(response.firmPersonsByIds)
        }, onError: (e) => {
        }
    });
    const [getProjectPersons] = useLazyQuery(GET_PROJECT_PERSONS, {
        variables: { projectId: project.id },
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setProjectPersons(response.projectPersons)
            getPersons({ variables: { ids: response.projectPersons.map((person) => person.personId) } })
            getFirmPersons({ variables: { ids: response.projectPersons.map((person) => person.personId) } })
            getPersonsRoles({ variables: { entityIds: response.projectPersons.map((person) => person.personId) } })
        }
    });
    const refetch = () => {
        getPersons({ variables: { ids: persons.map((person) => person.personId) } })
        getFirmPersons({ variables: { ids: persons.map((person) => person.personId) } })
        getProjectPersons({ variables: { projectId: project.id } })
    }
    useEffect(() => {
        getProjectPersons({ variables: { projectId: project.id } })
        getFieldReadyRoles({ variables: { projectId: project.id } })
    }, [project, getProjectPersons, getFieldReadyRoles]);

    const [updateProjectPerson] = useMutation(UPDATE_PROJECT_PERSON)
    const [deleteProjectPersons] = useMutation(DELETE_PROJECT_PERSONS, {
        onCompleted: () => {
            refetchCallback()
        }
    })

    const refetchCallback = useCallback(
        () => {
            getPersons({ variables: { ids: persons.map((person) => person.personId) } })
            getFirmPersons({ variables: { ids: persons.map((person) => person.personId) } })
            getProjectPersons({ variables: { projectId: project.id } })
        }, [persons, project, getPersons, getProjectPersons, getFirmPersons]
    )
    const processRowUpdate = useCallback(
        async (newRow) => {
            const projectPerson = { id: newRow.id, fieldReady: newRow.fieldReady }
            updateProjectPerson({ variables: { projectPerson } });
            refetchCallback()
        },
        [updateProjectPerson, refetchCallback]
    );
    const handleProcessRowUpdateError = useCallback((error) => { }, []);

    const formatFirm = (firmPerson) => {
        if (firmPerson) {
            return {
                firmPersonId: firmPerson.id,
                firmName: firmPerson.firm.name,
                firmId: firmPerson.firm.id
            }
        } else {
            return {}
        }
    }

    const formatRoles = (roles) => {
        let roleString = ""
        roles.forEach((role) => {
            if (!!roleString) {
                roleString = roleString + `, ${role.roleType.name}`
            } else {
                roleString = `${role.roleType.name}`
            }
        })
        return { roles: roleString }
    }
    useEffect(() => {
        const personHasFieldReadyRoles = (personRoles) => {
            let personRolesIds = personRoles.map((role) => role.roleType.id)
    
            return fieldReadyRoles.every((val) => personRolesIds.includes(val));
        }
        let merged = []
        if (Array.isArray(persons) && Array.isArray(firmPersons) && Array.isArray(personsRoles) && Array.isArray(projectPersons)) {
            for (let i = 0; i < projectPersons.length; i++) {
                merged.push({
                    ...(formatFirm(firmPersons.find((itmInner) => itmInner.personId === projectPersons[i].personId))),
                    ...(formatRoles(personsRoles.filter((role) => role.entityId === projectPersons[i].personId))),
                    ...projectPersons[i],
                    ...(persons.find((itmInner) => itmInner.id === projectPersons[i].personId)),
                    fieldReady: personHasFieldReadyRoles(personsRoles.filter((role) => role.entityId === projectPersons[i].personId))
                });
            }
        }
        setRows(merged)
    }, [projectPersons, persons, firmPersons, personsRoles, fieldReadyRoles]);

    const customActions = [
        { callback: handleCredentialOpen, icon: plusSquareOutline, text: "Request Credential for" },
        { callback: handleRoleOpen, icon: plusSquareOutline, text: "Request Role for" },
        { callback: handleBackgroundCheckOpen, icon: plusSquareOutline, text: "Request Background Check for", singleAction: true }
    ]
    const onDeleteProjectPersons = (selectionModel) => {
        let ids = selectionModel.map((model) => model.id)
        deleteProjectPersons({ variables: { ids } })
    }

    return (
        <Page title="Projects | FINBACK670">
            <Confirmation
                confirmation={confirmation}
                handleClose={closeConfirmation}
            />
            <AddCredential
                open={openCredential}
                selectionRecord={selectionRecord}
                handleClose={handleCredentialClose}
                project={project}
            />
            <AddRole
                open={openRole}
                selectionRecord={selectionRecord}
                handleClose={handleRoleClose}
                project={project}
            />
            <AddBackgroundCheck
                open={openBackgroundCheck}
                selectionRecord={selectionRecord}
                handleClose={handleBackgroundCheckClose}
                project={project}
            />
            <Container maxWidth="xl">
                {AddPersons(project, rows, open, handleClose, refetch)}
                {project.parentProject?.id && <Button onClick={() => { setOpen(true) }} variant="outlined">Add Persons from Parent Project</Button>}
                <TableActions
                    deleteRecords={onDeleteProjectPersons}
                    selectionModel={selectionRecord}
                    setConfirmation={setConfirmation}
                    customActions={customActions}
                />
                <div style={{ height: 600, width: "100%" }}>
                    <DataGridPremium
                        rows={rows}
                        loading={!project}
                        columns={[
                            { editable: false, type: 'boolean', field: 'fieldReady', headerName: "Field Ready?" },
                            { editable: false, field: 'firstName', headerName: "First Name" },
                            { editable: false, field: 'lastName', headerName: "Last Name" },
                            { editable: false, field: 'firmName', headerName: "Firm" },
                            { editable: false, field: 'email', headerName: "Email" },
                            { editable: false, field: 'phone1', headerName: "Contact Phone" },
                            { editable: false, field: 'roles', headerName: "Roles" },
                        ]}
                        pageSize={5}
                        pageSizeOptions={[10, 25, 50]}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        slots={{ toolbar: GridToolbar }}
                        checkboxSelection
                        onRowSelectionModelChange={(ids) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData = rows.filter((row) =>
                                selectedIDs.has(row.id)
                            );
                            setSelectionRecord(selectedRowData);
                            setSelectionModel(ids);
                        }}
                        rowSelectionModel={selectionModel}
                    />
                </div>
            </Container>
        </Page>
    );
}
