import { MenuItem, Select } from "@mui/material";
import React from "react";

interface AssignmentSelectProps {
  selectedAssignmentId: string;
  updateSelectedAssignment: (assignmentId: string) => void;
  possibleAssignments: {
    id: string;
    start: string;
  }[];
}

export default function AssignmentSelect({
  selectedAssignmentId,
  updateSelectedAssignment,
  possibleAssignments,
}: AssignmentSelectProps) {
  return (
    <Select
      size="small"
      value={selectedAssignmentId}
      onChange={(e) => updateSelectedAssignment(e.target.value)}
    >
      {possibleAssignments.map((assignment) => {
        let dateText = new Date(assignment.start).toDateString();
        return (
          <MenuItem value={assignment.id} key={assignment.id}>
            {dateText}
          </MenuItem>
        );
      })}
    </Select>
  );
}
