import { useEffect, useState } from "react";
import { Select } from "../../utils/mui-rhf";
import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';
// ----------------------------------------------------------------------

const GET_PERSONS = gql`
query Persons($ids: [ID]) {
  personsByIds(ids: $ids) {
    id
    avatar
    email
    firmId
    firstName
    lastName
    phone1
  }}
`;
const GET_FIRM_PERSONS = gql`
query firmPersonsByIds($ids: [ID]) {
    firmPersonsByIds(ids: $ids) {
    id
    personId
    firm {
    id
    name
    }
  }
}
`
const GET_PROJECT_PERSONS = gql`
query ProjectPersons($projectId: ID) {
  projectPersons(projectId: $projectId) {
    id
    personId
    project {
        id
        name
    }
    fieldReady
  }
}
`

const GET_ROLES = gql`
query draftRolesByEntityIds($entityIds: [ID]) {
  draftRolesByEntityIds(entityIds: $entityIds) {
    id
    entityId
    roleType {
        id
        name
    }
  }
}
`


export default function PersonSelectors({ methods, userIsOwner, project, person }) {
    const [persons, setPersons] = useState(null);
    const [firmPersons, setFirmPersons] = useState(null);
    const [projectPersons, setProjectPersons] = useState(null);
    const [personsRoles, setPersonsRoles] = useState(null);
    const [options, setOptions] = useState([]);

    const [getPersonsRoles] = useLazyQuery(GET_ROLES, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {

            setPersonsRoles(response.draftRolesByEntityIds)
        },
        onError: (response) => {

        }
    });

    const [getPersons] = useLazyQuery(GET_PERSONS, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setPersons(response.personsByIds)
        }
    });
    const [getFirmPersons] = useLazyQuery(GET_FIRM_PERSONS, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setFirmPersons(response.firmPersonsByIds)
        }, onError: (e) => {
        }
    });
    const [getProjectPersons] = useLazyQuery(GET_PROJECT_PERSONS, {
        variables: { projectId: project.id },
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setProjectPersons(response.projectPersons)
            getPersons({ variables: { ids: response.projectPersons.map((person) => person.personId) } })
            getFirmPersons({ variables: { ids: response.projectPersons.map((person) => person.personId) } })
            getPersonsRoles({ variables: { entityIds: response.projectPersons.map((person) => person.personId) } })
        }
    });
    useEffect(() => {
        getProjectPersons({ variables: { projectId: project.id } })
    }, [project, getProjectPersons]);

    const formatFirm = (firmPerson) => ({
        firmPersonId: firmPerson.id,
        firmName: firmPerson.firm.name,
        firmId: firmPerson.firm.id,
    })

    const formatRoles = (roles) => {
        let roleString = ""
        roles.forEach((role) => {
            if (!!roleString) {
                roleString = roleString + `, ${role.roleType.name}`
            } else {
                roleString = `${role.roleType.name}`
            }
        })
        return { roles: roleString }
    }
    useEffect(() => {
        let merged = []
        if (Array.isArray(persons) && Array.isArray(firmPersons) && Array.isArray(personsRoles) && Array.isArray(projectPersons)) {
            for (let i = 0; i < projectPersons.length; i++) {
                merged.push({
                    ...(persons.find((itmInner) => itmInner.id === projectPersons[i].personId)),
                    ...(formatFirm(firmPersons.find((itmInner) => itmInner.personId === projectPersons[i].personId))),
                    ...(formatRoles(personsRoles.filter((role) => role.entityId === projectPersons[i].personId))),
                    ...projectPersons[i]
                });
            }
        }
        setOptions(merged)
    }, [projectPersons, persons, firmPersons, personsRoles]);

    let personEntityId = methods.watch('entityId')

    useEffect(() => {
        let person = options.find((option) => option.id === personEntityId)
        if (person) {
            methods.setValue('firstName', person.firstName)
            methods.setValue('lastName', person.lastName)
            methods.setValue('email', person.email)
        }
        // methods.setValue('projectFirmId')
    }, [personEntityId, methods, options])
    let formatOptions = [...options]
    if (!userIsOwner) {
        formatOptions = formatOptions.filter((option) => option.firmId === person.firmId)
    }
    formatOptions = formatOptions.map((option) => {
        return {
            value: option.id,
            label: `${option.firstName} ${option.lastName} - ${option.firmName}`
        }
    })
    return (
        <Select style={{ marginBottom: '16px' }} rules={{ required: "select option" }} fullWidth label={"Project Person"} name={"entityId"} options={formatOptions} />
    );
}
