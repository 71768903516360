import { PageState, Action, Form } from "../../TypeDefinitions";
import { v4 as uuidv4 } from "uuid";

export function createForm<
  E extends Extract<
    Action,
    {
      type: "createForm";
    }
  >
>(action: E, draft: PageState): void {
  const { type } = action.payload;

  const newFormFn = (): Form => {
    const params = {
      fields: [],
      formConfigurationId: draft.data.inspectionTypeTemplate.configuration.id,
      subformIds: [],
      id: uuidv4(),
      name: "",
      validations: [],
      isEmbedded: false,
    };

    switch (type) {
      case "SubForm":
      case "InteriorInspection":
      case "ExteriorInspection":
      case "Questionnaire":
        return { ...params, type: type };
      case "RoomInspection":
      case "ComponentInspection":
        return { ...params, type: type, references: [] };
    }
  };

  const newForm = newFormFn();

  draft.data.inspectionTypeTemplate.configuration.forms.push(newForm);
  draft.navigate(`${newForm.id}`);
}
