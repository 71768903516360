import {
  AccessTime,
  AlternateEmail,
  ArrowDropDown,
  CalendarToday,
  CheckBox,
  ContentPaste,
  DragHandle,
  Event,
  Gesture,
  LibraryAddCheck,
  Numbers,
  Phone,
  PhotoCamera,
  RadioButtonChecked,
  ShortText,
  Subject,
} from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import React, { useContext } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { FormCtx } from "./FormWrapper/BuilderWrapper";

const inputFieldData = [
  {
    label: "Yes or No",
    icon: <CheckBox />,
    addsField: "YesNo",
  },
  {
    label: "Phone",
    icon: <Phone />,
    addsField: "Phone",
  },
  {
    label: "Short Text",
    icon: <ShortText />,
    addsField: "Text",
  },
  {
    label: "Long Text",
    icon: <Subject />,
    addsField: "LongText",
  },
  {
    label: "Checklist",
    icon: <LibraryAddCheck />,
    addsField: "Checklist",
  },
  {
    label: "Option Select",
    icon: <RadioButtonChecked />,
    addsField: "OptionSelect",
  },
  {
    label: "Drop Down",
    icon: <ArrowDropDown />,
    addsField: "DropDown",
  },
  {
    label: "Email",
    icon: <AlternateEmail />,
    addsField: "Email",
  },
  {
    label: "Number",
    icon: <Numbers />,
    addsField: "Number",
  },
  {
    label: "Time",
    icon: <AccessTime />,
    addsField: "Time",
  },
  {
    label: "Date",
    icon: <CalendarToday />,
    addsField: "Date",
  },
  {
    label: "Date + Time",
    icon: <Event />,
    addsField: "DateTime",
  },
  {
    label: "Photo",
    icon: <PhotoCamera />,
    addsField: "PhotoCollection",
  },
  {
    label: "Signature",
    icon: <Gesture />,
    addsField: "Signature",
  },
];

export default function CreateInputButtons({ addField }) {
  const { state } = useContext(FormCtx);
  return (
    <Droppable droppableId="inputButtonDroppable" isDropDisabled={true}>
      {(provided, snapshot) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
          }}
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          <>
            {state.fieldToCopy && (
              <FormInputDraggable
                key="input-field-paste"
                width={"100%"}
                label="Paste from Clipboard"
                icon={<ContentPaste />}
                addsField="paste"
              />
            )}
            {inputFieldData.map((input, i) => (
              <FormInputDraggable
                {...input}
                index={i}
                key={`input-field-${input.addsField}`}
              />
            ))}
            <div style={{ display: "hidden" }}>{provided.placeholder}</div>
          </>
        </Box>
      )}
    </Droppable>
  );
}

function FormInputDraggable(props) {
  const { addsField, index } = props;
  return (
    <Draggable draggableId={addsField} index={index}>
      {(provided, snapshot) => (
        <>
          <FormInput {...props} provided={provided} snapshot={snapshot} />
          {snapshot.isDragging && <FormInput {...props} />}
        </>
      )}
    </Draggable>
  );
}

function FormInput({ provided, icon, addsField, label, snapshot, width }) {
  const { dispatch } = useContext(FormCtx);
  const { isDragging, isDropAnimating, draggingOver } = snapshot || false;

  const draggingOverDisplay = draggingOver === "formDisplayDroppable";

  const droppingInWrongLocation = isDropAnimating && !draggingOverDisplay;

  const buttonClassName = `input-button-${addsField}`;

  return (
    <Box
      sx={[
        {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "4px",
          width: width || "50%",
          transform: !isDragging && "translate(0px, 0px) !important",
        },
        droppingInWrongLocation && {
          visibility: "hidden",
        },
      ]}
      {...provided?.draggableProps}
      ref={provided?.innerRef}
    >
      <Button
        component="div"
        variant="outlined"
        endIcon={icon}
        fullWidth
        className={buttonClassName}
        onClick={() =>
          dispatch({
            type: "createField",
            payload: {
              inputType: addsField,
            },
          })
        }
        sx={[
          {
            display: "flex",
            flexDirection: "row",
            backgroundColor: "white",
            alignItems: "center",
          },
          droppingInWrongLocation && {
            visibility: "hidden",
          },
        ]}
      >
        <>
          <IconButton
            {...provided?.dragHandleProps}
            sx={{
              padding: 0,
              ":active": {
                selector: {
                  marginBottom: "3.2rem",
                },
              },
            }}
          >
            <DragHandle
              color="disabled"
              sx={{
                marginLeft: "-18px",
                transform: "rotate(90deg)",
              }}
            />
          </IconButton>
          {label}
        </>
        <Box sx={{ flex: 1 }}></Box>
      </Button>
    </Box>
  );
}
