// material
// components
import { useContext } from "react";
import { ProjectContext } from "../../../utils/contexts/project";
import ProjectFirmList from './list';
// ----------------------------------------------------------------------
export default function UserManagement() {
  let projectContext = useContext(ProjectContext);
  const { project } = projectContext;
  if (!project) { return <div>Select a Project to View</div> }
  if (project) {
    return <ProjectFirmList project={project} />
  }
}
