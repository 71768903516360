import * as React from 'react';

import { useParams } from 'react-router-dom';
//gql-mui-rhf
import createQuery from '../../../utils/gql-mui-rhf/QueryAction';
// material
import { GQLForm } from '../../../utils/gql-mui-rhf/FormProvider';
import { CircularProgress } from '@mui/material';

// ----------------------------------------------------------------------

export default function FirmView() {
    const params = useParams()
    const firmId = params.id

    const { loading, error, data } = createQuery('firm', { id: firmId })
    if (loading)
        return <CircularProgress />
    if (error) {
        return <div>{`${error}`}</div>
    }
    if (data) {
        return (
            <GQLForm
            mutationString="updateFirm"
            />
        );
    }
}
