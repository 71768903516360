import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import gql from 'graphql-tag';
import jwt_decode from 'jwt-decode';
import { useContext } from "react";
import { AuthContext } from "../../utils/contexts/auth";
import { PersonContext } from "../../utils/contexts/person";
import { ProjectContext } from "../../utils/contexts/project";
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

const REFRESH_TOKEN = gql`
    mutation refresh($token: String!){
      refresh(token: $token) {
        user {
            id
            email
        }
        token
      }
    }
  `;

export default function TimeoutTimer() {
    let auth = useContext(AuthContext);
    let person = useContext(PersonContext);
    let project = useContext(ProjectContext);
    const onCompleted = (response) => {
        auth.refresh(response.refresh)
    }
    const [refresh] = useMutation(REFRESH_TOKEN, { onCompleted });

    const [secondsToExpiration, setSecondsToExpiration] = useState();
    const [closed, setClosed] = useState(false);

    useEffect(() => {
        if (auth.token) {
            console.log("CALLING INTERVAL")
            const interval = setInterval(() => {
                const token = auth.token
                const decoded = jwt_decode(token);
                const expiration = decoded.exp * 1000;
                if (!expiration || expiration < Date.now()) auth.signout()
                const now = new Date(Date.now());
                setSecondsToExpiration(parseInt((expiration - now.getTime()) / 1000));
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [auth, auth.token]);

    const minutes = parseInt(secondsToExpiration / 60);
    const seconds = parseInt(secondsToExpiration % 60);

    const shouldRedirect = (secondsToExpiration) => {
        if (auth.token && secondsToExpiration <= 0) {
            setClosed(true)
            auth.signout()
            person.setPerson(null)
            project.setProject(null)
            setSecondsToExpiration()
        }
    }

    return (
        <>
            {shouldRedirect(secondsToExpiration)}
            <Dialog open={secondsToExpiration < 180 && !closed}>
                <DialogTitle>Sign Out Warning</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`You are going to be signed out in ${minutes > 0 ? `${minutes} minutes ` : ''
                            }${seconds} seconds.`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={e => {
                        auth.signout()
                        setSecondsToExpiration()
                        setClosed(true)
                    }}>
                        Sign Out
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={async e => {
                            refresh({ variables: { token: auth.token } })
                        }}
                    >
                        Keep Me Signed In
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}