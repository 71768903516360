import { Action, PageState } from "../../TypeDefinitions";
import { getForm } from "../utilities";

export function updateReferences<
  E extends Extract<
    Action,
    {
      type: "updateReferences";
    }
  >
>(action: E, draft: PageState) {
  const { references, inspectionType } = action.payload;

  const form = getForm(draft);

  if (!form) return;

  if (form.type !== "ComponentInspection" && form.type !== "RoomInspection")
    return;

  draft.data.inspectionTypeTemplate.configuration.forms.every((form) => {
    if (form.id === draft.formId) {
      if (form.type === inspectionType) {
        form.references = references;
      }
      return false;
    }
    return true;
  });
}
