import React, { useEffect, useState } from 'react';
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import { gql } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';

const GET_USER = gql`
query userForToken($token: String!) {
  userForToken(token: $token) {
    id
    email
  }}
`;
let AuthContext = React.createContext(null);
const TOKEN_NAME = "authToken";

function AuthProvider({ children }) {
    const [cookies, setCookie, removeCookie] = useCookies([TOKEN_NAME]);
    const [token, setToken] = useState(cookies[TOKEN_NAME])
    const [user, setUser] = useState(null)
    let navigate = useNavigate();

    const [getUser] = useLazyQuery(GET_USER, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setUser(response.userForToken)
        },
        onError: (response) => {
            removeCookie(TOKEN_NAME);
            setToken(null)
            navigate('/login');
        }
    });

    let signin = (signin) => {
        setCookie(TOKEN_NAME, signin.token, { path: '/', maxAge: 1800 });
        setToken(signin.token)
        setUser(signin.user)
    };

    let refresh = (refresh) => {
        setCookie(TOKEN_NAME, refresh.token, { path: '/', maxAge: 1800 });
        setToken(refresh.token)
        setUser(refresh.user)
    };

    let signout = () => {
        removeCookie(TOKEN_NAME);
        setToken(null)
        navigate('/login');
    };

    const checkToken = () => {
        if (token) {
            let decodeToken = jwt_decode(token)
            if (decodeToken.exp < Date.now() / 1000) {
                removeCookie(TOKEN_NAME);
                setToken(null)
                return false
            }
            return true
        }
        return false
    }
    useEffect(
        () => {
            if (token && !user) {
                getUser({ variables: { token } })
            }
        },
        [token, user, getUser]
    )

    let value = { checkToken, token, signin, refresh, signout, user };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthProvider }

