// material
import { useState, useEffect } from 'react';
import {
    useGridApiRef,
    DataGridPremium
} from "@mui/x-data-grid-premium";
import { Grid, Container, Button, Icon } from '@mui/material';
import plusSquareOutline from "@iconify/icons-eva/plus-square-outline";
// components
import Page from '../../../components/Page';
// ----------------------------------------------------------------------
import { useLazyQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import Confirmation from "../../../components/modals/confirmation";
import AddFieldReadyRoleToProject from "./AddFieldReadyRoleToProject";

const CREATE_FIELD_READY_ROLES = gql`
    mutation createFieldReadyRoles($fieldReadyRoles: [InputFieldReadyRoleParams]) {
        createFieldReadyRoles(fieldReadyRoles: $fieldReadyRoles) {
                id
                roleId
                project {
                    id
                    name
                }
        }
    }
`
const DELETE_FIELD_READY_ROLES = gql`
    mutation deleteFieldReadyRoles($ids: [ID]) {
        deleteFieldReadyRoles(ids: $ids) {
            id
        }
    }
`
const GET_ROLES = gql`
query Roles($ids: [ID]) {
  roleTypesByIds(ids: $ids) {
    id
    name
  }}
`;
const GET_FIELD_READY_ROLES = gql`
query FieldReadyRoles($projectId: ID) {
  fieldReadyRoles(projectId: $projectId) {
    id
    roleId
    project {
        id
        name
    }
  }
}
`

function renderTableActions(
    project,
    selectionModel,
    openAddFieldReadyRole,
    deleteFieldReadyRoles,
    setConfirmation
) {
    return (
        <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={4}
        >
            <Grid item>
                <h4>Actions: </h4>
            </Grid>
            <Grid item>
                <Button
                    onClick={openAddFieldReadyRole}
                    variant="outlined"
                    startIcon={<Icon icon={plusSquareOutline} />}
                >
                    {`Add New Field Ready Role(s)`}
                </Button>
            </Grid>
            {selectionModel.length > 0 && (
                <Grid item>
                    <Button
                        onClick={() => {
                            setConfirmation({
                                open: true,
                                action: () => {
                                    deleteFieldReadyRoles({ variables: { ids: selectionModel } })
                                },
                                actionText: `Remove ${selectionModel.length} roles from Project`,
                            });
                        }}
                        variant="outlined"
                        startIcon={<Icon icon={plusSquareOutline} />}
                    >
                        {`Remove ${selectionModel.length} roles from Project`}
                    </Button>
                </Grid>
            )}

        </Grid>
    );
}

export default function ProjectPeople({ project }) {
    const apiRef = useGridApiRef();
    const [roles, setRoles] = useState([]);
    const [fieldReadyRoles, setFieldReadyRoles] = useState([]);
    const [addFieldReadyRole, setAddFieldReadyRole] = useState(false);
    const [selectionRecord, setSelectionRecord] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const closeAddFieldReadyRole = () => setAddFieldReadyRole(false)
    const openAddFieldReadyRole = () => setAddFieldReadyRole(true)
    const [confirmation, setConfirmation] = useState({ open: false });
    const closeConfirmation = () => {
        setConfirmation({ open: false });
    };
    let merged = [];
    const [getRoles, getRolesFunctions] = useLazyQuery(GET_ROLES, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setRoles(response.roleTypesByIds)
        }
    });
    const [getFieldReadyRoles, getFieldReadyRolesFunctions] = useLazyQuery(GET_FIELD_READY_ROLES, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setFieldReadyRoles(response.fieldReadyRoles)
            getRoles({ variables: { ids: response.fieldReadyRoles.map((role) => role.roleId) } })
        }
    });

    const refetch = () => {
        getFieldReadyRolesFunctions.refetch()
        getRolesFunctions.refetch()
    }

    useEffect(() => {
        if (project.id) {
            getFieldReadyRoles({ variables: { projectId: project.id } })
        }
    },
        [project, getFieldReadyRoles])

    const [addFieldReadyRoles] = useMutation(CREATE_FIELD_READY_ROLES,
        {
            onCompleted: (response) => {
                refetch()
            }
        }
    )

    const [deleteFieldReadyRoles] = useMutation(DELETE_FIELD_READY_ROLES,
        {
            onCompleted: (response) => {
                refetch()
            }
        }
    )
    if ((fieldReadyRoles && fieldReadyRoles.length > 0) && (roles && roles.length > 0)) {
        for (let i = 0; i < fieldReadyRoles.length; i++) {
            merged.push({
                ...(roles.find((itmInner) => itmInner.id === fieldReadyRoles[i].roleId)),
                ...fieldReadyRoles[i]
            }
            );
        }
    }
    return (
        <Page title="Projects | FINBACK670">
            <Container maxWidth="xl">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <AddFieldReadyRoleToProject
                            project={project}
                            open={addFieldReadyRole}
                            handleClose={closeAddFieldReadyRole}
                            addFieldReadyRoles={addFieldReadyRoles}
                            fieldReadyRoles={merged}
                            refetchFieldReadyRoles={refetch}
                        />
                        <Confirmation
                            confirmation={confirmation}
                            handleClose={closeConfirmation}
                        />
                        <div style={{ paddingBottom: "16px" }}>
                            {renderTableActions(
                                project,
                                selectionModel,
                                openAddFieldReadyRole,
                                deleteFieldReadyRoles,
                                setConfirmation
                            )}
                        </div>
                        <div style={{ height: 600, width: "100%" }}>
                            <DataGridPremium
                                rows={merged}
                                checkboxSelection={true}
                                apiRef={apiRef}
                                columns={[
                                    { field: 'name', headerName: "Field Ready Role" },
                                    { field: 'id', headerName: "ID" },
                                ]}
                                pageSize={5}
                                onRowSelectionModelChange={(ids) => {
                                    const selectedIDs = new Set(ids);
                                    const selectedRowData = merged.filter((row) =>
                                        selectedIDs.has(row.id)
                                    );
                                    console.log(selectionRecord)
                                    setSelectionRecord(selectedRowData);
                                    setSelectionModel(ids);
                                }}
                                rowSelectionModel={selectionModel}
                                pageSizeOptions={[50]}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
