// material
import { Box, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import GQLTable from '../../utils/gql-mui-rhf/Table';

// ----------------------------------------------------------------------

export default function FirmManagement() {
  return (
    <Page title="Dashboard | FINBACK670">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Administrative Firm Management</Typography>
        </Box>
        <GQLTable
          queryString={'firms'}
          addMutationString={'createFirm'}
          deleteMutationString={'deleteFirm'}
          restrictedColumns={{ password: true }} />
      </Container>
    </Page>
  );
}
