import { useState } from 'react';
// material
import { Box, Container, Button, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import Map from './map';
import GQLTable from '../../../utils/gql-mui-rhf/Table'
import { useContext } from "react";
import { ProjectContext } from "../../../utils/contexts/project";
import createQuery from '../../../utils/gql-mui-rhf/QueryAction';
import { GQLMutationAction } from "../../../utils/gql-mui-rhf/SingleMutation";
import Import from './import';

// ----------------------------------------------------------------------
function generateMutation() {
    return GQLMutationAction({
      mutationString: 'createProjectLocation',
      onCompleted: (response) => {
        debugger
      },
    });
  }
export default function Addresses() {
    let projectContext = useContext(ProjectContext);
    const { project } = projectContext;
    const [refetchTable, setRefetchTable] = useState(false);
    const [showImport, setShowImport] = useState(false);
    const shouldRefetchTable = () => setRefetchTable(true)
    const createProjectLocation = generateMutation();

    if (!project) { return null }
    const { loading, error, data, refetch } = createQuery('project', { id: project.id })
    if (loading) {
        return null
    }
    if (error) {
        debugger
    }
    if (data.project) {
        if (data.project.id !== project.id) { refetch() }
        return (
            <Page title="Inspections | FINBACK670">
                <Container maxWidth="xl">
                    <Box sx={{ pb: 5 }}>
                        <Typography variant="h4">Project Addresses</Typography>
                    </Box>
                    <Map />
                    {!showImport && <div style={{marginTop: '32px', marginBottom: '32px'}}> <Button variant={"contained"} onClick={()=> {setShowImport(true)}}>Import Addresses</Button> </div>}
                    {showImport && <div style={{marginTop: '32px', marginBottom: '32px'}}>
                        <Import projectId={data.project.id} createProjectLocation={createProjectLocation} shouldRefetchTable={shouldRefetchTable} close={setShowImport}/>
                    </div>}
                    <GQLTable
                        queryString={'projectLocations'}
                        deleteMutationString={'deleteProjectLocation'}
                        queryVariables={{ "projectId": data.project.id }}
                        updateModal={true}
                        columnOrder={['lis', 'identifier', 'street1', 'street2', 'street3', 'neighborhood', 'city', 'state', 'zip']}
                        columnOptions={{
                            'lis': {headerName: 'Control Number'},
                            'identifier': {headerName: 'Location Number'},
                        }}
                        restrictedColumns={{parentId: true, mapboxId: true, lng: true, name: true, tenant: true, owner: true, lat: true, id: true, description: true, projectId: true}}
                        shouldRefetch={refetchTable}
                        setShouldRefetch={setRefetchTable}
                    />
                </Container>
            </Page>
        );
    }
}
