import * as React from 'react';
// material
import { Box, Container, Tabs, Tab } from '@mui/material';
// components
import Page from '../../components/Page';
import PeopleList from './list';
import { GQLForm } from 'src/utils/gql-mui-rhf/FormProvider';

// ----------------------------------------------------------------------

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function FirmView() {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Page title="Dashboard | FINBACK670">
            <Container maxWidth="xl" style={{ paddingBottom: '64pt' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="People" {...a11yProps(0)} />
                        <Tab label="Delete Person" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <PeopleList />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <GQLForm mutationString='deletePerson' />
                </TabPanel>
            </Container>
        </Page >
    );
}
