import { useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import Radar from 'radar-sdk-js';

const getOptions = (newInputValue, setOptions) => {
    Radar.autocomplete({
        query: newInputValue,
        limit: 10
    }, function (err, result) {
        if (!err) {
            setOptions(result.addresses)
            // do something with result.addresses
        }
    });
}


export default function Address({ callback }) {
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);

    return <Autocomplete
        value={value}
        getOptionLabel={(option) => option.formattedAddress}
        onChange={(event, newValue) => {
            callback(newValue);
            setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            getOptions(newInputValue, setOptions);
        }}
        id="controllable-states-demo"
        options={options}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} />}
    />;
}